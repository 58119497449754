const ENV = process.env;
export const UNAUTHORIZED_401 = Number(ENV.REACT_APP_UNAUTHORIZED_401);
export const UNAUTHORIZED_440 = Number(ENV.REACT_APP_UNAUTHORIZED_440);
export const UNAUTHORIZED_403 = Number(ENV.REACT_APP_UNAUTHORIZED_403);
export const GETSTATUS_5005 = Number(ENV.REACT_APP_GETSTATUS_5005);
export const GETSTATUS_4000 = Number(ENV.REACT_APP_GETSTATUS_4000);
export const GETSTATUS_4001 = Number(ENV.REACT_APP_GETSTATUS_4001);
export const GETSTATUS_4002 = Number(ENV.REACT_APP_GETSTATUS_4002);
export const CHECKEMI_2009 = Number(ENV.REACT_APP_CHECKEMI_2009);
export const UPDATE_IMEI_2008 = Number(ENV.REACT_APP_UPDATE_IMEI_2008);
export const DOWNPAYMENT_4053 = Number(ENV.REACT_APP_DOWNPAYMENT_4053);
export const RESENDOTP_2006 = Number(ENV.REACT_APP_RESENDOTP_2006);
export const SENDOTP_2005 = Number(ENV.REACT_APP_SENDOTP_2005);
export const SENDOTP_5001 = Number(ENV.REACT_APP_SENDOTP_5001);
export const VERIFY_OTP_2007 = Number(ENV.REACT_APP_VERIFY_OTP_2007);
export const CHECK_DEVICE_REGISTRATION_2001 = Number(
  ENV.REACT_APP_CHECK_DEVICE_REGISTRATION_2001
);
export const RETAILER_CONFIRMATION_2001 = Number(
  ENV.REACT_APP_RETAILER_CONFIRMATION_2001
);
export const CHECK_ACTIVATION_2000 = Number(
  ENV.REACT_APP_CHECK_ACTIVATION_2000
);
export const DISBURSE_2001 = Number(ENV.REACT_APP_DISBURSE_2001);
export const REGISTRATION_2001 = Number(ENV.REACT_APP_REGISTRATION_2001);
export const UPDATE_CUSTOMER_CONSENT_2000 = Number(
  ENV.REACT_APP_UPDATE_CUSTOMER_CONSENT_2000
);
export const UPDATE_CUSTOMER_CONSENT_4000 = Number(
  ENV.REACT_APP_UPDATE_CUSTOMER_CONSENT_4000
);
export const CUSTOMER_SCORE_2000 = Number(ENV.REACT_APP_CUSTOMER_SCORE_2000);
export const CUSTOMER_SCORE_4000 = Number(ENV.REACT_APP_CUSTOMER_SCORE_4000);
export const CUSTOMER_SCORE_4001 = Number(ENV.REACT_APP_CUSTOMER_SCORE_4001);
export const CUSTOMER_SCORE_4002 = Number(ENV.REACT_APP_CUSTOMER_SCORE_4002);
