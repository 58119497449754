import { DEVICE_PARTNER } from "Global/constant";
import { PRODUCT_NAME } from "HttpRequest/url";

const MODE = process.env.REACT_APP_MODE;
let PROD_PRODUCT_NAME = "";
let STAGING_PRODUCT_NAME = "";
const BaseUrl = `${process.env.PUBLIC_URL}/assets/`;
const QR_CODE = BaseUrl + "orange_prod_qrcode.png";
const STAG_QR_CODE = BaseUrl + "ORANGE_STAGE_BUILD_QRCODE.png";
const BURKINA_FASO_QR_CODE = BaseUrl + "burkina_faso_qr_code.jpg";

const PartnerConfigData = () => {
  const getOrangeCiStep = ({
    status,
    device_partner = "",
    defaultStep = 0,
    autodebitscreen = true,
  }) => {
    switch (status) {
      case "customer_confirmation_started": // Step 2
        return 1;
      case "mandate_initialised": // Step 2
        return 2;
      case "customer_confirmed": // Step 3
        return 2;
      case "starting_dowpayment": // Step 3
        return autodebitscreen ? 3 : 2;
      case "mandate_confirmed": // Step 3
        return 3;
      case "starting_handset_registeration": // Step 4
        return autodebitscreen ? 4 : 3;
      case "starting_handset_activation": // Step 5 (Data culter qr screen)
        return autodebitscreen ? 4 : 3;
      case "starting_retailer_confirmation": // Step 5 (nested step checkbox screen)
        return autodebitscreen ? 5 : 4;
      case "handset_registerated": // Step 4 (BE Steps)
        return autodebitscreen ? 4 : 3;
      case "handset_activated": // Step 4 (BE Steps)
        return autodebitscreen ? 5 : 4;
      case "retailer_confirmed": // Step 6 (BE Steps)
        return autodebitscreen ? 6 : 5;
      case "events_scheduled": // Step 6
        return autodebitscreen ? 6 : 5;
      case "instrument_initialised":
        return 1;
      case "disbursement_accounting_started":
        return device_partner === DEVICE_PARTNER
          ? autodebitscreen
            ? 5
            : 4
          : autodebitscreen
          ? 6
          : 5;
      case "pre_disbursement_accounting_done":
        return device_partner === DEVICE_PARTNER
          ? autodebitscreen
            ? 5
            : 4
          : autodebitscreen
          ? 6
          : 5;
      case "disbursement_accounting_done":
        return device_partner === DEVICE_PARTNER
          ? autodebitscreen
            ? 5
            : 4
          : autodebitscreen
          ? 6
          : 5;
      case "downpayment_done": // Step 6
        return autodebitscreen ? 4 : 3;
      default:
        return defaultStep;
    }
  };
  const getBurkinaFasoStep = ({
    status,
    device_partner = "",
    defaultStep = 0,
    autodebitscreen = true,
  }) => {
    switch (status) {
      case "customer_confirmation_started": // Step 2
        return 1;
      case "mandate_initialised":
        return 2;
      case "starting_dowpayment": // Step 3
        return autodebitscreen ? 3 : 2;
      case "customer_confirmed": // Step 3
        return 2;
      case "mandate_confirmed": // Step 3
        return 3;
      case "starting_handset_registeration": // Step 4
        return autodebitscreen ? 4 : 3;
      case "starting_handset_activation": // Step 5 (Data culter qr screen)
        return autodebitscreen ? 4 : 3;
      case "starting_retailer_confirmation": // Step 5 (nested step checkbox screen)
        return autodebitscreen ? 5 : 4;
      case "handset_registerated": // Step 4 (BE Steps)
        return autodebitscreen ? 4 : 3;
      case "handset_activated": // Step 4 (BE Steps)
        return autodebitscreen ? 5 : 4; //-> doubt
      case "retailer_confirmed": // Step 6 (BE Steps)
        return autodebitscreen ? 6 : 5;
      case "events_scheduled": // Step 6
        return autodebitscreen ? 6 : 5;
      case "instrument_initialised":
        return 1;
      case "disbursement_accounting_started":
        return device_partner === DEVICE_PARTNER
          ? autodebitscreen
            ? 5
            : 4
          : autodebitscreen
          ? 6
          : 5;
      case "pre_disbursement_accounting_done":
        return device_partner === DEVICE_PARTNER
          ? autodebitscreen
            ? 5
            : 4
          : autodebitscreen
          ? 6
          : 5;
      case "disbursement_accounting_done":
        return device_partner === DEVICE_PARTNER
          ? autodebitscreen
            ? 5
            : 4
          : autodebitscreen
          ? 6
          : 5;
      case "downpayment_done": // Step 6
        return autodebitscreen ? 4 : 3;
      default:
        return defaultStep;
    }
  };

  const partnerData = {
    isDynamicDownpaymentEnabled: false,
    qrCode: "",
    partnerName: "",
    isLmsKycEnabled: false,
    msisdnMaxLength: 10,
    msisdnRegex: /^\D*(\d\D*){1,10}$/,
    totalEligibilityCard: true,
    smsCode: "#191#",
    autodebitsmscode: "192#",
    autodebitscreen: true,
    getStepNumber: getOrangeCiStep,
    isChannelEnabled: true,
    loanCancellationEmail: "dcx-admetier@ocitnetad.ci",
    isCancelLoanBtnEnabled: true,
    isDownpaymentOtpEnabled: false,
  };
  if (PRODUCT_NAME === "burkina_faso") {
    if (MODE?.toLowerCase() === "development") {
      partnerData.isDynamicDownpaymentEnabled = false;
      partnerData.qrCode = BURKINA_FASO_QR_CODE;
      partnerData.partnerName = PRODUCT_NAME;
      partnerData.isLmsKycEnabled = true;
      partnerData.msisdnMaxLength = 8;
      partnerData.msisdnRegex = /^\D*(\d\D*){1,8}$/;
      partnerData.totalEligibilityCard = false;
      partnerData.smsCode = "*610#";
      partnerData.autodebitscreen = false;
      partnerData.isChannelEnabled = true;
      partnerData.loanCancellationEmail = "obfcustomersupport@yabx.co";
      partnerData.isCancelLoanBtnEnabled = true;
      partnerData.isDownpaymentOtpEnabled = true;
      partnerData.getStepNumber = (res) =>
        getBurkinaFasoStep({ ...res, autodebitscreen: false });
    } else if (MODE?.toLowerCase() === "production") {
      const BURKINA_FASO_PROD_QR_CODE =
        BaseUrl + "burkina_faso_prod_qr_code.png";
      partnerData.isDynamicDownpaymentEnabled = false;
      partnerData.qrCode = BURKINA_FASO_PROD_QR_CODE;
      partnerData.partnerName = PROD_PRODUCT_NAME;
      partnerData.isLmsKycEnabled = true;
      partnerData.msisdnMaxLength = 8;
      partnerData.msisdnRegex = /^\D*(\d\D*){1,8}$/;
      partnerData.totalEligibilityCard = false;
      partnerData.smsCode = "*610#";
      partnerData.autodebitscreen = false;
      partnerData.isChannelEnabled = true;
      partnerData.loanCancellationEmail = "obfcustomersupport@yabx.co";
      partnerData.isCancelLoanBtnEnabled = true;
      partnerData.isDownpaymentOtpEnabled = true;
      partnerData.getStepNumber = (res) =>
        getBurkinaFasoStep({ ...res, autodebitscreen: false });
    } else if (MODE?.toLowerCase() === "pre_production") {
      partnerData.isDynamicDownpaymentEnabled = false;
      partnerData.qrCode = BURKINA_FASO_QR_CODE;
      partnerData.partnerName = PROD_PRODUCT_NAME;
      partnerData.isLmsKycEnabled = true;
      partnerData.msisdnMaxLength = 8;
      partnerData.msisdnRegex = /^\D*(\d\D*){1,8}$/;
      partnerData.totalEligibilityCard = false;
      partnerData.smsCode = "*610#";
      partnerData.autodebitscreen = false;
      partnerData.isChannelEnabled = true;
      partnerData.loanCancellationEmail = "obfcustomersupport@yabx.co";
      partnerData.isCancelLoanBtnEnabled = true;
      partnerData.isDownpaymentOtpEnabled = true;
      partnerData.getStepNumber = (res) =>
        getBurkinaFasoStep({ ...res, autodebitscreen: false });
    } else if (MODE?.toLowerCase() === "staging") {
      partnerData.isDynamicDownpaymentEnabled = false;
      partnerData.qrCode = BURKINA_FASO_QR_CODE;
      partnerData.partnerName = STAGING_PRODUCT_NAME;
      partnerData.isLmsKycEnabled = true;
      partnerData.msisdnMaxLength = 8;
      partnerData.msisdnRegex = /^\D*(\d\D*){1,8}$/;
      partnerData.totalEligibilityCard = false;
      partnerData.smsCode = "*610#";
      partnerData.autodebitscreen = false;
      partnerData.isChannelEnabled = true;
      partnerData.loanCancellationEmail = "obfcustomersupport@yabx.co";
      partnerData.isCancelLoanBtnEnabled = true;
      partnerData.isDownpaymentOtpEnabled = true;
      partnerData.getStepNumber = (res) =>
        getBurkinaFasoStep({ ...res, autodebitscreen: false });
    }
  } else if (PRODUCT_NAME === "orange_ci") {
    if (MODE?.toLowerCase() === "development") {
      partnerData.isDynamicDownpaymentEnabled = false;
      partnerData.qrCode = STAG_QR_CODE;
      partnerData.partnerName = PRODUCT_NAME;
      partnerData.msisdnMaxLength = 10;
      partnerData.totalEligibilityCard = false;
      partnerData.smsCode = "#191#";
      partnerData.autodebitsmscode = "#192#";
      partnerData.autodebitscreen = true;
      partnerData.isChannelEnabled = true;
      partnerData.loanCancellationEmail = "dcx-admetier@ocitnetad.ci";
      partnerData.isCancelLoanBtnEnabled = true;
      partnerData.isDownpaymentOtpEnabled = false;
      partnerData.getStepNumber = (res) =>
        getOrangeCiStep({ ...res, autodebitscreen: true });
    } else if (MODE?.toLowerCase() === "production") {
      partnerData.isDynamicDownpaymentEnabled = false;
      partnerData.qrCode = QR_CODE;
      partnerData.partnerName = PROD_PRODUCT_NAME;
      partnerData.msisdnMaxLength = 10;
      partnerData.totalEligibilityCard = false;
      partnerData.smsCode = "#191#";
      partnerData.autodebitsmscode = "#144*74#";
      partnerData.autodebitscreen = true;
      partnerData.isChannelEnabled = false;
      partnerData.loanCancellationEmail = "dcx-admetier@ocitnetad.ci";
      partnerData.isCancelLoanBtnEnabled = true;
      partnerData.isDownpaymentOtpEnabled = false;
      partnerData.getStepNumber = (res) =>
        getOrangeCiStep({ ...res, autodebitscreen: true });
    } else if (MODE?.toLowerCase() === "pre_production") {
      partnerData.isDynamicDownpaymentEnabled = false;
      partnerData.qrCode = QR_CODE;
      partnerData.partnerName = PROD_PRODUCT_NAME;
      partnerData.msisdnMaxLength = 10;
      partnerData.totalEligibilityCard = false;
      partnerData.smsCode = "#191#";
      partnerData.autodebitsmscode = "#192#";
      partnerData.autodebitscreen = true;
      partnerData.isChannelEnabled = false;
      partnerData.loanCancellationEmail = "dcx-admetier@ocitnetad.ci";
      partnerData.isCancelLoanBtnEnabled = true;
      partnerData.isDownpaymentOtpEnabled = false;
      partnerData.getStepNumber = (res) =>
        getOrangeCiStep({ ...res, autodebitscreen: true });
    } else if (MODE?.toLowerCase() === "staging") {
      partnerData.isDynamicDownpaymentEnabled = false;
      partnerData.qrCode = STAG_QR_CODE;
      partnerData.partnerName = STAGING_PRODUCT_NAME;
      partnerData.totalEligibilityCard = false;
      partnerData.msisdnMaxLength = 10;
      partnerData.smsCode = "#191#";
      partnerData.autodebitsmscode = "#192#";
      partnerData.autodebitscreen = true;
      partnerData.isChannelEnabled = true;
      partnerData.loanCancellationEmail = "dcx-admetier@ocitnetad.ci";
      partnerData.isCancelLoanBtnEnabled = true;
      partnerData.isDownpaymentOtpEnabled = false;
      partnerData.getStepNumber = (res) =>
        getOrangeCiStep({ ...res, autodebitscreen: true });
    }
  }
  return {
    partnerData,
  };
};

export default PartnerConfigData;
