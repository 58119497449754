import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  originalProductList: [],
  categories: [],
  nestedProductData: {},
  productDetail: {
    productCategory: {
      extraData: [],
      label: null,
      value: null,
    },
    productSubCategory: "",
    searchProduct: "",
  },
  searchedItems: [],
  filteredList: [],
};

const productSlice = createSlice({
  name: "product_details",
  initialState,
  reducers: {
    updateProductDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetProduct: (state) => {
      state.originalProductList = [];
      state.categories = [];
      state.nestedProductData = {};
      state.productDetail = {
        productCategory: {
          extraData: [],
          label: null,
          value: null,
        },
        productSubCategory: "",
        searchProduct: "",
      };
      state.searchedItems = [];
      state.filteredList = [];
    },
  },
});

export const { updateProductDetails, resetProduct } = productSlice.actions;
export default productSlice.reducer;
