import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Flex } from "Component";
import JordanLoader from "./Component/jordanLoader";
import Cookies from "js-cookie";
import { REDIRECT_URL } from "HttpRequest/url";
import { clearCookies } from "Global/helper";
import { RootProvider } from "./Context";
import { getPathData } from "Utils/helpers";
const PrivateRoute = () => {
  const access_token = Cookies.get("access_token");
  const { state } = useContext(RootProvider);
  const RedirectUrl = getPathData(
    state,
    ["userProfile", "slo_url"],
    REDIRECT_URL
  );
  if (
    !access_token ||
    access_token === null ||
    access_token === "null" ||
    access_token === ""
  ) {
    clearCookies();
    window.location.href = RedirectUrl;

    return false;
  } else {
    return <Outlet />;
  }
};

export const PrivateReportRoute = () => {
  const { state = {} } = useContext(RootProvider);
  const isManager = getPathData(state, ["userProfile", "is_manager"], false);
  if (
    !isManager ||
    isManager === null ||
    isManager === "null" ||
    isManager === "" ||
    isManager === "false"
  ) {
    window.location.href = "/";
    return null;
  } else {
    return <Outlet />;
  }
};

export const PrivateEshopRoute = () => {
  const { state = {} } = useContext(RootProvider);
  const isManager = getPathData(state, ["userProfile", "eshop"], false);
  if (
    !isManager ||
    isManager === null ||
    isManager === "null" ||
    isManager === "" ||
    isManager === "false"
  ) {
    window.location.href = "/";
    return null;
  } else {
    return <Outlet />;
  }
};

export const PersistPageState = (props) => {
  const accessToken = localStorage.getItem("access_token");
  // const accessToken = Cookies.get("access_token");
  const [isLoading, setIsLoading] = useState({
    loader: true,
    msg: "Loading...",
  });
  const { loader, msg } = isLoading;
  let navigate = useNavigate();
  const { children } = props;
  useEffect(() => {
    if (
      !accessToken ||
      accessToken === null ||
      accessToken === "null" ||
      accessToken === ""
    ) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("shop_id");
      localStorage.setItem("language", "en");
      setIsLoading((prev) => ({ ...prev, loader: false }));
    } else {
      navigate(-1);
    }
  }, []);
  return loader ? (
    <Flex
      direction="row"
      justify="center"
      align="center"
      overrideStyle={{ height: "100vh" }}
    >
      <JordanLoader label={msg} />
    </Flex>
  ) : (
    children
  );
};

export default PrivateRoute;
