import React from "react";
import { Flex, Typography } from "Component";
import { RetailerJordanColors } from "Global/colors";
import { createUseStyles } from "react-jss";
import { formatValue, truncateText } from "Utils/helpers";
import RemoveIcon from "RetailerJordan/Assets/Icons/removeIcon";
import { DownEditIcon } from "RetailerJordan/Assets/Icons";
import { useTranslation } from "react-i18next";

const useStyles = createUseStyles((theme) => ({
  changeDirection: {
    direction: "rtl",
  },
  arIndicator: {
    marginLeft: theme.padding,
  },
  arChildStepContainer: {
    paddingLeft: theme.padding * 3,
    paddingRight: theme.padding * 6,
  },

  cardClass: {
    paddingLeft: theme.padding * 3,
    paddingBottom: theme.padding,
    color: `${RetailerJordanColors.PRIMARY}!important`,
  },
  cartHeader: {
    padding: theme.padding * 2,
  },
  cartCount: {
    width: 26,
    height: 26,
    padding: theme?.padding / 2,
    borderRadius: 26,
    background: RetailerJordanColors.PRIMARY,
    marginLeft: theme.margin * 1.5,
    marginRight: theme.margin * 1.5,
    position: "relative",
  },
  cartPara: {
    color: `${RetailerJordanColors.WHITE}!important`,
    fontSize: "14px",
    position: "absolute",
    top: "39%",
    left: "40%",
    transform: "translate(-50%,-50%)",
  },
  cartCard: {
    padding: theme.padding,
    border: `1px solid ${RetailerJordanColors.BORDERCOLOR_10}`,
    borderRadius: "4px",
    marginTop: theme.padding,
    marginBottom: theme.padding / 2,
  },
  cartCont: {
    width: "90%",
  },
  readCartCont: {
    width: "100%",
  },
  delCont: { width: "10%", marginTop: theme.margin * 1.5, cursor: "pointer" },
  imageWrapper: {
    width: "20%",
    "& img": {
      width: theme.padding * 6,
      marginBottom: theme.padding,
    },
  },
  readImageWrapper: {
    width: "15%",
  },
  contentWrapper: {
    width: "80%",
    marginLeft: theme.margin,
    marginRight: theme.margin,
  },
  cartTitle: {
    fontSize: theme.margin * 2,
    color: `${RetailerJordanColors.GREY900}!important`,
    fontWeight: 600,
    marginBottom: theme.margin / 2,
  },
  cartDesc: {
    fontSize: theme.margin * 1.5,
    color: `${RetailerJordanColors.GREY400}!important`,
    marginBottom: theme.margin,
  },
  priceDetails: {
    borderTop: `1px solid ${RetailerJordanColors.BORDERCOLOR_10}`,
    paddingTop: theme.margin,
  },
  priceLabel: {
    fontSize: theme.margin * 1.5,
    color: `${RetailerJordanColors.GREY400}!important`,
    marginBottom: theme.margin / 2,
  },
  priceAmount: {
    fontSize: 14,
    color: `${RetailerJordanColors.TEXTMEDIUMEMPHASIS}!important`,
    fontWeight: 600,
    marginBottom: theme.margin / 2,
  },
  cartBody: {
    height: "50vh",
    overflowY: "scroll",
    marginBottom: theme.margin * 2,
    padding: theme.padding * 2,
    paddingTop: 0,
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  cartFooter: {
    borderTop: `1px solid ${RetailerJordanColors.BORDERCOLOR}`,
    padding: theme.padding * 2,
    "& button": {
      marginTop: theme.margin * 2,
    },
  },
  totalAmount: {
    color: `${RetailerJordanColors.GREY900}!important`,
    fontSize: "18px",
    fontWeight: 600,
  },
  rightAlign: {
    textAlign: "right",
    alignItems: "flex-end",
  },
  minTax: {
    fontSize: "10px",
  },
  buttonClass: {
    marginTop: 0,
  },
  footer: {
    paddingTop: theme.padding * 2,
    paddingLeft: 0,
    paddingRight: theme.padding * 3,
  },
  cartModal: {
    marginBottom: `${theme.padding}px!important`,
  },
}));

const MultiProduct = ({
  products = [],
  openDownPaymentModal = null,
  removeProductHandler = null,
  isEdit = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      {products &&
        products?.length !== 0 &&
        products?.map((item, index) => {
          const taxAmount = item?.tax16?.toLowerCase() === "taxable" ? 16 : 0;
          const finalDownpaymentAmount = (
            item?.downpayment_amount +
            item?.downpayment_amount * (taxAmount / 100)
          )?.toFixed(3);
          return (
            <Flex
              overrideClass={
                isEdit
                  ? classes.cartCard
                  : `${classes.cartCard} ${classes.cartModal}`
              }
              key={`${index}${item?.itemCode}`}
            >
              <Flex
                overrideClass={`${classes.cartCont} ${
                  !isEdit ? classes.readCartCont : null
                }`}
                direction="column"
              >
                <Flex align="center">
                  <Flex
                    overrideClass={`${classes.imageWrapper} ${
                      !isEdit ? classes.readImageWrapper : null
                    }`}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/grey-image-placeholder.jpg"
                      }
                      data-testid="product_img_id"
                      alt="product-img"
                    />
                  </Flex>
                  <Flex
                    direction="column"
                    overrideClass={classes.contentWrapper}
                  >
                    <Typography
                      type="h3"
                      overrideClass={classes.cartTitle}
                      testID="item_desc_id"
                    >
                      {isEdit
                        ? truncateText(item?.itemDesc, 20)
                        : truncateText(item?.item_desc, 50)}
                    </Typography>
                    <Typography type="p" overrideClass={classes.cartDesc}>
                      {isEdit
                        ? truncateText(
                            `${item?.catDesc} | ${item?.subCatDesc}`,
                            40
                          )
                        : truncateText(
                            `${item?.cat_desc} | ${item?.sub_cat_desc}`,
                            40
                          )}
                    </Typography>
                  </Flex>
                </Flex>
                <Flex overrideClass={classes.priceDetails} justify="between">
                  <Flex direction="column">
                    <Typography type="label" overrideClass={classes.priceLabel}>
                      {t("price")}
                    </Typography>
                    <Typography type="h3" overrideClass={classes.priceAmount}>
                      {item?.item_cost_with_margin} JOD
                    </Typography>
                  </Flex>
                  <Flex direction="column" overrideClass={classes.rightAlign}>
                    <Typography type="label" overrideClass={classes.priceLabel}>
                      {t("downpayment")}
                    </Typography>
                    <Flex justify="end">
                      <Typography type="h3" overrideClass={classes.priceAmount}>
                        {item?.downpayment_amount}{" "}
                        {`+ ${formatValue(
                          finalDownpaymentAmount - item?.downpayment_amount
                        )} `}
                        <span className={classes.minTax}>({t("tax")})</span>
                      </Typography>
                      {isEdit && (
                        <Flex
                          overrideStyle={{
                            width: "initial",
                            marginLeft: "8px",
                            cursor: "pointer",
                          }}
                          onClick={() => openDownPaymentModal(item, index)}
                          testID="edit_icon_id"
                        >
                          <DownEditIcon />
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              {isEdit && (
                <Flex
                  overrideClass={classes.delCont}
                  justify="center"
                  onClick={() => removeProductHandler(index, item)}
                  testID="remove_icon_id"
                >
                  <RemoveIcon />
                </Flex>
              )}
            </Flex>
          );
        })}
    </>
  );
};

export default MultiProduct;
