import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Flex from "Component/flex";
import Typography from "Component/Typography";
import Colors, { RetailerJordanColors } from "Global/colors";
import Theme from "Global/retailerJordanTheme";
import { capitalizeFirstLetter, getPathData } from "Utils/helpers";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { fetchMetaData } from "Global/commonRequest";
import DeviceObserver from "Hooks/deviceDetect";
import { LogoutIcon, ToggleIcon } from "RetailerJordan/Assets/Icons";
// import { SelectBox } from "Component";
import PopOver from "./popOver";
import { PARTNER_CODE_URL, REDIRECT_URL } from "HttpRequest/url";
import Cookies from "js-cookie";
import { clearCookies } from "Global/helper";
import { RootProvider } from "RetailerJordan/Context";

export const themeContext = React.createContext(null);
const useStyles = createUseStyles({
  headerCont: (props) => ({
    padding: `${Theme.padding - 8}px ${Theme.padding * 3}px `,
    backgroundColor: Colors.WHITE,
    borderBottom: `1px solid ${Colors.BORDERCOLOR_10}`,
    width: "100%",
    height: "4vw",
    marginLeft: props?.fromLogin ? 0 : Theme.margin * 7,
    "@media (max-width: 1071px)": {
      marginLeft: 0,
      height: 64,
    },
  }),
  imgLogo: {
    width: 100,
    height: "100%",
    objectFit: "contain",
  },
  mobileImgLogo: {
    width: 97.6,
    cursor: "pointer",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
  },
  navlinkCont: {
    alignSelf: "center",
  },
  navlink: {
    textDecoration: "none",
    color: Colors.BLACK,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  languageContainer: {
    justifyContent: "flex-end",
    padding: Theme.padding + 2,
    gap: "4px",
    borderRadius: "4px",
    border: `1px solid ${Colors.BORDERCOLOR}`,
    boxSizing: "border-box",
    width: 88,
    height: 40,
  },
  dropDownCont: {
    padding: Theme.padding,
    border: "none",
    backgroundColor: "rgba(0,0,0,0)",
    color: Colors.BLACK,
    cursor: "pointer",
    "&:focus": {
      border: "none",
    },
    "&:active": {
      border: "none",
    },
    "&>*": {
      borderRadius: Theme.borderRadius,
    },
    "@media (max-width: 768px)": {
      width: "300px",
    },
  },
  toggleIcon: {
    fontSize: 25,
    display: "flex",
    padding: 8,
    justifyContent: "center",
    alignItems: "center",
    width: 37,
    height: 21,
  },
  sideBarIconCont: {
    cursor: "pointer",
    flex: 1,
    flexShrink: 0,
    borderRight: `1px solid ${RetailerJordanColors.BORDERCOLOR_10}`,
  },
  selectHeader: {
    width: "110px!important",
    marginLeft: "auto!important",
    marginRight: "15px",
  },
  optionHeader: {
    width: "110px!important",
    right: "15px!important",
    left: "initial",
  },
  userName: {
    "@media (max-width: 767px)": {
      display: "none",
    },
  },
  shopName: {
    borderLeft: `1px solid ${Colors.BORDERCOLOR}`,
    padding: Theme.padding,
    color: `${RetailerJordanColors.PRIMARY}!important`,
  },
});

const Header = (props) => {
  const classes = useStyles({ ...props });
  const navigate = useNavigate();
  const { isDesktop, isTab, isMobile } = DeviceObserver();
  const { i18n, t } = useTranslation();
  const { state = {} } = useContext(RootProvider);
  const userProfile = getPathData(state, ["userProfile"], {});
  const onChangeLanguageHandler = (val) => {
    i18n.changeLanguage(val);
    localStorage.setItem("language", val);
  };
  const logoutHandler = ({ params }) => {
    const toggleLoader = getPathData(params, ["setIsLoading"], null);
    if (toggleLoader) {
      toggleLoader({ loader: true, msg: t("login_out") });
      localStorage?.removeItem("downpayment_details");
      clearCookies();
      localStorage.setItem("language", "en");
      i18n.changeLanguage("en");
      setTimeout(() => {
        fetchMetaData({ setIsLoading: null });
        window.location.href = getPathData(
          userProfile,
          ["slo_url"],
          REDIRECT_URL
        );
      }, 200);
    }
  };
  const UserName = userProfile
    ? getPathData(userProfile, ["name"], "User")?.split("+")?.join(" ")
    : "User";
  const rightNavOptions = [
    {
      onClick: null,
      component: () => {
        return (
          <Flex
            justify="end"
            align="center"
            overrideStyle={{
              marginRight: Theme.margin * 2,
              width: "100px",
            }}
          >
            <PopOver
              options={
                PARTNER_CODE_URL?.toLowerCase() !== "ojo_jo_retailer_jt_jtgroup"
                  ? [
                      {
                        label: "Eng",
                        value: "en",
                      },
                      {
                        label: "عربي",
                        value: "ar",
                      },
                      {
                        label: "Spanish",
                        value: "sp",
                      },
                    ]
                  : [
                      {
                        label: "Eng",
                        value: "en",
                      },
                      {
                        label: "عربي",
                        value: "ar",
                      },
                    ]
              }
              value={i18n?.language || "en"}
              handleChange={(res) =>
                onChangeLanguageHandler(getPathData(res, ["value"], ""))
              }
            />
          </Flex>
        );
      },
    },
    {
      onClick: null,
      component: Cookies.get("access_token")
        ? () => {
            return (
              <Flex
                justify="end"
                align="center"
                overrideStyle={{ width: "initial" }}
                overrideClass={classes.userName}
              >
                <Typography
                  type="fontSmallBold"
                  color={RetailerJordanColors.GREY600}
                  overrideStyle={{ width: "150px" }}
                >
                  {UserName}
                </Typography>
              </Flex>
            );
          }
        : null,
    },
    {
      path: "",
      icon: Cookies.get("access_token") && (
        <LogoutIcon
          style={{
            width: 30,
            cursor: "pointer",
          }}
          data-testid="logout_icon_id"
        />
      ),
      label: "",
      onClick: logoutHandler,
      component: null,
    },
  ];

  return (
    <Flex
      overrideStyle={{
        position: "fixed",
        top: 0,
        zIndex: 11,
      }}
    >
      <Flex
        overrideClass={`${classes.headerCont}`}
        justify="between"
        align="center"
      >
        {(isMobile || isTab) && !props?.isSidebarVisible && (
          <Flex overrideStyle={{ width: "initial" }}>
            <ToggleIcon
              onClick={() =>
                props?.toggleSidebar ? props?.toggleSidebar() : null
              }
              data-testid="barsIcon_id"
              style={{
                alignSelf: "center",
                cursor: "pointer",
              }}
            />
          </Flex>
        )}
        <Flex align="center">
          <img
            src={process.env.PUBLIC_URL + "/assets/yabx_logo.png"}
            className={isDesktop ? classes.imgLogo : classes.mobileImgLogo}
            data-testid="logo"
            alt="company_logo"
            onClick={() => navigate("/")}
          />
          {isDesktop && (
            <Typography type="fontSmallBold" overrideClass={classes.shopName}>
              {userProfile?.shop_name &&
                capitalizeFirstLetter(userProfile?.shop_name)}
            </Typography>
          )}
        </Flex>
        <Flex
          align="center"
          justify="between"
          overrideStyle={{ marginLeft: 40 }}
        >
          {rightNavOptions.length > 0 && (
            <Flex
              align="center"
              justify="end"
              overrideStyle={{ justifyContent: "flex-end" }}
            >
              {rightNavOptions.map((res, key) => {
                const {
                  label = "",
                  overridelabelClass = "",
                  overrideLabelContainerClass = "",
                  path = "",
                  onClick = null,
                  component = null,
                  icon,
                } = res;
                return component ? (
                  <React.Fragment key={key}>{component(res)}</React.Fragment>
                ) : (
                  <div
                    onClick={(e) => onClick({ event: e, params: props })}
                    className={`${classes.navlinkCont} ${overrideLabelContainerClass}`}
                    key={key}
                  >
                    <NavLink to={path} className={classes.navlink}>
                      {icon && <span>{icon}</span>}
                      <Typography
                        type="link"
                        overrideClass={overridelabelClass}
                      >
                        {label}
                      </Typography>
                    </NavLink>
                  </div>
                );
              })}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Header;
