import React from "react";
import PropTypes from "prop-types";
import { Range, getTrackBackground } from "react-range";
import { RetailerOrangeColors } from "Global/colors";
import { Flex } from "Component";

const OrangeSlider = (props) => {
  const {
    minVal = 0,
    maxVal = 1,
    stepCount = 1,
    value = [0],
    onSlide = null,
    overrideTrackStyle = {},
    trackTestId = "sliderTrack",
    thumbTestId = "sliderThumb",
  } = props;
  return (
    <Range
      values={value}
      step={stepCount}
      min={minVal}
      max={maxVal}
      onChange={(values) => onSlide && onSlide(values)}
      renderTrack={({ props, children }) => (
        <Flex
          onMouseDown={props?.onMouseDown}
          onTouchStart={props?.onTouchStart}
          style={{
            ...overrideTrackStyle,
            height: "36px",
            display: "flex",
            width: "100%",
            cursor: "pointer",
          }}
          testID={trackTestId}
        >
          <Flex
            innerRef={props?.ref}
            overrideStyle={{
              height: "8px",
              width: "100%",
              borderRadius: "4px",
              background: getTrackBackground({
                values: value,
                colors: [
                  RetailerOrangeColors.PRIMARY,
                  RetailerOrangeColors.ORANGE100,
                ],
                min: minVal,
                max: maxVal,
              }),
              alignSelf: "center",
            }}
          >
            {children}
          </Flex>
        </Flex>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            ...props.style,
            boxSizing: "border-box",
            cursor: "pointer",
            outline: "none",
            height: "20px",
            width: "20px",
            borderRadius: "15px",
            backgroundColor: RetailerOrangeColors.WHITE,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 2px 6px #AAA",
          }}
          data-testid={thumbTestId}
        >
          <div
            style={{
              height: "12px",
              width: "12px",
              borderRadius: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: RetailerOrangeColors.PRIMARY_DARK,
            }}
          />
        </div>
      )}
    />
  );
};

OrangeSlider.propTypes = {
  minVal: PropTypes.number,
  maxVal: PropTypes.number,
  stepCount: PropTypes.number,
  onSlide: PropTypes.func,
  overrideTrackStyle: PropTypes.object,
  value: PropTypes.array,
  thumbTestId: PropTypes.string,
  trackTestId: PropTypes.string,
};

export default OrangeSlider;
