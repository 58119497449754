import React, { useEffect, useState, Suspense, useContext } from "react";
import { Typography, Flex, Label } from "Component";
import { RetailerOrangeColors } from "Global/colors";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import DocumentUpload from "./documentUpload";
import { RootProvider } from "RetailerOrange/Context";
import { UploadIcon, EyeIcon } from "RetailerOrange/Assets";
import Theme from "Global/retailerOrangeTheme";
import { getPathData } from "Utils/helpers";
import OrangeLoader from "../orangeLoader";
import FileUploadComponent from "./fileUploadComponent";
const PDFICON =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAxklEQVR4nO2WQQoCMQxFcwoR73+UDuTtRfE0I0IWbmWStOp/UNrVtI8+mJqJLwLYC8cNOP+CyO7ujzHGpU3Eir7bJkOxiLvf3+a6zKpFtm07ufu1/GYoFnmtW2RoEGmRoUmkXIZGkVIZmkXKZGaIlMjMEkmX6fizf/I2s6MbrvKGs6MbplrMOAcSyQXdSKC0kkFpBUorGZRWoLSSQWkFSisZlFagtJJBaQVKKxmUVqC0kkFpBUorGZRWoLRWTYtFhv29iLB+nuLfptEmoprCAAAAAElFTkSuQmCC";
const useStyles = createUseStyles({
  uploadFileMainConatiner: {
    border: `1px solid ${RetailerOrangeColors.GREY200}`,
    padding: Theme.padding + 4,
    borderRadius: Theme.borderRadius,
  },
  uploadContainer: {
    backgroundColor: RetailerOrangeColors.GREY25,
    padding: Theme?.padding,
    borderRadius: Theme?.borderRadius,
    border: `1px solid ${RetailerOrangeColors.GREY100}`,
    // boxShadow: `1px 1px  ${RetailerOrangeColors.BORDERCOLOR}`,
    height: 140,
    marginRight: Theme?.margin * 3,
  },
  imageActionClass: {
    padding: Theme?.padding / 2,
    backgroundColor: RetailerOrangeColors.WHITE,
    border: `1px solid ${RetailerOrangeColors.BORDERCOLOR}`,
    borderRadius: Theme?.borderRadius,
    maxWidth: 100,
    marginRight: Theme?.margin * 4,
    cursor: "pointer",
    marginTop: Theme?.margin + 4,
  },
  isButtonDisabled: {
    cursor: "default",
    backgroundColor: RetailerOrangeColors.GREY_20,
  },
  disabledContainer: {
    background: RetailerOrangeColors?.GREY200,
  },
  subLabel2Class: {
    display: "flex",
    justifyContent: "end",
    cursor: "pointer",
  },
});
const FileUploader = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    error,
    value,
    setFieldTouched,
    keyName = "",
    required = false,
    label = "",
    accept = ".pdf, .jpg, .jpeg, .png",
    errorMsg = "",
    handleUploadFile = null,
    imageChangeHandler = null,
    fetchDocument = null,
    isUploadDisabled = true,
    uploadedUrl = null,
    previewTitle = "Image",
    overrideUploadContainer = "",
    showLabel = true,
    overrideMainContainer = "",
    fileUploadSubLabel = t("upload_file_sublabel"),
    isDisabled = false,
    subLabel = "",
    showSubLabel = false,
    testID = "fileUploader_id",
    showInfoText = false,
    infoText = "",
  } = props;
  const RootContextValue = useContext(RootProvider);
  const [imageData, setImageData] = useState(value);
  const [isLoading, setIsLoading] = useState({
    loader: false,
    msg: "",
  });
  const { loader, msg } = isLoading;
  useEffect(() => {
    fetchDocument && fetchDocument({ setIsLoading });
  }, []);
  useEffect(() => {
    setImageData(value);
  }, [props?.value]);

  const getDeviceDetails = (val) => {
    let imgUrl = "";
    try {
      imgUrl = URL.createObjectURL(val);
    } catch {
      /* istanbul ignore next */
      imgUrl = val;
    }
    return (
      <Flex justify="center" align="center">
        <img src={imgUrl} style={{ width: "70%", height: "100%" }} />
      </Flex>
    );
  };
  const showImageHandler = (val, title, isPdfAfterUpload) => {
    const openModal = getPathData(RootContextValue, ["openModal"], null);
    if (isPdfAfterUpload) {
      const isPDF =
        val?.toUpperCase()?.includes(".PDF&AUTHORIZATION") ||
        val?.toUpperCase()?.includes(".TXT&AUTHORIZATION");
      if (isPDF) {
        window.open(val, "_blank");
      } else {
        window.location.assign(val);
      }
    } else {
      openModal &&
        openModal({
          isVisible: true,
          title,
          component: () => getDeviceDetails(val),
          extraModalProps: {
            size: "large",
          },
        });
    }
  };
  const onClickUpload = () => {
    handleUploadFile &&
      handleUploadFile({ imageData, setIsLoading, setImageData });
  };
  const handleImageChange = (res) => {
    setFieldTouched(keyName, true);
    setIsLoading({ loader: false, msg: "" });
    imageChangeHandler && imageChangeHandler(res);
  };
  const isPdfAfterUpload = !(
    uploadedUrl?.toUpperCase()?.includes(".JPG&AUTHORIZATION") ||
    uploadedUrl?.toUpperCase()?.includes(".PNG&AUTHORIZATION") ||
    uploadedUrl?.toUpperCase()?.includes(".JPEG&AUTHORIZATION")
  );
  return (
    <Flex
      direction="column"
      overrideClass={`${classes.uploadFileMainConatiner} ${overrideMainContainer}`}
      overrideStyle={{
        border: error
          ? `1px solid ${RetailerOrangeColors.RED}`
          : isDisabled
          ? "0px"
          : "",
        backgroundColor: isDisabled
          ? RetailerOrangeColors.GREY200
          : uploadedUrl
          ? RetailerOrangeColors.WHITE
          : "",
      }}
      testID={testID}
    >
      <Flex justify="between">
        {showLabel && (
          <Label
            label={label}
            required={required}
            labelTypographyType="fontSmallBold"
            labelColour={RetailerOrangeColors.GREY900}
            labelStyle={{
              paddingBottom: Theme.padding * 3,
            }}
            testID="uploader_label_id"
          />
        )}
        {showSubLabel && (
          <Label
            label={subLabel}
            // required={required}
            labelTypographyType="fontSmallBold"
            labelColour={RetailerOrangeColors.GREY900}
            labelStyle={{
              paddingBottom: Theme.padding * 3,
            }}
            testID="uploader_sub_label_id"
          />
        )}
      </Flex>
      <Flex align="start" direction="column">
        {showInfoText && <Typography>{infoText}</Typography>}
        <Flex
          overrideClass={`${classes.uploadContainer} ${overrideUploadContainer}`}
          justify="center"
          align="center"
          direction="column"
        >
          {loader ? (
            <OrangeLoader loaderMsg={msg} />
          ) : (
            <Suspense fallback={<OrangeLoader loaderMsg="Loading..." />}>
              <FileUploadComponent
                onChangeHandler={handleImageChange}
                uploadId={keyName}
                isDisabled={isDisabled}
                name={keyName}
                imgUrl={imageData}
                accept={accept}
                FileErrorMsg={t("file_size_loader_msg")}
                sizeMaxLimit={100000000}
                fieldName={keyName}
                renderComponent={({ preview, selectedFile }) => {
                  const isImage =
                    selectedFile?.type === "image/jpg" ||
                    selectedFile?.type === "image/jpeg" ||
                    selectedFile?.type === "image/png" ||
                    selectedFile?.type?.toUpperCase().endsWith(".JPG") ||
                    selectedFile?.type?.toUpperCase().endsWith(".JPEG") ||
                    selectedFile?.type?.toUpperCase().endsWith(".PNG") ||
                    preview?.toUpperCase()?.includes(".JPG&AUTHORIZATION") ||
                    preview?.toUpperCase()?.includes(".JPEG&AUTHORIZATION") ||
                    preview?.toUpperCase()?.includes(".PNG&AUTHORIZATION");
                  // const isPdf =
                  //   selectedFile?.type === "application/pdf" ||
                  //   selectedFile?.type?.toUpperCase().endsWith(".PDF") ||
                  //   preview?.toUpperCase()?.includes(".PDF&AUTHORIZATION");
                  const fileName = getPathData(selectedFile, ["name"], "");
                  return (
                    <DocumentUpload
                      preview={imageData ? (!isImage ? PDFICON : preview) : ""}
                      imageStyle={{
                        // width: "100%",
                        height: 80,
                        resizeMode: "contain",
                      }}
                      label={t("upload_files")}
                      isPdf={!isImage}
                      fileName={fileName}
                      subLabel={fileUploadSubLabel}
                      isDisabled={isDisabled}
                    />
                  );
                }}
              />
            </Suspense>
          )}

          {error && (
            <Typography
              color={RetailerOrangeColors.RED}
              // overrideClass={classes?.errorMsg}
            >
              {errorMsg}
            </Typography>
          )}
        </Flex>
        <Flex align="center" justify="center">
          {isUploadDisabled || !imageData ? null : (
            <Flex
              justify="center"
              align="center"
              overrideClass={`${classes.imageActionClass}  ${
                /* istanbul ignore next */
                isUploadDisabled && classes.isButtonDisabled
              }  `}
              onClick={imageData && onClickUpload}
              testID={`${keyName}_upload_btn`}
            >
              <UploadIcon />
              <Typography>{t(`upload`)}</Typography>
            </Flex>
          )}
          {!uploadedUrl ? null : (
            <Flex justify="center" align="center">
              <Flex
                justify="center"
                align="center"
                overrideClass={`${classes.imageActionClass}  ${
                  /* istanbul ignore next */
                  !imageData && classes.isButtonDisabled
                }  `}
                onClick={() =>
                  imageData &&
                  showImageHandler(uploadedUrl, previewTitle, isPdfAfterUpload)
                }
                testID={`${keyName}_preview_btn`}
              >
                <EyeIcon />
                <Typography>{t(`preview`)}</Typography>
              </Flex>
              {/* <Flex
                justify="center"
                align="center"
                overrideClass={`${classes.imageActionClass}  ${
                  imageData ? "" : classes.isButtonDisabled
                }  `}
                onClick={() => (imageData ? handleImageChange() : null)}
              >
                <CrossIcon style={{ width: 17 }} />
                <Typography>{t(`remove`)}</Typography>
              </Flex> */}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FileUploader;
