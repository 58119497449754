import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const eligibilitySlice = createSlice({
  name: "eligibility_details",
  initialState,
  reducers: {
    updateEligibilityDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetEligibilityDetails: () => initialState,
  },
});

export const { updateEligibilityDetails, resetEligibilityDetails } =
  eligibilitySlice.actions;
export default eligibilitySlice.reducer;
