import React from "react";
import { Flex } from "Component";

const FAQ = () => {
  const pdfUrl = `${process.env.PUBLIC_URL}/assets/faqs-on-installment.pdf`;
  return (
    <Flex overrideStyle={{ height: "100vh" }}>
      <iframe
        src={pdfUrl}
        width={"100%"}
        height={"100%"}
        data-testid="faq-img"
      />
    </Flex>
  );
};

export default FAQ;
