import React, { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import Flex from "./flex";
import Typography from "./Typography";
import Colors from "Global/colors";
import theme from "Global/retailerOrangeTheme";

const useStyles = createUseStyles({
  accordionHeader: {
    // @ts-ignore
    padding: theme?.padding,
    borderBottom: `1px solid ${Colors.BORDERCOLOR}`,
  },
  accordionItem: {
    overflow: "hidden",
    cursor: "pointer",
    margin: (props) => (props?.isOpen ? `${props?.openSpacing}px 0px` : "0px"),
  },

  accordionContent: {
    // @ts-ignore
    margin: theme?.margin,
    color: "#000000",
  },
  imgDown: {
    width: 27,
    height: 27,
  },
  accordionData: {
    // @ts-ignore
    padding: theme?.padding,
    // @ts-ignore
    maxHeight: (props) => props?.maxHeight,
    overflowY: "auto",
  },
});
const AccordianList = (props) => {
  const {
    options = [],
    overrideAccordionContainerClass = "",
    accordionContBackgroundColor = "",
    overrideArrowImageClass = "",
    accordionLabelTextType = "",
    overrideAccordionLabelClass = "",
    overrideAccordionComponentClass = "",
    accordionArrowImage = "",
    ...rest
  } = props;
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(options);
  }, [props.options]);
  const multipleToggleHandler = (index) => {
    const cloneData = [...data];
    setData(
      cloneData.map((res, key) => {
        if (key === index) return { ...res, isOpen: !res?.isOpen };
        else return { ...res, isOpen: false };
      })
    );
  };
  return (
    <Flex direction="column">
      {data.map((res, key) => {
        return (
          <Accordian
            key={key}
            index={key}
            testID={`accordian_${key}`}
            {...res}
            {...rest}
            multipleToggle={multipleToggleHandler}
            accordionHeaderClass={overrideAccordionContainerClass}
            accordianBackgroundColor={accordionContBackgroundColor}
            overrideImageClass={overrideArrowImageClass}
            accordionHeaderLabelType={accordionLabelTextType}
            overrideLabelClass={overrideAccordionLabelClass}
            accordianComponentClass={overrideAccordionComponentClass}
            arrowImage={accordionArrowImage}
          />
        );
      })}
    </Flex>
  );
};
export const Accordian = (props) => {
  const {
    title = "",
    component = null,
    isDefaultOpen = false,
    isOpen = isDefaultOpen ? isDefaultOpen : false,
    maxHeight = 200,
    overrideLabelClass = "",
    containerHeight = 400,
    containerClass = "",
    accordionHeaderClass = "",
    overrideLabelStyle = {},
    labelClass = "",
    accordianComponentClass = "",
    overrideImageClass = "",
    openMultiple = true,
    multipleToggle = null,
    index = null,
    testID = "accordianID",
    isCustomAccordian = false,
    showDropDown = true,
    openSpacing = theme.margin,
    accordianBackgroundColor = Colors.GREY_20,
    accordionHeaderLabelType = "",
    arrowImage = "",
    ...rest
  } = props;
  const [open, setOpen] = useState(isOpen);
  const classes = useStyles({ ...props, open, maxHeight, openSpacing });
  useEffect(() => {
    setOpen(isOpen);
  }, [props.isOpen]);
  const toggleHandler = () => {
    if (!openMultiple) {
      multipleToggle(index);
      return;
    }
    setOpen(!open);
  };
  // @ts-ignore
  const openAnimation = useSpring({
    from: { opacity: 0, maxHeight: maxHeight },
    to: { opacity: 1, maxHeight: open ? containerHeight : maxHeight },
    config: { duration: 400 },
  });
  // @ts-ignore
  const iconAnimation = useSpring({
    from: {
      transform: "rotate(0deg)",
      color: "#000000",
    },
    to: {
      transform: open ? "rotate(180deg)" : "rotate(0deg)",
      color: open ? "#10d6f5" : "#000000",
    },
    config: { duration: "400" },
  });
  return (
    // @ts-ignore
    <animated.div
      className={`${classes.accordionItem} ${containerClass}`}
      style={{ openAnimation }}
      {...rest}
    >
      <>
        <Flex
          align="center"
          justify="between"
          direction="row"
          overrideClass={`${classes.accordionHeader} ${accordionHeaderClass}`}
          data-testid={testID}
          onClick={toggleHandler}
          overrideStyle={{ backgroundColor: accordianBackgroundColor }}
        >
          {isCustomAccordian ? (
            <>
              {isCustomAccordian({ ...props })}
              {showDropDown && (
                <animated.img
                  data-testid="accordiconarrow-test"
                  className={`${overrideImageClass} ${classes.imgDown}`}
                  // @ts-ignore
                  style={iconAnimation}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAbklEQVRIie2PMQqAMAxFXwfP6qJVPIjYzcOKdUkhQ0BaCoLkLS3k818CjuN8ztApYzIDl7xvmalFsAIZuIFozKPMMrC0CAJwKIkuGVX5KdkmLEm3ci1JSlLKU49yLSmXVG1es0EANvnvInKcP/AA784fpjlWwNQAAAAASUVORK5CYII="
                />
              )}
            </>
          ) : (
            <Typography
              type={accordionHeaderLabelType ? accordionHeaderLabelType : "h6"}
              overrideClass={`${overrideLabelClass} ${labelClass}`}
              overrideStyle={overrideLabelStyle}
            >
              {title}
            </Typography>
          )}
          {showDropDown && (
            <animated.img
              data-testid="accordiconarrow-test"
              className={`${overrideImageClass} ${classes.imgDown}`}
              // @ts-ignore
              style={iconAnimation}
              src={
                arrowImage
                  ? arrowImage
                  : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAbklEQVRIie2PMQqAMAxFXwfP6qJVPIjYzcOKdUkhQ0BaCoLkLS3k818CjuN8ztApYzIDl7xvmalFsAIZuIFozKPMMrC0CAJwKIkuGVX5KdkmLEm3ci1JSlLKU49yLSmXVG1es0EANvnvInKcP/AA784fpjlWwNQAAAAASUVORK5CYII="
              }
            />
          )}
        </Flex>
        {open && component && (
          <div
            className={`${classes.accordionData} ${accordianComponentClass}`}
          >
            {component()}
          </div>
        )}
      </>
    </animated.div>
  );
};

AccordianList.propTypes = {
  options: PropTypes.array,
  overrideAccordionContainerClass: PropTypes.string,
  accordionContBackgroundColor: PropTypes.string,
  overrideArrowImageClass: PropTypes.string,
  accordionLabelTextType: PropTypes.string,
  overrideAccordionLabelClass: PropTypes.string,
  overrideAccordionComponentClass: PropTypes.string,
  accordionArrowImage: PropTypes.string,
};

Accordian.propTypes = {
  isOpen: PropTypes.bool,
  openMultiple: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  multipleToggle: PropTypes.func,
  containerClass: PropTypes.string,
  accordionHeaderClass: PropTypes.string,
  accordianComponentClass: PropTypes.string,
  overrideLableClass: PropTypes.string,
  overrideImageClass: PropTypes.string,
  overrideLabelStyle: PropTypes.object,
  labelClass: PropTypes.string,
  testID: PropTypes.string,
  component: PropTypes.func,
  overrideLabelClass: PropTypes.string,
  isCustomAccordian: PropTypes.func,
  showDropDown: PropTypes.bool,
  containerHeight: PropTypes.number,
  accordionHeaderLabelType: PropTypes.string,
  arrowImage: PropTypes.string,
};

export default AccordianList;
