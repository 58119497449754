import React from "react";
import { Flex, Typography } from "Component";
import UITranslator from "Hooks/uiTranslator";
import { StepSuccessIcon, TickIcon } from "RetailerJordan/Assets/Icons";
import { useTranslation } from "react-i18next";
import { getPathData } from "Utils/helpers";
import { createUseStyles } from "react-jss";
import Colors, { RetailerJordanColors } from "Global/colors";
import { CartStepper } from "RetailerJordan/Component";

const useStyles = createUseStyles((theme) => ({
  stepsChildContainer: {
    overflow: "hidden",
    "&:hover": {
      overflowX: "auto",
    },
    width: 344,
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    height: "max-content",
    borderRadius: theme?.borderRadius,
    position: "fixed",
    zIndex: 50,
    backgroundColor: RetailerJordanColors.WHITE,
    "@media (max-width: 1080px)": {
      position: "initial",
      width: "100%",
    },
  },
  numberIndicator: {
    width: 20,
    height: 20,
    padding: theme?.padding / 2,
    borderRadius: 20,
    // @ts-ignore
    marginRight: theme?.margin,
    // @ts-ignore
    // backgroundColor: (props) => props.unvisitedStepColor,
    backgroundColor: RetailerJordanColors.GREY200,
  },
  activeStepClass: {
    // @ts-ignore
    // backgroundColor: (props) => props.activeStepColor,
    backgroundColor: RetailerJordanColors.PRIMARY,
    color: Colors.WHITE,
  },
  previousStepClass: {
    width: 24,
    height: 24,
    // @ts-ignore
    marginRight: theme?.margin,
  },
  verticalCardDivider: {
    width: 1,
    height: "100%",
    marginLeft: theme.margin * 5,
    marginRight: theme.margin * 5,
    background: RetailerJordanColors.BORDERCOLOR_10,
  },
  stepContainer: {
    // @ts-ignore
    marginRight: 0,
    cursor: "pointer",
    borderBottom: `1px solid ${RetailerJordanColors.BORDERCOLOR_10}`,
  },
  activeStepContainer: {
    background: RetailerJordanColors.BRAND25,
    borderLeft: `2px solid ${RetailerJordanColors.PRIMARY_DARK}`,
  },
  childStepContainer: {
    // @ts-ignore
    height: 56,
    paddingLeft: theme?.padding * 6,
    paddingRight: theme?.padding * 3,
    // @ts-ignore
    marginRight: 0,
    cursor: "pointer",
  },
  childStepActiveContainer: {
    background: RetailerJordanColors.BRAND50,
  },
  parentLabelContainer: {
    paddingLeft: theme?.padding * 3,
    paddingRight: theme?.padding * 3,
    height: 68,
  },
  changeDirection: {
    direction: "rtl",
  },
  arIndicator: {
    marginLeft: theme.padding,
  },
  arChildStepContainer: {
    paddingLeft: "24px",
    paddingRight: "48px",
  },
  overrideViewClass: {
    fontWeight: "600",
    textDecoration: "underline",
    marginRight: theme.padding,
  },
}));

const Stepper = ({
  options,
  isParentActive,
  stepClickable,
  onClickHandler,
  isParentPrevious,
  isChildActive,
  isChildPrevious,
  openModal,
  overrideStepMainContainer,
  overrideStepContainer,
  overrideNumberContainer,
  overrideIndexClass,
  overrideLabelClass,
  overrideLabelStyle,
  renderCart,
  renderFallBack,
  stepChangeHandler,
  testID,
  refs,
  ...rest
}) => {
  const lang = UITranslator();
  const { t } = useTranslation();
  const classes = useStyles({});
  const {
    stepContainer,
    numberIndicator,
    activeStepClass,
    previousStepClass,
    stepsChildContainer,
    activeStepContainer,
    childStepContainer,
    childStepActiveContainer,
    parentLabelContainer,
    changeDirection,
    arIndicator,
    arChildStepContainer,
    overrideViewClass,
  } = classes;

  return (
    <Flex
      direction="column"
      overrideClass={`${stepsChildContainer} ${overrideStepMainContainer} ${
        lang ? changeDirection : undefined
      }`}
      testID={testID}
      {...rest}
    >
      {renderCart() ? (
        <CartStepper
          stepChangeHandler={stepChangeHandler}
          renderFallBack={renderFallBack}
        />
      ) : (
        options?.map((res, key) => {
          const isDisabled = getPathData(res, ["isDisabled"], false);
          return (
            <Flex
              key={key}
              overrideClass={`${stepContainer} ${
                isParentActive(key) ? activeStepContainer : ""
              } ${overrideStepContainer}`}
              direction="column"
              justify="between"
              onClick={() => {
                if (stepClickable && !isDisabled) onClickHandler(key);
              }}
              overrideStyle={{
                cursor: isDisabled ? "pointer" : "cursor",
              }}
              innerRef={refs[key]}
              testID={`stepper_${key}`}
            >
              <Flex align="center" overrideClass={parentLabelContainer}>
                {isParentPrevious(key) ? (
                  <Flex
                    overrideClass={`${previousStepClass}
                     ${overrideNumberContainer} ${
                      lang ? arIndicator : undefined
                    }
                     `}
                    justify="center"
                    align="center"
                  >
                    <StepSuccessIcon />
                  </Flex>
                ) : (
                  <Flex
                    overrideClass={`${numberIndicator} ${
                      lang ? arIndicator : undefined
                    }
                ${isParentActive(key) ? activeStepClass : ""}
                ${overrideNumberContainer}
                `}
                    justify="center"
                    align="center"
                  >
                    <Typography
                      overrideClass={overrideIndexClass}
                      type="fontXSmallBold"
                      color={RetailerJordanColors.WHITE}
                    >
                      {key + 1}
                    </Typography>
                  </Flex>
                )}

                <Typography
                  color={RetailerJordanColors.GREY800}
                  overrideClass={overrideLabelClass}
                  overrideStyle={overrideLabelStyle}
                  type="fontSmallBold"
                >
                  {t(res?.label)}
                </Typography>
              </Flex>
              {isParentActive(key) &&
                res?.children?.map((result, key1) => {
                  return (
                    <Flex
                      key={key1}
                      direction="row"
                      justify="between"
                      align="center"
                      innerRef={refs[key1]}
                      overrideClass={`${childStepContainer} ${
                        lang ? arChildStepContainer : undefined
                      }
                ${isChildActive(key1) ? childStepActiveContainer : ""}
                `}
                      testID={`childstepper_${key1}`}
                    >
                      <Typography
                        color={RetailerJordanColors.GREY600}
                        overrideClass={overrideLabelClass}
                        overrideStyle={overrideLabelStyle}
                        type="fontSmall"
                      >
                        {t(result?.label)}
                      </Typography>

                      {isChildPrevious(key1) ? (
                        <Flex
                          direction="row"
                          justify="end"
                          align="center"
                          overrideStyle={{ width: "initial" }}
                        >
                          {result?.isViewButton && (
                            <Typography
                              color={RetailerJordanColors.PRIMARY}
                              overrideClass={overrideViewClass}
                              type="fontSmall"
                              onClick={() => openModal(result)}
                              testID="viewbtn_link"
                            >
                              {t("view")}
                            </Typography>
                          )}
                          <TickIcon />
                        </Flex>
                      ) : null}
                    </Flex>
                  );
                })}
            </Flex>
          );
        })
      )}
    </Flex>
  );
};

export default Stepper;
