import ReatilerOrangeColors from "./colors";

export default {
  fontSize: "2 rem",
  padding: 8,
  primaryColor: ReatilerOrangeColors.PRIMARY,
  primaryColorLight: ReatilerOrangeColors.PRIMARY_LIGHT,
  primaryColorDark: ReatilerOrangeColors.PRIMARY_DARK,
  secondaryColor: ReatilerOrangeColors.SECONDARY,
  black: ReatilerOrangeColors.BLACK,
  white: ReatilerOrangeColors.WHITE,
  color: ReatilerOrangeColors.BLACK,
  backgroundColor: ReatilerOrangeColors.WHITE,
  borderRadius: 4,
  margin: 8,
  selectbox: {
    options: {
      fontSize: 12,
      fontFamily: "Inter-Regular",
    },
    placeholder: {
      fontSize: 12,
      fontFamily: "Inter-Regular",
    },
    singleValue: {
      fontSize: 12,
      fontFamily: "Inter-Regular",
    },
  },
  typography: {
    p: {
      margin: 2,
      fontSize: 14,
      fontFamily: "Inter-Regular",
    },
    h1: {
      margin: 0,
      fontSize: 24,
      fontFamily: "Inter-Bold",
    },
    h2: {
      margin: 0,
      fontSize: 22,
      fontFamily: "Inter-Medium",
    },
    h3: {
      margin: 0,
      fontSize: 20,
      fontFamily: "Inter-Medium",
    },
    h4: {
      margin: 0,
      fontSize: 18,
      fontFamily: "Inter-Medium",
    },
    h5: {
      margin: 0,
      fontSize: 16,
      fontFamily: "Inter-Medium",
    },
    h6: {
      margin: 0,
      fontSize: 14,
      fontFamily: "Inter-Medium",
    },
    label: {
      fontSize: 15,
      color: ReatilerOrangeColors.LABEL_COLOR,
      fontFamily: "Inter-Regular",
      letterSpacing: "0.02em",
    },
    link: {
      fontSize: 16,
      color: ReatilerOrangeColors.LINK,
      fontFamily: "Inter-Regular",
      letterSpacing: "0.02em",
    },
    notify: {
      fontSize: 15,
      color: ReatilerOrangeColors.BLACK,
      fontFamily: "Inter-Semibold",
      letterSpacing: "0.02em",
    },
    fontBold: {
      fontFamily: "Inter-Regular",
      fontSize: 24,
      fontStyle: "normal",
      fontWeight: 600,
    },
    fontLargeBold: {
      fontFamily: "Inter-Regular",
      fontSize: 20,
      fontStyle: "normal",
      fontWeight: 600,
    },
    fontLargeSemiBold: {
      fontFamily: "Inter-Regular",
      fontSize: 20,
      fontStyle: "normal",
      fontWeight: 500,
    },
    fontMediumBold: {
      fontFamily: "Inter-Regular",
      fontSize: 18,
      fontStyle: "normal",
      fontWeight: 600,
    },
    fontMediumSemiBold: {
      fontFamily: "Inter-Regular",
      fontSize: 18,
      fontStyle: "normal",
      fontWeight: 500,
    },
    fontSmallBold: {
      fontFamily: "Inter-Regular",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 600,
    },
    fontSmallSemiBold: {
      fontFamily: "Inter-Regular",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 500,
    },
    fontSmall: {
      fontFamily: "Inter-Regular",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
    },
    fontXSmallBold: {
      fontFamily: "Inter-Regular",
      fontSize: 13,
      fontStyle: "normal",
      fontWeight: 500,
    },
    fontXSmallSemiBold: {
      fontFamily: "Inter-Regular",
      fontSize: 13,
      fontStyle: "normal",
      fontWeight: 400,
    },
    fontXXSmallBold: {
      fontFamily: "Inter-Regular",
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 500,
    },
    fontXXSmallSemiBold: {
      fontFamily: "Inter-Regular",
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 400,
    },
  },
};
