import { Modal } from "Component";
import PropTypes from "prop-types";
import { RetailerOrangeColors } from "Global/colors";
import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  closeiconStyle: {
    width: 20,
    height: 20,
    padding: theme.padding,
    margin: 0,
  },
  footerContainer: {
    height: 76,
    paddingTop: theme.padding * 2,
    paddingBottom: theme.padding * 2,
    paddingRight: theme.padding * 3,
    paddingLeft: theme.padding * 3,
  },
  overrideChildrenCont: {
    backgroundColor: RetailerOrangeColors.GREY30,
    marginTop: 0,
    marginBottom: 0,
    paddingTop: theme.padding * 3,
    paddingBottom: theme.padding * 3,
  },
  overrideModalHeader: {
    // paddding: `${theme.padding + 4}px ${theme.padding + 4}px ${
    //   theme.padding + 4
    // }px ${theme.padding * 3}px`,
    paddingTop: theme.padding + 4,
    paddingRight: theme.padding * 3,
    paddingBottom: theme.padding + 4,
    paddingLeft: theme.padding * 3,
    borderBottom: `1px solid ${RetailerOrangeColors.GREY50}`,
  },
  overrideModalParent: {
    paddingBottom: 0,
    "@media (max-width: 750px)": {
      width: "90%",
    },
  },
}));

const OrangeModal = (props) => {
  const classes = useStyles();
  const {
    modalOpen = false,
    modalChildren,
    onCloseHandler = null,
    modalTitle = "",
    modalTitleComponent = null,
    modalFooterComponent = null,
    showModalFooter = false,
    showModalHeader = true,
    modalHeaderTitleType = "",
    overrideModalHeaderClass = "",
    overrideModalParentClass = "",
    overrideModalCloseStyle = "",
    overrideModalChildrenCont = "",
    overrideModalFooterClass = "",
    modalSize = "small",
    overrideModalParentStyle = {},
    buttonHandler = null,
    orangeModalTestId = "orangeModalID",
    modalCloseIcontTestId = "orangeModalCloseIcon",
    ...rest
  } = props;
  return (
    <Modal
      open={modalOpen}
      onClose={onCloseHandler}
      title={modalTitle}
      titleComponent={modalTitleComponent}
      footerComponent={modalFooterComponent}
      showFooter={showModalFooter}
      showHeader={showModalHeader}
      headerTitleType={modalHeaderTitleType}
      overrideModalHeader={`${classes.overrideModalHeader} ${overrideModalHeaderClass}`}
      overrideModalParent={`${classes.overrideModalParent} ${overrideModalParentClass}`}
      modalParentStyle={{ ...overrideModalParentStyle }}
      overrideCloseStyle={`${classes.closeiconStyle} ${overrideModalCloseStyle}`}
      overrideModalCont={`${classes.overrideChildrenCont} ${overrideModalChildrenCont}`}
      overrideModalFooter={`${classes.footerContainer} ${overrideModalFooterClass}`}
      size={modalSize}
      buttonHandler={buttonHandler}
      testID={orangeModalTestId}
      closeIcontTestId={modalCloseIcontTestId}
      {...rest}
    >
      {modalChildren}
    </Modal>
  );
};

OrangeModal.propTypes = {
  modalOpen: PropTypes.bool,
  modalChildren: PropTypes.node,
  onCloseHandler: PropTypes.func,
  modalTitle: PropTypes.string,
  modalTitleComponent: PropTypes.func,
  modalFooterComponent: PropTypes.func,
  showModalFooter: PropTypes.bool,
  showModalHeader: PropTypes.bool,
  modalHeaderTitleType: PropTypes.string,
  overrideModalHeaderClass: PropTypes.string,
  overrideModalParentClass: PropTypes.string,
  overrideModalCloseStyle: PropTypes.string,
  overrideModalChildrenCont: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  overrideModalFooterClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  modalSize: PropTypes.string,
  overrideModalParentStyle: PropTypes.object,
  buttonHandler: PropTypes.func,
  orangeModalTestId: PropTypes.string,
  modalCloseIcontTestId: PropTypes.string,
};

export default OrangeModal;
