import React, { useState, useEffect, lazy } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Flex,
  // @ts-ignore
} from "Component";
import RetailerOrangeTheme from "Global/retailerOrangeTheme";
import { HomeErrorBoundary } from "RetailerOrange/ErrorBoundary";
import { ThemeProvider } from "react-jss";
import ValidateParamsWrapper from "RetailerOrange/Hoc";
import { PARTNER_CODE } from "HttpRequest/url";
import { setFavicon } from "Global/helper";
import { fetchMetaData } from "Global/commonRequest";
import { OrangeLoader } from "./Components";
export const ThemeContext = React.createContext(null);
const PrivateRoute = lazy(() => import("RetailerOrange/auth"));
const LandingPage = lazy(() => import("RetailerOrange/Container/LandingPage"));
const LoanProcess = lazy(() => import("RetailerOrange/Container/LoanProcess"));
const BarcodeScanner = lazy(() => import("./Container/barcodeScanner"));
const MainContainer = lazy(() =>
  import("RetailerOrange/Container/mainContainer")
);
const NotFound = lazy(() => import("Component/notFound"));
const TermsAndCondition = lazy(() => import("Component/termsandcondition"));
const Login = lazy(() => import("RetailerOrange/Container/Login"));
const RootContext = lazy(() => import("RetailerOrange/Context/rootContext"));
const Wrapper = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <Flex
          overrideStyle={{ height: "100vh" }}
          justify="center"
          align="center"
        >
          {/* <Loader
            label="Loading..."
            loaderColor={RetailerOrangeColors.PRIMARY}
            secondaryColor={RetailerOrangeColors.PRIMARY_LIGHT}
          /> */}
          <OrangeLoader loaderMsg="Loading..." />
        </Flex>
      }
    >
      {children}
    </React.Suspense>
  );
};
const App = () => {
  const [isLoading, setIsLoading] = useState({
    loader: true,
    msg: "Loading...",
  });
  const { loader, msg } = isLoading;
  /* istanbul ignore next */
  useEffect(() => {
    const code = localStorage.getItem("code");
    const logo = localStorage.getItem("logo");
    const name = localStorage.getItem("name");
    const heading = localStorage.getItem("heading");
    const favicon = localStorage.getItem("favIcon");
    if (
      !code ||
      code === null ||
      code === "null" ||
      code === "" ||
      PARTNER_CODE !== code ||
      !logo ||
      logo === null ||
      logo === "null" ||
      logo === "" ||
      !name ||
      name === null ||
      name === "null" ||
      name === "" ||
      !favicon ||
      favicon === null ||
      favicon === "null" ||
      favicon === "" ||
      !heading ||
      heading === null ||
      heading === "null" ||
      heading === ""
    ) {
      fetchMetaData({
        setIsLoading,
      });
    } else {
      const favicon = localStorage.getItem("favIcon");
      const heading = localStorage.getItem("heading");
      document.title = heading;
      setFavicon(favicon);
      setIsLoading((prev) => ({ ...prev, loader: false }));
    }
  }, []);
  return (
    <HomeErrorBoundary>
      <ThemeProvider theme={RetailerOrangeTheme}>
        {loader ? (
          <Flex
            direction="row"
            justify="center"
            align="center"
            overrideStyle={{ height: "100vh" }}
          >
            <OrangeLoader loaderMsg={msg} />
          </Flex>
        ) : (
          <Router>
            <Wrapper>
              <RootContext>
                <Routes>
                  <Route
                    element={
                      <Wrapper>
                        <PrivateRoute />
                      </Wrapper>
                    }
                  >
                    <Route
                      element={
                        <Wrapper>
                          <LandingPage />
                        </Wrapper>
                      }
                    >
                      <Route
                        path="/"
                        element={
                          <Wrapper>
                            <MainContainer />
                          </Wrapper>
                        }
                      />
                      <Route
                        path="search/:mobNumber"
                        element={
                          <ValidateParamsWrapper>
                            <Wrapper>
                              <LoanProcess />
                            </Wrapper>
                          </ValidateParamsWrapper>
                        }
                      />
                    </Route>
                  </Route>
                  <Route
                    element={
                      <Wrapper>
                        <LandingPage />
                      </Wrapper>
                    }
                  >
                    <Route
                      path="/tnc"
                      element={
                        <Wrapper>
                          <TermsAndCondition />
                        </Wrapper>
                      }
                    />
                    <Route
                      path="/barcodeScanner"
                      element={
                        <Wrapper>
                          <BarcodeScanner />
                        </Wrapper>
                      }
                    />
                  </Route>
                  <Route
                    path="*"
                    element={
                      <Wrapper>
                        <NotFound />
                      </Wrapper>
                    }
                  />
                  <Route
                    index
                    path="/login"
                    element={
                      <Wrapper>
                        <Login />
                      </Wrapper>
                    }
                  />
                </Routes>
              </RootContext>
            </Wrapper>
          </Router>
        )}
      </ThemeProvider>
    </HomeErrorBoundary>
  );
};

export default App;
