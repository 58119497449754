import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { getPathData } from "Utils/helpers";
import Colors from "Global/colors";
const useStyles = createUseStyles((theme) => {
  return {
    typographyClass: (props) => ({
      ...getPathData(
        theme,
        ["typography", getPathData(props, ["type"], "")],
        {}
      ),
      // @ts-ignore
      userSelect: props?.userSelect,
      // @ts-ignore
      color: props?.disabled ? Colors.UNSELECTED : props?.color,
      "&:hover": {
        // @ts-ignore
        textDecoration: props.type === "link" ? "underline" : "null",
        // @ts-ignore
        color: props.type === "link" ? theme?.primaryColor : props?.color,
      },
    }),
  };
});
const Typography = (props) => {
  const {
    children = null,
    overrideStyle = {},
    type = "p",
    color = "inherit",
    overrideClass = "",
    disabled = false,
    userSelect = "none",
    onClick = null,
    testID = "typographyID",
    ...rest
  } = props;
  const classes = useStyles({ ...props, type, color, disabled, userSelect });
  return (
    <div
      className={`${classes.typographyClass} ${overrideClass}`}
      style={{
        color: color !== "inherit" ? color : "inherit",
        ...overrideStyle,
      }}
      onClick={onClick}
      data-testid={testID}
      {...rest}
    >
      {children}
    </div>
  );
};
Typography.propTypes = {
  children: PropTypes.node,
  overrideStyle: PropTypes.object,
  type: PropTypes.oneOf([
    "p",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "link",
    "notify",
    "label",
    "fontBold",
    "fontLargeBold",
    "fontLargeSemiBold",
    "fontLarge",
    "fontMediumBold",
    "fontMediumSemiBold",
    "fontMedium",
    "fontSmallBold",
    "fontSmallSemiBold",
    "fontSmall",
    "fontXSmallBold",
    "fontXSmallSemiBold",
    "fontXSmall",
    "fontXXSmallBold",
    "fontXXSmallSemiBold",
  ]),
  onClick: PropTypes.func,
  color: PropTypes.string,
  overrideClass: PropTypes.string,
  disabled: PropTypes.bool,
  testID: PropTypes.string,
};
export default Typography;
