import React from "react";
const TickIcon = (props) => {
  const { color = "#ffffff" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10.0279 15.5154L18.6664 6.87692C18.8151 6.72821 18.9917 6.65224 19.1962 6.64902C19.4007 6.64582 19.5805 6.72179 19.7356 6.87692C19.8907 7.03204 19.9683 7.21024 19.9683 7.41152C19.9683 7.61279 19.8907 7.79099 19.7356 7.94612L10.6606 17.0211C10.4798 17.2019 10.2689 17.2922 10.0279 17.2922C9.78689 17.2922 9.576 17.2019 9.39524 17.0211L5.22024 12.8461C5.07152 12.6974 4.99812 12.5208 5.00004 12.3163C5.00197 12.1118 5.0805 11.932 5.23561 11.7769C5.39075 11.6218 5.56895 11.5442 5.77021 11.5442C5.9715 11.5442 6.1497 11.6218 6.30481 11.7769L10.0279 15.5154Z"
        fill={color}
        {...props}
      />
    </svg>
  );
};

export default TickIcon;
