import React, { useContext } from "react";
import { useSpring, animated } from "@react-spring/web";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { Flex, Typography } from "Component";
import Theme from "Global/retailerJordanTheme";
import { RetailerJordanColors } from "Global/colors";
import { useLocation, useNavigate } from "react-router-dom";

import {
  FAQIcon,
  HomeIcon,
  // HutIcon,
  ReportIcon,
  TermsConditionIcon,
  ToggleIcon,
  CloseIcon,
} from "RetailerJordan/Assets/Icons";
import { useTranslation } from "react-i18next";
import UITranslator from "Hooks/uiTranslator";
import ReportDownloadIcon from "RetailerJordan/Assets/Icons/reportDownload";
import { RootProvider } from "RetailerJordan/Context";
import { getPathData } from "Utils/helpers";
import EshopIcon from "RetailerJordan/Assets/Icons/eshopIcon";

const useStyles = createUseStyles({
  sidebarContainer: {
    boxSizing: "border-box",
    paddingLeft: Theme.padding,
    paddingRight: Theme.padding,
    paddingTop: Theme.padding * 2,
    paddingBottom: Theme.padding,
    borderRight: `1px solid ${RetailerJordanColors.BORDERCOLOR_10}`,
    background: RetailerJordanColors.WHITE,
    height: "100vh",
    boxShadow: (styleProps) =>
      styleProps?.isSidebarVisible
        ? "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
        : "",
    "@media (max-width: 1080px)": {
      display: (styleProps) =>
        styleProps?.isSidebarVisible ? "block" : "none",
    },
  },
  activeSidebarOption: {
    height: "100%",
    width: "100%",
    backgroundColor: RetailerJordanColors.PURPLE_10,
    color: RetailerJordanColors.WHITE,
    borderRight: `1px solid COLOR_CODE`,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 25,
    borderRadius: Theme.borderRadius,
    marginTop: 20,
  },
  sidebarOptions: {
    color: Theme.black,
    cursor: "pointer",
    marginTop: 20,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 25,
    borderRadius: Theme.borderRadius,
  },
  optionClass: {
    height: "100%",
    marginTop: Theme.margin,
    "@media (max-width: 1080px)": {
      height: "90%",
    },
  },
  sideBarIcon: {
    backgroundColor: RetailerJordanColors.WHITE,
    borderRadius: 4,
  },
  activeSidebarIcon: {
    background: RetailerJordanColors.PURPLE_10,
    borderRadius: 4,
    padding: Theme.padding / 2,
  },
  lowerSidebarIcon: {
    padding: Theme.padding + 2,
    borderRadius: Theme.borderRadius * 6,
    background: RetailerJordanColors.BORDERCOLOR_10,
    cursor: "pointer",
    marginTop: 8,
  },
  lowerSidebarVisibleIcon: {
    padding: Theme.padding + 2,
    marginTop: 8,
    cursor: "pointer",
    fontSize: 25,
  },
  directionChange: {
    direction: "rtl",
  },
});
const LeftSideBar = (props) => {
  const { isSidebarVisible = false, toggleSidebar = null } = props;
  const classes = useStyles({ ...props, isSidebarVisible });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state = {} } = useContext(RootProvider);
  const isManager = getPathData(state, ["userProfile", "is_manager"], false);
  const eshop = getPathData(state, ["userProfile", "eshop"], false);
  const styles = useSpring({
    width: isSidebarVisible ? 200 : 64,
  });
  const clickOptionHandler = (path = "/", isNewTab = false) => {
    isNewTab ? window.open(path, "_blank") : navigate(path);
    isSidebarVisible ? toggleSidebar() : null;
  };

  const upperSideMenu = [
    {
      path: "/customerdetails",
      navlink: "/",
      label: t("Home"),
      onClick: null,
      component: null,
      icon: (isActive) => (
        <HomeIcon color={isActive ? RetailerJordanColors.WHITE : "#29242D"} />
      ),
      isHome: true,
      testID: "homeIcon",
    },
    isManager && {
      path: "/reports",
      navlink: "/reports",
      label: t("Reports"),
      onClick: null,
      component: null,
      testID: "reportsIcon",
      isHome: false,
      icon: (isActive) => (
        <ReportIcon color={isActive ? RetailerJordanColors.WHITE : "#29242D"} />
      ),
    },
    isManager && {
      path: "/download-reports",
      navlink: "/download-reports",
      label: t("report_download"),
      onClick: null,
      component: null,
      icon: (isActive) => (
        <ReportDownloadIcon
          color={isActive ? RetailerJordanColors.WHITE : "#29242D"}
        />
      ),
      isHome: false,
      testID: "downloadReportsIcon",
    },
    eshop && {
      path: "/eshop",
      navlink: "/eshop",
      label: t("eshop"),
      onClick: null,
      component: null,
      testID: "eshopIcon",
      isHome: false,
      icon: (isActive) => (
        <EshopIcon color={isActive ? RetailerJordanColors.WHITE : "#29242D"} />
      ),
    },
  ].filter(Boolean);

  const lowerSideMenu = [
    {
      path: "",
      navlink: "/faq",
      label: t("FAQs"),
      onClick: null,
      component: null,
      isNewTab: true,
      testID: "faqIcon",
      icon: <FAQIcon />,
    },
    {
      path: "",
      label: t("Terms and Conditions"),
      onClick: null,
      component: null,
      isNewTab: false,
      testID: "tncIcon",
      icon: <TermsConditionIcon />,
    },
  ];

  const lang = UITranslator();

  return (
    <animated.div
      style={{ ...styles, position: "fixed", zIndex: 111, left: 0 }}
    >
      <Flex
        direction="column"
        align="center"
        overrideClass={classes.sidebarContainer}
        data-testid="leftsidemenu"
      >
        {isSidebarVisible ? (
          <CloseIcon
            onClick={() => (toggleSidebar ? toggleSidebar() : null)}
            style={{
              alignSelf: "flex-end",
              cursor: "pointer",
              display: "block",
              marginLeft: "auto",
            }}
            data-testid="closesidebar_icon"
          />
        ) : (
          <ToggleIcon
            onClick={() => (toggleSidebar ? toggleSidebar() : null)}
            data-testid="barsIcon_id"
            style={{
              alignSelf: "center",
              cursor: "pointer",
            }}
          />
        )}
        <Flex
          direction="column"
          justify="between"
          align="center"
          overrideClass={classes.optionClass}
        >
          <Flex direction="column" align="center" testID="uppersidemenu">
            {upperSideMenu.map((res, key) => {
              const { path = "", navlink = "", isHome = false, testID } = res;
              let isActive = isHome
                ? pathname === "/" || pathname.startsWith(path)
                  ? true
                  : false
                : pathname.startsWith(path);
              return (
                <Flex
                  overrideClass={`${
                    isActive
                      ? classes.activeSidebarOption
                      : classes.sidebarOptions
                  } ${lang ? classes.directionChange : undefined}`}
                  justify="start"
                  align="center"
                  key={key}
                  onClick={() => clickOptionHandler(navlink)}
                  testID={testID}
                >
                  {!isSidebarVisible ? (
                    <Flex
                      justify="center"
                      align="center"
                      overrideClass={
                        isActive
                          ? classes.activeSidebarIcon
                          : classes.sideBarIcon
                      }
                    >
                      {res?.icon(isActive)}
                    </Flex>
                  ) : (
                    <Flex
                      justify="start"
                      align="center"
                      overrideClass={`${
                        isActive
                          ? classes.activeSidebarIcon
                          : classes.sideBarIcon
                      } ${lang ? classes.directionChange : undefined}`}
                    >
                      {res?.icon(isActive)}

                      <Typography
                        color={
                          isActive
                            ? RetailerJordanColors.WHITE
                            : RetailerJordanColors.BLACK
                        }
                      >
                        {res.label}
                      </Typography>
                    </Flex>
                  )}
                  {/* <Typography>{res.label}</Typography> */}
                </Flex>
              );
            })}
          </Flex>
          <Flex
            direction="column"
            align="center"
            overrideClass={lang ? classes.directionChange : undefined}
            testID="lowersidemenu"
          >
            {lowerSideMenu.map((res, key) => {
              const { navlink = "", isNewTab = false, testID } = res;

              return (
                <Flex
                  align="center"
                  key={key}
                  onClick={() => clickOptionHandler(navlink, isNewTab)}
                  testID={testID}
                >
                  {!isSidebarVisible ? (
                    <Flex
                      justify="center"
                      align="center"
                      overrideClass={`${classes.lowerSidebarIcon} ${
                        lang ? classes.directionChange : undefined
                      }`}
                    >
                      {res.icon}
                    </Flex>
                  ) : (
                    <Flex
                      justify="start"
                      align="center"
                      overrideClass={classes.lowerSidebarVisibleIcon}
                    >
                      {res.icon}

                      <Typography color={RetailerJordanColors.BLACK}>
                        {res.label}
                      </Typography>
                    </Flex>
                  )}
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
    </animated.div>
  );
};

LeftSideBar.propTypes = {
  isSidebarVisible: PropTypes.bool,
  toggleSidebar: PropTypes.func,
};

export default React.memo(LeftSideBar);
