import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { SelectBox } from "Component";
import { RetailerOrangeColors } from "Global/colors";

const useStyles = createUseStyles((theme) => ({
  selecboxMainCont: {
    // marginTop: theme.margin * 3,
    padding: theme.padding + 4,
    height: 42,
    border: `1px solid ${RetailerOrangeColors.GREY200}`,
  },
  selecboxOptionContainer: {
    padding: theme.padding - 4,
    height: 128,
  },
  optionClassStyle: {
    padding: theme.padding + 2,
    fontFamily: "Inter",
    fontSize: 13,
    fontWeight: 400,
    textAlign: "left",
  },
  selectedOption: {
    backgroundColor: RetailerOrangeColors.GREY100,
    color: RetailerOrangeColors.GREY900,
  },
  optionLabelClass: {
    marginLeft: 0,
  },
}));

const OrangeSelectbox = (props) => {
  const {
    options = [],
    label = "",
    value = [],
    showLabel = true,
    isSearchable = true,
    isMulti = true,
    isCustom = true,
    labelKey = "label",
    valueKey = "value",
    searchKey = "search",
    placeholder = "",
    inputType = "text_type",
    onOptionSelectHandler = null,
    responseType = "array_value",
    required = false,
    error = false,
    errorMsg = "",
    OrangeSelectboxTestID = "orange_selectbox_input",
  } = props;
  const classes = useStyles();
  return (
    <SelectBox
      options={options}
      label={label}
      value={value}
      showLabel={showLabel}
      isSearchable={isSearchable}
      isMulti={isMulti}
      isCustom={isCustom}
      labelKey={labelKey}
      valueKey={valueKey}
      searchKey={searchKey}
      placeholder={placeholder}
      inputType={inputType}
      overrideMainContainerClass={classes.selecboxMainCont}
      handleChange={onOptionSelectHandler}
      overrideStraightLineStyle={{ display: "none" }}
      rightComponentContainerStyle={{ width: 30 }}
      overrideOptionContClass={classes.selecboxOptionContainer}
      overrideOptionClass={classes.optionClassStyle}
      overrideTypographyStyle={classes.optionLabelClass}
      overrideSelectedOptionClass={classes.selectedOption}
      responseType={responseType}
      required={required}
      error={error}
      errorMsg={errorMsg}
      testID={OrangeSelectboxTestID}
    />
  );
};

OrangeSelectbox.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
    PropTypes.number,
  ]),
  showLabel: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isCustom: PropTypes.bool,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  onOptionSelectHandler: PropTypes.func,
  inputType: PropTypes.oneOf(["single_value", "array_value"]),
  searchKey: PropTypes.string,
  responseType: PropTypes.oneOf(["single_value", "array_value"]),
  required: PropTypes.bool,
  OrangeSelectboxTestID: PropTypes.string,
};

export default OrangeSelectbox;
