import React from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import { Typography, Flex } from "Component";
import { RetailerOrangeColors } from "Global/colors";
import Theme from "Global/retailerOrangeTheme";
const useStyles = createUseStyles({
  uploadButton: {
    color: RetailerOrangeColors.PRIMARY,
    marginTop: Theme?.margin,
  },
  fileName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    minWidth: "70%",
    maxWidth: "100%",
    textAlign: "center",
  },
});
const DocumentsUpload = (props) => {
  const { t } = useTranslation();
  const {
    preview = "",
    label = t("upload_file_label"),
    subLabel = t("upload_file_sublabel"),
    imageStyle = { width: "100%", height: 110, resizeMode: "contain" },
    fileName = "",
  } = props;
  const classes = useStyles();
  return (
    <Flex direction="row" align="center" justify="center">
      {!preview ? (
        <Flex direction="column" align="center" testID="upload_file">
          {" "}
          <img src={process.env.PUBLIC_URL + "/assets/cloud_upload.png"} />
          <Flex
            overrideClass={classes.uploadButton}
            justify="center"
            align="center"
            direction="column"
          >
            <Typography
              type="fontXSmallBold"
              color={RetailerOrangeColors.GREY900}
              overrideStyle={{
                marginBottom: Theme.margin,
                textAlign: "center",
              }}
            >
              {label}
            </Typography>
            <Typography
              type="fontXXSmallSemiBold"
              color={RetailerOrangeColors.GREY500}
              overrideStyle={{
                textAlign: "center",
                width: "90%",
                wordWrap: "break-word",
              }}
            >
              {subLabel}
            </Typography>
          </Flex>
        </Flex>
      ) : (
        <Flex direction="column" align="center" testID="file_preview">
          <img src={preview} style={imageStyle} alt={fileName} />
          <Typography
            overrideClass={classes.fileName}
            testID="uploaded_filename"
          >
            {fileName}
          </Typography>
        </Flex>
      )}
      {/* <Typography color={Colors.RED}>{errorMsg}</Typography> */}
    </Flex>
  );
};

export default DocumentsUpload;
