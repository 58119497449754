import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  kyc_data: {},
  transformed_kyc_data: {},
};

const kycSlice = createSlice({
  name: "kyc_details",
  initialState,
  reducers: {
    updateKycData: (state, action) => {
      state.kyc_data = { ...state.kyc_data, ...action.payload };
    },
    updateTransformedKycData: (state, action) => {
      state.transformed_kyc_data = {
        ...state.transformed_kyc_data,
        ...action.payload,
      };
    },
    resetKycData: (state) => {
      state.kyc_data = {};
      state.transformed_kyc_data = {};
    },
  },
});

export const { updateKycData, updateTransformedKycData, resetKycData } =
  kycSlice.actions;
export default kycSlice.reducer;
