import React, { useEffect, useState, Suspense, useContext } from "react";
import { useSelector } from "react-redux";
import { Flex, Modal, Typography } from "Component";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter, getPathData } from "Utils/helpers";
import Colors, { RetailerJordanColors } from "Global/colors";
import DeviceObserver from "Hooks/deviceDetect";
import UITranslator from "Hooks/uiTranslator";
import { RootProvider } from "RetailerJordan/Context";
import { JordanLoader, Stepper } from "RetailerJordan/Component";
import CircleStepper from "RetailerJordan/Assets/Icons/circleStepper";

const useStyles = createUseStyles((theme) => ({
  stepsMainContainer: {
    paddingLeft: theme.padding * 5,
    paddingRight: theme.padding * 5,
    backgroundColor: RetailerJordanColors.WHITE,
    "@media (max-width: 550px)": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  stepsChildContainer: {
    overflow: "hidden",
    "&:hover": {
      overflowX: "auto",
    },
    width: 344,
    zIndex: 99,
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    height: "max-content",
    borderRadius: theme?.borderRadius,
    position: "fixed",
    backgroundColor: RetailerJordanColors.WHITE,
  },
  numberIndicator: {
    width: 20,
    height: 20,
    padding: theme?.padding / 2,
    borderRadius: 20,
    // @ts-ignore
    marginRight: theme?.margin,
    // @ts-ignore
    backgroundColor: (props) => props.unvisitedStepColor,
  },
  activeStepClass: {
    // @ts-ignore
    backgroundColor: (props) => props.activeStepColor,
    color: Colors.WHITE,
  },
  previousStepClass: {
    width: 24,
    height: 24,
    // @ts-ignore
    marginRight: theme?.margin,
  },
  verticalCardDivider: {
    width: 1,
    height: "100%",
    marginLeft: theme.margin * 5,
    marginRight: theme.margin * 5,
    background: RetailerJordanColors.BORDERCOLOR_10,
  },
  stepContainer: {
    // @ts-ignore
    marginRight: 0,
    cursor: "pointer",
    borderBottom: `1px solid ${RetailerJordanColors.BORDERCOLOR_10}`,
  },
  activeStepContainer: {
    background: RetailerJordanColors.BRAND25,
    borderLeft: `2px solid ${RetailerJordanColors.PRIMARY_DARK}`,
  },
  childStepContainer: {
    // @ts-ignore
    height: 56,
    paddingLeft: theme?.padding * 6,
    paddingRight: theme?.padding * 3,
    // @ts-ignore
    marginRight: 0,
    cursor: "pointer",
  },
  childStepActiveContainer: {
    background: RetailerJordanColors.BRAND50,
  },
  parentLabelContainer: {
    paddingLeft: theme?.padding * 3,
    paddingRight: theme?.padding * 3,
    height: 68,
  },
  changeDirection: {
    direction: "rtl",
  },
  arIndicator: {
    marginLeft: theme.padding,
  },
  modalFooterStyle: {
    paddingTop: theme.padding * 2,
    paddingLeft: 0,
  },
  arChildStepContainer: {
    paddingLeft: theme.padding * 3,
    paddingRight: theme.padding * 6,
  },
  overrideViewClass: {
    fontWeight: "600",
    textDecoration: "underline",
    marginRight: theme.padding,
  },
  directionChange: {
    direction: "rtl",
  },
  overrideModalHeader: {
    paddingRight: theme.padding * 2,
  },
  cardClass: {
    paddingLeft: theme.padding * 3,
    paddingBottom: theme.padding,
    color: `${RetailerJordanColors.PRIMARY}!important`,
  },
  cartHeader: {
    padding: theme.padding * 2,
  },

  delCont: { width: "10%", marginTop: theme.margin * 1.5, cursor: "pointer" },
  imageWrapper: {
    width: "20%",
    "& img": {
      width: theme.padding * 6,
      marginBottom: theme.padding,
    },
  },
  contentWrapper: {
    width: "80%",
    marginLeft: theme.margin,
    marginRight: theme.margin,
  },
  cartTitle: {
    fontSize: theme.margin * 2,
    color: `${RetailerJordanColors.GREY900}!important`,
    fontWeight: 600,
    marginBottom: theme.margin / 2,
  },
  cartDesc: {
    fontSize: theme.margin * 1.5,
    color: `${RetailerJordanColors.GREY400}!important`,
    marginBottom: theme.margin,
  },
  priceDetails: {
    borderTop: `1px solid ${RetailerJordanColors.BORDERCOLOR_10}`,
    paddingTop: theme.margin,
  },
  priceLabel: {
    fontSize: theme.margin * 1.5,
    color: `${RetailerJordanColors.GREY400}!important`,
    marginBottom: theme.margin / 2,
  },
  priceAmount: {
    fontSize: 14,
    color: `${RetailerJordanColors.TEXTMEDIUMEMPHASIS}!important`,
    fontWeight: 600,
    marginBottom: theme.margin / 2,
  },
  cartBody: {
    height: "50vh",
    overflowY: "scroll",
    marginBottom: theme.margin * 2,
    padding: theme.padding * 2,
    paddingTop: 0,
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  cartFooter: {
    borderTop: `1px solid ${RetailerJordanColors.BORDERCOLOR}`,
    padding: theme.padding * 2,
    "& button": {
      marginTop: theme.margin * 2,
    },
  },
  totalAmount: {
    color: `${RetailerJordanColors.GREY900}!important`,
    fontSize: "18px",
    fontWeight: 600,
  },
  rightAlign: {
    textAlign: "right",
    alignItems: "flex-end",
  },
  minTax: {
    fontSize: "10px",
  },
  buttonClass: {
    marginTop: 0,
  },
  footer: {
    paddingTop: theme.padding * 2,
    paddingLeft: 0,
    paddingRight: theme.padding * 3,
  },
  cartCont: {
    width: "90%",
  },
  circularProgressBar: {
    width: theme.padding * 12,
    position: "relative",
  },
  circularProgress: {
    width: "100%",
    height: "100%",
  },

  circleBackground: {
    fill: "none",
    stroke: RetailerJordanColors.GREY,
    strokeWidth: "2",
  },
  circleProgress: {
    fill: "none",
    stroke: RetailerJordanColors.PURPLE_10,
    strokeWidth: "2",
    strokeLinecap: "round",
    transition: "stroke-dasharray 0.3s",
  },

  completedCircleProgress: {
    stroke: `${RetailerJordanColors.SUCCESS}!important`,
  },

  percentageText: {
    fill: "#000",
    fontSize: (theme.padding * 3) / 4,
    fontFamily: "PlusJakartaSans-Regular",
    fontWeight: 600,
    letterSpacing: "0.28px",
    textAnchor: "middle",
  },
  circularProgressBarWrapper: {
    width: "initial",
    marginRight: theme.padding * 2,
  },
  progressBarWrapper: {
    paddingLeft: theme.padding * 2,
    paddingBottom: theme.padding * 4,
    paddingTop: theme.padding * 2,
  },
  rightLabel: {
    marginRight: theme.margin,
  },

  footerWrapper: {
    paddingLeft: theme.padding * 2,
    paddingRight: theme.padding * 2,
  },
}));
const JordanStepper = (props) => {
  const {
    options = [],
    onSelectStep = null,
    parentStep = 0,
    childStep = null,
    renderFallBack = null,
    activeStepColor = RetailerJordanColors.PRIMARY,
    previousStepColor = RetailerJordanColors.PRIMARY,
    unvisitedStepColor = RetailerJordanColors.GREY200,
    overrideStepMainContainer = "",
    overrideStepContainer = "",
    overrideNumberContainer = "",
    overrideContainer = "",
    overrideIndexClass = "",
    overrideLabelClass = "",
    stepClickable = false,
    overrideLabelStyle = {},
    testID = "stepperID",
    overrideGridStyle = "",
    overrideComponentClass = "",
    onStepChange = null,
    ...rest
  } = props;
  const { isDesktop } = DeviceObserver();
  const lang = UITranslator();
  const { t } = useTranslation();
  const { state = {} } = useContext(RootProvider);
  const shopName = getPathData(state, ["userProfile", "shop_name"], "");
  const { items: products = [], ...product_data } = useSelector(
    (state) => state?.multi_product_details
  );
  const [activeStep, setActiveStep] = useState({
    parentStep: 0,
    childStep: null,
  });
  const [showModal, setShowModal] = useState({
    component: null,
    isVisible: false,
    title: "",
    showFooter: false,
  });
  const [isMobileStepper, setIsMobileStepper] = useState(false);
  const classes = useStyles({
    ...props,
    activeStep,
    activeStepColor,
    previousStepColor,
    unvisitedStepColor,
  });
  const { stepsMainContainer } = classes;
  const refs = options.reduce((acc, value, index) => {
    acc[index] = React.createRef();
    return acc;
  }, {});
  const onClickHandler = (tabNumber) => {
    if (onSelectStep) onSelectStep(tabNumber);
    if (tabNumber > activeStep) {
      if (refs[tabNumber + 3]) {
        refs[tabNumber + 3].current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } else
        refs[options.length - 1].current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
    } else {
      if (refs[tabNumber - 2])
        refs[tabNumber - 2].current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      else
        refs[0].current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
    }
  };
  const isParentPrevious = (key) => {
    return key < activeStep?.parentStep;
  };
  const isParentActive = (key) => {
    return key === activeStep?.parentStep;
  };
  const isChildActive = (key) => {
    return key === activeStep?.childStep;
  };
  const isChildPrevious = (key) => {
    return key < activeStep?.childStep;
  };
  useEffect(() => {
    setActiveStep({
      parentStep,
      childStep,
    });
  }, [props.parentStep, props.childStep]);
  let renderComponent = null;
  const isChildrenAvailable = getPathData(
    options,
    [activeStep.parentStep, "children"],
    []
  );
  if (isChildrenAvailable && isChildrenAvailable?.length > 0) {
    renderComponent = getPathData(
      options,
      [activeStep.parentStep, "children", activeStep?.childStep, "component"],
      null
    );
  } else {
    renderComponent = getPathData(
      options,
      [activeStep.parentStep, "component"],
      null
    );
  }

  const closeModal = () => {
    setIsMobileStepper(false);
    setShowModal({
      component: null,
      isVisible: false,
      title: "",
      showFooter: false,
    });
  };

  const openModal = (result) => {
    setIsMobileStepper(false);
    setShowModal({
      component: result?.modalProps?.modalComponent,
      isVisible: true,
      title: result?.modalProps?.title,
      showFooter: false,
    });
  };

  const stepChangeHandler = () => {
    closeModal();
    onStepChange &&
      onStepChange({
        parentStep: 1,
        childStep: 1,
      });
  };

  const renderCart = () => {
    const isInstrumentPresent = getPathData(product_data, ["id"], false);
    if (
      activeStep?.parentStep === 1 &&
      activeStep?.childStep === 0 &&
      products?.length !== 0 &&
      !isInstrumentPresent
    ) {
      return true;
    }
    return false;
  };

  const getCurrentChildStep = (children) => {
    for (let i = 0; i < children.length; i++) {
      if (isChildActive(i)) {
        return i;
      }
    }
    return -1;
  };

  const calculateRemainingSteps = (
    options,
    currentParentStep,
    currentSubStep
  ) => {
    let remainingSteps = 0;

    for (let i = currentParentStep + 1; i < options.length; i++) {
      remainingSteps += options[i].children.length;
    }
    remainingSteps +=
      options[currentParentStep].children.length - currentSubStep - 1;

    return remainingSteps;
  };

  const openMobileStepper = () => {
    setShowModal({
      isVisible: true,
      title: "step",
      showFooter: true,
      component: null,
    });
    setIsMobileStepper(true);
  };

  return (
    <Flex
      overrideClass={`${stepsMainContainer} ${overrideContainer}`}
      overrideStyle={{ ...overrideGridStyle }}
      direction={isDesktop ? "row" : "column"}
    >
      {isDesktop ? (
        <Stepper
          options={options}
          isParentActive={isParentActive}
          stepClickable={stepClickable}
          onClickHandler={onClickHandler}
          isParentPrevious={isParentPrevious}
          isChildActive={isChildActive}
          isChildPrevious={isChildPrevious}
          openModal={openModal}
          overrideStepMainContainer={overrideStepMainContainer}
          overrideStepContainer={overrideStepContainer}
          overrideNumberContainer={overrideNumberContainer}
          overrideIndexClass={overrideIndexClass}
          overrideLabelClass={overrideLabelClass}
          overrideLabelStyle={overrideLabelStyle}
          testID={testID}
          renderFallBack={renderFallBack}
          renderCart={renderCart}
          stepChangeHandler={stepChangeHandler}
          refs={refs}
          {...rest}
        />
      ) : (
        <Flex
          overrideClass={lang ? classes.changeDirection : undefined}
          direction="column"
        >
          <Flex>
            <Typography type="fontSmallBold" overrideClass={classes.cardClass}>
              {capitalizeFirstLetter(shopName)}
            </Typography>
          </Flex>
          {options.map((res, key) => {
            const totalSteps = options.reduce(
              (acc, step) => acc + step.children.length,
              0
            );

            const currentStep = getCurrentChildStep(res.children);

            const remainingSteps = calculateRemainingSteps(
              options,
              key,
              currentStep
            );

            const percentage =
              ((totalSteps - remainingSteps) / (totalSteps + 1)) * 100;
            return (
              isParentActive(key) && (
                <Flex
                  key={key}
                  align="center"
                  overrideClass={classes.progressBarWrapper}
                >
                  <Flex overrideClass={classes.circularProgressBarWrapper}>
                    <Flex
                      direction="column"
                      overrideClass={classes.circularProgressBar}
                      testID="mobile_stepper"
                      onClick={openMobileStepper}
                    >
                      <CircleStepper
                        isCompleted={
                          product_data?.status === "events_scheduled"
                            ? true
                            : false
                        }
                        percentage={percentage}
                        val={key}
                        options={options}
                      />
                    </Flex>
                  </Flex>
                  <Flex direction="column">
                    <Typography
                      type="h3"
                      overrideClass={lang ? classes.rightLabel : undefined}
                    >
                      {t(res.label)}
                    </Typography>
                    {res?.children.map((item, index) => {
                      return (
                        isChildActive(index) && (
                          <Typography
                            type="p"
                            color={RetailerJordanColors.UNSELECTED}
                            overrideClass={
                              lang ? classes.rightLabel : undefined
                            }
                            key={index}
                          >
                            {t(item?.label)}
                          </Typography>
                        )
                      );
                    })}
                  </Flex>
                </Flex>
              )
            );
          })}
        </Flex>
      )}
      <Modal
        open={showModal?.isVisible}
        onClose={closeModal}
        buttonHandler={closeModal}
        titleComponent={() => {
          return (
            <Flex>
              <Typography type="h3">{t(showModal?.title)}</Typography>
            </Flex>
          );
        }}
        showFooter={false}
        overrideModalParent={lang ? classes.directionChange : undefined}
        overrideModalHeader={lang ? classes.overrideModalHeader : undefined}
        // headerStyle={showModal?.showFooter && { display: "none" }}
        overrideModalFooter={classes.modalFooterStyle}
        // footerComponent={() => {
        //   return (
        //     <Flex overrideClass={classes.footerWrapper}>
        //       <JordanButton
        //         msg={t("close")}
        //         customClass={classes.buttonClass}
        //         onClickHandler={closeModal}
        //       />
        //     </Flex>
        //   );
        // }}
      >
        {isMobileStepper && (
          <Stepper
            options={options}
            isParentActive={isParentActive}
            stepClickable={stepClickable}
            onClickHandler={onClickHandler}
            isParentPrevious={isParentPrevious}
            isChildActive={isChildActive}
            isChildPrevious={isChildPrevious}
            openModal={openModal}
            overrideStepMainContainer={overrideStepMainContainer}
            overrideStepContainer={overrideStepContainer}
            overrideNumberContainer={overrideNumberContainer}
            overrideIndexClass={overrideIndexClass}
            overrideLabelClass={overrideLabelClass}
            overrideLabelStyle={overrideLabelStyle}
            renderFallBack={renderFallBack}
            renderCart={renderCart}
            stepChangeHandler={stepChangeHandler}
            testID={testID}
            refs={refs}
            {...rest}
          />
        )}
        <Suspense
          fallback={renderFallBack ? renderFallBack() : <JordanLoader />}
        >
          {showModal?.component ? showModal?.component() : null}
        </Suspense>
      </Modal>
      <Flex overrideClass={overrideComponentClass}>
        {isDesktop && <div className={classes.verticalCardDivider}></div>}
        <Flex
          overrideStyle={{ flexGrow: 1, overflowY: "hidden" }}
          testID="rendredcomponent_id"
          direction={isDesktop ? "row" : "column"}
        >
          {!isDesktop && (
            <Flex>
              <Typography
                type="fontSmallBold"
                overrideClass={classes.cardClass}
              >
                {capitalizeFirstLetter(shopName)}
              </Typography>
            </Flex>
          )}
          <Suspense
            fallback={renderFallBack ? renderFallBack() : <JordanLoader />}
          >
            {renderComponent ? renderComponent() : null}
          </Suspense>
        </Flex>
      </Flex>
    </Flex>
  );
};

JordanStepper.propTypes = {
  options: PropTypes.array,
  overrideContainer: PropTypes.string,
  overrideStepMainContainer: PropTypes.string,
  overrideStepContainer: PropTypes.string,
  overrideLabelClass: PropTypes.string,
  overrideStepBar: PropTypes.string,
  overrideNumberContainer: PropTypes.string,
  overrideIndexClass: PropTypes.string,
  overrideLabelStyle: PropTypes.object,
  stepClickable: PropTypes.bool,
  testID: PropTypes.string,
  onSelectStep: PropTypes.func,
  renderFallBack: PropTypes.func,
  parentStep: PropTypes.number,
  childStep: PropTypes.number,
  overrideGridStyle: PropTypes.object,
  overrideComponentClass: PropTypes.string,
  onStepChange: PropTypes.func,
};
export default JordanStepper;
