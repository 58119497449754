import { configureStore } from "@reduxjs/toolkit";
import kycReducer from "./Reducers/kycSlice";
import eligibilityReducer from "./Reducers/eligibilitySlice";
import productReducer from "./Reducers/productSlice";
import multiProductReducer from "./Reducers/multiProductSlice";

export const store = configureStore({
  reducer: {
    kyc_details: kycReducer,
    eligibility_details: eligibilityReducer,
    product_details: productReducer,
    multi_product_details: multiProductReducer,
  },
});
