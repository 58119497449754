import { RetailerJordanColors } from "Global/colors";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  circularProgressBar: {
    width: theme.padding * 12,
    position: "relative",
  },
  circularProgress: {
    width: "100%",
    height: "100%",
  },

  circleBackground: {
    fill: "none",
    stroke: RetailerJordanColors.GREY,
    strokeWidth: "2",
  },
  circleProgress: {
    fill: "none",
    stroke: RetailerJordanColors.PURPLE_10,
    strokeWidth: "2",
    strokeLinecap: "round",
    transition: "stroke-dasharray 0.3s",
  },

  completedCircleProgress: {
    stroke: `${RetailerJordanColors.SUCCESS}!important`,
  },

  percentageText: {
    fill: "#000",
    fontSize: (theme.padding * 3) / 4,
    fontFamily: "PlusJakartaSans-Regular",
    fontWeight: 600,
    letterSpacing: "0.28px",
    textAnchor: "middle",
  },
  circularProgressBarWrapper: {
    width: "initial",
    marginRight: theme.padding * 2,
  },
  progressBarWrapper: {
    paddingLeft: theme.padding * 2,
    paddingBottom: theme.padding * 4,
    paddingTop: theme.padding * 2,
  },
  modalButtonClass: {
    background: RetailerJordanColors.PURPLE_10,
    border: `1px solid ${RetailerJordanColors.PURPLE_10}`,
    color: RetailerJordanColors.WHITE,
    width: "100%",
    fontSize: 18,
    borderBottomLeftRadius: theme.borderRadius - 2,
    borderBottomRightRadius: theme.borderRadius - 2,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));

const CircleStepper = ({ isCompleted, percentage, val, options }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <svg viewBox="0 0 36 36" className={classes.circularProgress}>
      <path
        className={classes.circleBackground}
        d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        className={`${classes.circleProgress}  ${
          isCompleted ? classes.completedCircleProgress : undefined
        }`}
        data-testid="completed_progress"
        strokeDasharray={`${isCompleted ? 100 : percentage}, 100`}
        d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <text x="18" y="20.35" className={classes.percentageText}>
        {`${val + 1} ${t("of")} ${options?.length}`}
      </text>
    </svg>
  );
};

export default CircleStepper;
