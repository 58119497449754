import React from "react";
import { Button, Typography } from "Component";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { RetailerOrangeColors } from "Global/colors";
import Theme from "Global/retailerOrangeTheme";

const useStyles = createUseStyles({
  buttonClass: {
    background: RetailerOrangeColors.PRIMARY,
    border: `1px solid ${RetailerOrangeColors.PRIMARY}`,
    color: RetailerOrangeColors.WHITE,
    width: "100%",
    marginTop: Theme?.margin * 3,
    borderRadius: Theme.borderRadius * 2,
    height: 44,
  },
});

const OrangeButton = ({
  onClickHandler,
  isValid = null,
  msg,
  customClass = "",
  testID = "orangeButtonId",
  type = "fontSmallBold",
  btnType = "filled",
  buttonTextColor = "",
  overrideButtonStyle = {},
}) => {
  const classes = useStyles();
  const { buttonClass } = classes;
  return (
    <Button
      onClick={onClickHandler}
      disabled={isValid}
      buttonClass={`${buttonClass} ${customClass}`}
      overrideStyle={{ ...overrideButtonStyle }}
      testID={testID}
      btnType={btnType}
    >
      <Typography
        type={type}
        color={buttonTextColor ? buttonTextColor : RetailerOrangeColors.WHITE}
      >
        {msg}
      </Typography>
    </Button>
  );
};

OrangeButton.propTypes = {
  onClickHandler: PropTypes.func,
  isValid: PropTypes.bool,
  msg: PropTypes.string,
  customClass: PropTypes.string,
  type: PropTypes.string,
  btnType: PropTypes.string,
  buttonTextColor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  overrideButtonStyle: PropTypes.object,
};

export default OrangeButton;
