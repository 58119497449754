import React from "react";
import { Button, Typography } from "Component";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { RetailerJordanColors } from "Global/colors";
import Theme from "Global/retailerJordanTheme";

const useStyles = createUseStyles({
  buttonClass: {
    background: RetailerJordanColors.PURPLE_10,
    border: `1px solid ${RetailerJordanColors.PURPLE_10}`,
    color: RetailerJordanColors.WHITE,
    width: "100%",
    marginTop: Theme?.margin * 3,
    borderRadius: Theme.borderRadius * 2,
    height: 44,
  },
});

const JordanButton = ({
  onClickHandler,
  isValid = null,
  msg,
  customClass = "",
  testID = "jordanButtonId",
  type = "fontMedium",
  btnType = "filled",
  buttonTextColor = "",
}) => {
  const classes = useStyles();
  const { buttonClass } = classes;
  return (
    <Button
      onClick={onClickHandler}
      disabled={isValid}
      buttonClass={`${buttonClass} ${customClass}`}
      testID={testID}
      btnType={btnType}
    >
      <Typography
        type={type}
        color={buttonTextColor ? buttonTextColor : RetailerJordanColors.WHITE}
      >
        {msg}
      </Typography>
    </Button>
  );
};

JordanButton.propTypes = {
  onClickHandler: PropTypes.func,
  isValid: PropTypes.bool,
  msg: PropTypes.string,
  customClass: PropTypes.string,
  type: PropTypes.string,
  btnType: PropTypes.string,
  buttonTextColor: PropTypes.string,
};

export default JordanButton;
