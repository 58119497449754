import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { Flex, Toast } from "Component";
const { toast } = Toast;
const useStyles = createUseStyles({
  container: {
    cursor: "pointer",
    width: "100%",
  },
  uploadContainer: {
    display: "none",
  },
  parentContainer: {
    overflow: "hidden",
  },
});
const FileUploadComponent = (props) => {
  const classes = useStyles();
  const { container, uploadContainer } = classes;
  const {
    renderComponent = null,
    uploadId = "uploadCont",
    onChangeHandler = null,
    overrideContainer = "",
    overrideParentContainer = "",
    accept = "image/jpeg, image/png, image/jpg",
    onBlur = null,
    name = "uploader",
    imgUrl = null,
    testID = "fileUploadID",
    sizeMinLimit = 0,
    sizeMaxLimit = 0,
    FileErrorMsg = "File size error",
    isDisabled = false,
    fieldName = "",
    ...rest
  } = props;
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    let objectUrl = "";
    try {
      objectUrl = URL.createObjectURL(selectedFile);
      const isPdf = selectedFile?.type === "application/pdf";
      if (isPdf) {
        setPreview("");
      } else {
        setPreview(objectUrl);
      }
    } catch {
      setPreview(selectedFile);
    }

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);
  useEffect(() => {
    imgUrl && setSelectedFile(imgUrl);
  }, [props.imgUrl]);
  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      onChangeHandler && onChangeHandler(undefined, true);
      return;
    }
    if (validateFile(e.target.files[0])) {
      onChangeHandler && onChangeHandler(e.target.files[0], false);
      setSelectedFile(e.target.files[0]);
    } else {
      toast.error(FileErrorMsg);
      setSelectedFile(undefined);
      onChangeHandler && onChangeHandler(undefined, true);
    }
  };
  const validateFile = (res) => {
    return sizeMaxLimit
      ? res.size <= sizeMaxLimit && res.size >= sizeMinLimit
      : true;
  };
  const initialize = () => {
    // document.body.onfocus = checkIt;
    // onSelectFile();
  };
  return (
    <Flex
      overrideClass={`${classes.parentContainer} ${overrideParentContainer}`}
      testID={testID}
    >
      <label
        htmlFor={isDisabled ? null : uploadId}
        className={`${container} ${overrideContainer}`}
        data-testid="fileupload_label"
      >
        {renderComponent && renderComponent({ selectedFile, preview })}
      </label>
      <input
        type="file"
        id={uploadId}
        accept={accept}
        className={uploadContainer}
        onChange={onSelectFile}
        onBlur={onBlur}
        name={name}
        onClick={initialize}
        data-testid={`${fieldName}_file_input`}
        capture="environment"
        {...rest}
      />
    </Flex>
  );
};

FileUploadComponent.propTypes = {
  overrideParentContainer: PropTypes.string,
  overrideContainer: PropTypes.string,
  accept: PropTypes.string,
  testID: PropTypes.string,
  sizeMinLimit: PropTypes.number,
  sizeMaxLimit: PropTypes.number,
  FileErrorMsg: PropTypes.string,
  renderComponent: PropTypes.func,
  onChangeHandler: PropTypes.func,
  imgUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fieldName: PropTypes.string,
};

export default FileUploadComponent;
