import React, { Suspense, useState } from "react";
import { Flex, Modal, Typography, Toast } from "Component";
import MultiProduct from "./multiProduct";
import { RetailerJordanColors } from "Global/colors";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import JordanButton from "./jordanButton";
import { useTranslation } from "react-i18next";
import {
  removeProduct,
  updateEligibilityDetails,
  updateProduct,
} from "RetailerJordan/Redux/Actions";
import UITranslator from "Hooks/uiTranslator";
import DownpaymentModal from "./DownpaymentModal";
import JordanLoader from "./jordanLoader";

const useStyles = createUseStyles((theme) => ({
  cartCount: {
    width: 26,
    height: 26,
    padding: theme?.padding / 2,
    borderRadius: 26,
    background: RetailerJordanColors.PRIMARY,
    marginLeft: theme.margin * 1.5,
    marginRight: theme.margin * 1.5,
    position: "relative",
  },
  cartHeader: {
    padding: theme.padding * 2,
  },
  cartBody: {
    height: "50vh",
    overflowY: "scroll",
    marginBottom: theme.margin * 2,
    padding: theme.padding * 2,
    paddingTop: 0,
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  cartPara: {
    color: `${RetailerJordanColors.WHITE}!important`,
    fontSize: "14px",
    position: "absolute",
    top: "39%",
    left: "40%",
    transform: "translate(-50%,-50%)",
  },
  cartCard: {
    padding: theme.padding,
    border: `1px solid ${RetailerJordanColors.BORDERCOLOR_10}`,
    borderRadius: "4px",
    marginTop: theme.padding,
    marginBottom: theme.padding / 2,
  },
  totalAmount: {
    color: `${RetailerJordanColors.GREY900}!important`,
    fontSize: "18px",
    fontWeight: 600,
  },
  cartFooter: {
    borderTop: `1px solid ${RetailerJordanColors.BORDERCOLOR}`,
    padding: theme.padding * 2,
    "& button": {
      marginTop: theme.margin * 2,
    },
  },
  directionChange: {
    direction: "rtl",
  },
  overrideModalHeader: {
    paddingRight: theme.padding * 2,
  },
  buttonClass: {
    marginTop: 0,
  },
  footer: {
    paddingTop: theme.padding * 2,
    paddingLeft: 0,
    paddingRight: theme.padding * 3,
  },
  overrideModalParent: {
    width: "50%",
    height: "80%",
    "@media (max-width: 767px)": {
      width: "95%",
    },
  },
  stepsChildContainer: {
    overflow: "hidden",
    "&:hover": {
      overflowX: "auto",
    },
    width: 344,
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    height: "max-content",
    borderRadius: theme?.borderRadius,
    position: "fixed",
    backgroundColor: RetailerJordanColors.WHITE,
  },
}));

const CartStepper = ({ stepChangeHandler, renderFallBack }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lang = UITranslator();
  const dispatch = useDispatch();
  const { toast } = Toast;
  const { items: products = [], total_downpayment_amount = 0 } = useSelector(
    (state) => state?.multi_product_details
  );
  const eligibility_details = useSelector(
    (state) => state?.eligibility_details
  );
  const [showModal, setShowModal] = useState({
    component: null,
    isVisible: false,
    title: "",
    showFooter: false,
  });
  const [productIndex, setProductIndex] = useState(null);
  const [downpaymentValue, setDownpaymentValue] = useState(0);

  const removeProductHandler = (value, item, addTax = false) => {
    const final_downpayment =
      Number(item?.downpayment_amount) + Number(item?.downpayment_amount_tax);

    const total_installment_amount = addTax
      ? item?.item_cost_with_margin -
        (item?.downpayment_amount + item?.downpayment_amount_tax)
      : item?.item_cost_with_margin - item?.downpayment_amount;

    dispatch(
      removeProduct({
        productIndex: value,
        final_downpayment,
      })
    );
    dispatch(
      updateEligibilityDetails({
        available_credit_limit:
          Number(eligibility_details?.available_credit_limit) +
          Number(total_installment_amount),
      })
    );
    toast.success(t("item_removed"));
  };

  const updateSlider = (val) => {
    setDownpaymentValue(val);
  };

  const openDownPaymentModal = (result, index) => {
    setProductIndex(index);
    setDownpaymentValue(result?.downpayment_amount);
    setShowModal({
      component: () => (
        <DownpaymentModal
          itemDetail={result}
          updateDownpaymentAmount={updateSlider}
        />
      ),
      isVisible: true,
      title: "Edit Downpayment",
      showFooter: true,
    });
  };

  const updateDownpaymentHandler = (addTax = false) => {
    const old_product_details = products?.[productIndex];
    const taxAmount =
      old_product_details?.tax16?.toLowerCase() === "taxable" ? 16 : 0;
    const new_downpayment_amount_tax = Number(
      downpaymentValue * (taxAmount / 100)
    ).toFixed(3);
    const old_total_installment_amount = addTax
      ? old_product_details?.item_cost_with_margin -
        (old_product_details?.downpayment_amount +
          old_product_details?.downpayment_amount_tax)
      : old_product_details?.item_cost_with_margin -
        old_product_details?.downpayment_amount;
    const new_total_installment_amount = addTax
      ? old_product_details?.item_cost_with_margin -
        (Number(downpaymentValue) + Number(new_downpayment_amount_tax))
      : old_product_details?.item_cost_with_margin - downpaymentValue;
    const updated_credit =
      eligibility_details?.available_credit_limit +
      old_total_installment_amount;
    if (new_total_installment_amount > updated_credit) {
      toast.error(t("exhaust_limit"));
    } else {
      const payload = {
        productIndex,
        downpaymentValue,
        new_downpayment_amount_tax,
      };
      dispatch(updateProduct(payload));
      dispatch(
        updateEligibilityDetails({
          available_credit_limit:
            Number(eligibility_details?.available_credit_limit) +
            old_total_installment_amount -
            Number(new_total_installment_amount),
        })
      );
      closeModal();
    }
  };

  const closeModal = () => {
    setShowModal({
      component: null,
      isVisible: false,
      title: "",
      showFooter: false,
    });
    setProductIndex(null);
  };

  return (
    <>
      <Flex direction="column">
        <Flex align="center" overrideClass={classes.cartHeader}>
          <Typography type="h3">{t("cart")}</Typography>
          <Flex overrideClass={classes.cartCount}>
            <Typography type="p" overrideClass={classes.cartPara}>
              {products?.length}
            </Typography>
          </Flex>
        </Flex>
        <Flex direction="column" overrideClass={classes.cartBody}>
          {products?.length !== 0 && (
            <MultiProduct
              products={products}
              openDownPaymentModal={openDownPaymentModal}
              removeProductHandler={removeProductHandler}
              isEdit={true}
            />
          )}
        </Flex>
        <Flex overrideClass={classes.cartFooter} direction="column">
          <Typography type="h3" overrideClass={classes.totalAmount}>
            {total_downpayment_amount?.toFixed(3)} JOD
          </Typography>
          <JordanButton
            msg={t("continue")}
            onClickHandler={stepChangeHandler}
          />
        </Flex>
      </Flex>
      <Modal
        open={showModal?.isVisible}
        onClose={closeModal}
        buttonHandler={closeModal}
        titleComponent={() => (
          <Typography type="h3">{showModal?.title}</Typography>
        )}
        showFooter={showModal?.showFooter}
        overrideModalParent={
          lang
            ? `${classes.directionChange} ${classes.overrideModalParent}`
            : classes.overrideModalParent
        }
        overrideModalHeader={lang ? classes.overrideModalHeader : undefined}
        testID="downpayment_modal_id"
        footerComponent={() => (
          <Flex
            align="center"
            overrideClass={`${classes.footer} ${
              lang && classes.directionChange
            }`}
          >
            <JordanButton
              msg={t("save")}
              customClass={classes.buttonClass}
              onClickHandler={() => updateDownpaymentHandler()}
              testID="saveProductBtn"
            />
          </Flex>
        )}
      >
        <Suspense
          fallback={renderFallBack ? renderFallBack() : <JordanLoader />}
        >
          {showModal?.component ? showModal?.component() : null}
        </Suspense>
      </Modal>
    </>
  );
};

export default CartStepper;
