import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import Flex from "./flex";
import Typography from "./Typography";
import Label from "./label";
import Colors from "Global/colors";
import { getPathData } from "Utils/helpers";
const useStyles = createUseStyles((theme) => ({
  radioContainer: {
    // @ts-ignore
    margin: `${theme.margin / 2} ${theme.margin / 2}`,
    cursor: "pointer",
    userSelect: "none",
    border: "none",
    width: "100%",
    // @ts-ignore
    padding: theme.padding / 2,
  },
  radioOuterCircle: {
    width: "14px",
    height: "14px",
    minWidth: "14px",
    minHeight: "14px",
    // @ts-ignore
    border: (props) => `2px solid ${props?.color}`,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // @ts-ignore
    marginRight: theme.margin,
    transition: "all 0.1s linear",
    "&:hover": {
      // @ts-ignore
      boxShadow: `0 0 3px 3px ${theme?.primaryColorLight}`,
    },
  },
  radioInnerCircle: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    // @ts-ignore
    backgroundColor: (props) => props?.color,
    transition: "all 0.1s linear",
  },
  unselected: {
    // @ts-ignore
    border: `2px solid ${Colors.UNSELECTED}`,
  },
  unselectedCircle: {
    width: 0,
    height: 0,
  },
}));
export const Radio = (props) => {
  const {
    value,
    selected,
    onSelect = null,
    label = "",
    radioButtonStyle = {},
    labelStyle = {},
    color = Colors.PRIMARY,
    overrideRadioContClass = "",
    testID = "radioID",
    radioOuterCircleStyle = {},
    overrideInnerCircleClass = "",
    overrideOuterCircleClass = "",
    overrideOuterCircle = "",
    radioInnerCircleStyle = {},
    overrideInnerCircle = "",
    radioLableTestId = "",
    ...rest
  } = props;
  const classes = useStyles({ ...props, color, selected });
  const {
    radioContainer,
    radioOuterCircle,
    radioInnerCircle,
    unselectedCircle,
    unselected,
  } = classes;
  return (
    <Flex
      overrideClass={`${radioContainer} ${overrideRadioContClass}`}
      align="center"
      overrideStyle={{ flex: 1, ...radioButtonStyle }}
      onClick={() => {
        onSelect(value);
      }}
      data-testid={testID}
      {...rest}
    >
      <div
        className={`${radioOuterCircle} ${!selected && unselected} ${
          selected ? overrideOuterCircleClass : overrideOuterCircle
        }`}
        style={{ ...radioOuterCircleStyle }}
      >
        <div
          className={`${radioInnerCircle} ${!selected && unselectedCircle} ${
            selected ? overrideInnerCircleClass : overrideInnerCircle
          }`}
          style={{ ...radioInnerCircleStyle }}
        />
      </div>
      <Typography
        testID={radioLableTestId}
        overrideStyle={{ color: Colors.LABEL_COLOR, ...labelStyle }}
      >
        {label}
      </Typography>
    </Flex>
  );
};
const RadioList = (props) => {
  const {
    options = [],
    handleChange = null,
    error = false,
    errorMsg = "",
    label = "",
    containerStyle = {},
    containerClass = "",
    helpText = null,
    value = null,
    radioButtonStyle = {},
    showLabel = true,
    direction = "row",
    color = Colors.PRIMARY,
    labelStyle = {},
    required = false,
    labelKey = "label",
    valueKey = "value",
    responseType = "object_value",
    helpTextColor = Colors.RED,
    ...rest
  } = props;
  const [selectedValue, setSelectedValue] = useState(value);
  const isObjectForm = responseType === "object_value";
  useEffect(() => {
    setSelectedValue(value);
  }, [props.value]);

  const onChangeHandler = (result = {}) => {
    setSelectedValue(isObjectForm ? result : result?.value);
    handleChange && handleChange(isObjectForm ? result : result?.value);
  };
  return (
    <div style={{ width: "100%" }}>
      <Flex
        direction="column"
        overrideStyle={{ ...containerStyle }}
        overrideClass={`${containerClass}`}
      >
        {showLabel && <Label label={label} required={required} />}
        <Flex justify="center" align="center" direction={direction}>
          {options.map((res, key) => {
            const isSelected =
              getPathData(res, [valueKey], "") ===
              (isObjectForm
                ? getPathData(selectedValue, [valueKey], null)
                : selectedValue);
            return (
              <Radio
                key={key}
                radioButtonStyle={radioButtonStyle}
                onSelect={onChangeHandler}
                label={getPathData(res, [labelKey], "")}
                value={res}
                selected={isSelected}
                color={color}
                labelStyle={labelStyle}
                required={required}
                testID={`radiobtn_${key}`}
                {...rest}
              />
            );
          })}
        </Flex>
        {error && <Typography color={Colors.RED}>{errorMsg}</Typography>}
        {helpText && <Typography color={helpTextColor}>{helpText}</Typography>}
      </Flex>
    </div>
  );
};

Radio.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  showLabel: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  labelStyle: PropTypes.object,
  radioButtonStyle: PropTypes.object,
  overrideRadioContClass: PropTypes.string,
  containerStyle: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  color: PropTypes.string,
  testID: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  radioOuterCircleStyle: PropTypes.object,
  overrideInnerCircleClass: PropTypes.string,
  overrideOuterCircleClass: PropTypes.string,
  overrideOuterCircle: PropTypes.string,
  responseType: PropTypes.oneOf(["single_value", "object_value"]),
  radioInnerCircleStyle: PropTypes.object,
  overrideInnerCircle: PropTypes.string,
};
RadioList.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string || PropTypes.node,
  errorMsg: PropTypes.string,
  helpText: PropTypes.string,
  showLabel: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  labelStyle: PropTypes.object,
  radioButtonStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  direction: PropTypes.oneOf(["row", "column"]),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string]),
  handleChange: PropTypes.func,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  containerClass: PropTypes.string,
  responseType: PropTypes.oneOf(["single_value", "object_value"]),
  helpTextColor: PropTypes.string,
};

export default RadioList;
