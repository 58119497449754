// @ts-nocheck
import React from "react";
import {
  Audio,
  BallTriangle,
  Bars,
  Circles,
  Grid,
  Hearts,
  Oval,
  Puff,
  Rings,
  TailSpin,
  ThreeDots,
} from "react-loader-spinner";
import PropTypes from "prop-types";
import Flex from "./flex";
import { getPathData } from "Utils/helpers";
import Theme from "Global/retailerOrangeTheme";
import Colors from "Global/colors";
import Typography from "./Typography";
const Loader = (props) => {
  const {
    label = "",
    loaderType = "Oval",
    overrideContainerClass = "",
    overrideContainerStyle = {},
    overrideLoaderClass = "",
    overrideLoaderStyle = {},
    loaderHeight = 50,
    loaderWidth = 50,
    strokeWidth = 4,
    strokeWidthSecondary = 4,
    overrideLoaderTextClass = "",
    overrideLoaderTextStyle = {},
    typographyColor = "",
    testID = "loader" + loaderType,
    loaderColor = "",
    ...rest
  } = props;
  const LoaderStyle = {
    Audio: (params) => (
      <Audio color={loaderColor ? loaderColor : Colors.PRIMARY} {...params} />
    ),
    BallTriangle: (params) => (
      <BallTriangle
        color={loaderColor ? loaderColor : Colors.PRIMARY}
        {...params}
      />
    ),
    Bars: (params) => (
      <Bars color={loaderColor ? loaderColor : Colors.PRIMARY} {...params} />
    ),
    Circles: (params) => (
      <Circles color={loaderColor ? loaderColor : Colors.PRIMARY} {...params} />
    ),
    Grid: (params) => (
      <Grid color={loaderColor ? loaderColor : Colors.PRIMARY} {...params} />
    ),
    Hearts: (params) => (
      <Hearts color={loaderColor ? loaderColor : Colors.PRIMARY} {...params} />
    ),
    Oval: (params) => (
      <Oval
        color={loaderColor ? loaderColor : Colors.PRIMARY}
        secondaryColor={Colors.PRIMARY_LIGHT}
        strokeWidth={strokeWidth}
        strokeWidthSecondary={strokeWidthSecondary}
        {...params}
      />
    ),
    Puff: (params) => (
      <Puff color={loaderColor ? loaderColor : Colors.PRIMARY} {...params} />
    ),
    Rings: (params) => (
      <Rings color={loaderColor ? loaderColor : Colors.PRIMARY} {...params} />
    ),
    TailSpin: (params) => (
      <TailSpin
        color={loaderColor ? loaderColor : Colors.PRIMARY}
        {...params}
      />
    ),
    ThreeDots: (params) => (
      <ThreeDots
        color={loaderColor ? loaderColor : Colors.PRIMARY}
        {...params}
      />
    ),
  };
  const LoaderComponent = getPathData(LoaderStyle, [loaderType], null);
  return (
    <Flex
      justify="center"
      align="center"
      overrideClass={overrideContainerClass}
      overrideStyle={{ textAlign: "center", ...overrideContainerStyle }}
      testID={testID}
    >
      <Flex
        direction="column"
        justify="center"
        align="center"
        overrideClass={overrideLoaderClass}
        overrideStyle={overrideLoaderStyle}
      >
        {LoaderComponent &&
          LoaderComponent({
            height: loaderHeight,
            width: loaderWidth,
            ...rest,
          })}
        <Typography
          color={typographyColor}
          overrideStyle={{
            marginTop: Theme.margin,
            ...overrideLoaderTextStyle,
          }}
          overrideClass={overrideLoaderTextClass}
        >
          {label}
        </Typography>
      </Flex>
    </Flex>
  );
};

Loader.propTypes = {
  label: PropTypes.string,
  overrideContainerClass: PropTypes.string,
  overrideLoaderClass: PropTypes.string,
  overrideContainerStyle: PropTypes.object,
  overrideLoaderStyle: PropTypes.object,
  testID: PropTypes.string,
  loaderHeight: PropTypes.number,
  loaderWidth: PropTypes.number,
  strokeWidth: PropTypes.number,
  strokeWidthSecondary: PropTypes.number,
  overrideLoaderTextClass: PropTypes.string,
  typographyColor: PropTypes.string,
  overrideLoaderTextStyle: PropTypes.object,
  loaderColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  loaderType: PropTypes.oneOf([
    "Audio",
    "BallTriangle",
    "Bars",
    "Circles",
    "Grid",
    "Hearts",
    "Oval",
    "Puff",
    "Rings",
    "TailSpin",
    "ThreeDots",
  ]),
};

export default Loader;
