import React from "react";
import { StepBackArrowIcon } from "RetailerJordan/Assets/Icons";
import { Flex } from "Component";

const PreviousNavigator = ({ navigateHandler }) => {
  return (
    <Flex
      onClick={navigateHandler}
      overrideStyle={{ cursor: "pointer" }}
      testID="prev_navigate"
    >
      <StepBackArrowIcon />
    </Flex>
  );
};

export default PreviousNavigator;
