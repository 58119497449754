import React, { useState } from "react";
import PropTypes from "prop-types";
import { animated, useSpring } from "@react-spring/web";
import { Flex, Typography } from "Component";
import { RetailerOrangeColors } from "Global/colors";
import { createUseStyles } from "react-jss";
import Theme from "Global/retailerOrangeTheme";
// import DeviceObserver from "Hooks/deviceDetect";
// import { truncateText } from "Utils/helpers";
import CardUpArrow from "RetailerOrange/Assets/Icons/cardUpArrow";
import CardDownArrow from "RetailerOrange/Assets/Icons/cardDownArrow";

const useStyles = createUseStyles({
  subCardContainer: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: Theme.padding,
    paddingRight: Theme.padding,
    borderRadius: Theme.borderRadius,
    border: `1px solid ${RetailerOrangeColors.GREY200}`,
    position: "relative",
  },
  detailCont: {
    padding: `${Theme.padding}px ${Theme.padding + 4}px`,
    borderBottom: `1px solid ${RetailerOrangeColors.GREY200}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  extraLabel: {
    color: RetailerOrangeColors.BLUE,
    textDecoration: "underline",
    ...Theme.fontSmallBold,
    marginLeft: Theme?.padding,
    cursor: "pointer",
  },
});

const OrangeDetailCard = (props) => {
  const {
    data,
    testID = "detailsCard",
    overrideSubCardContainer = "",
    overrideSubCardStyle = {},
  } = props;
  const classes = useStyles();
  // const { isDesktop } = DeviceObserver();
  const [isOpen, setIsOpen] = useState(true);
  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  const styles = useSpring({
    height: isOpen && data.length > 2 ? 37 * data?.length : 67,
    overflow: "hidden",
  });

  return (
    <Flex direction="column" align="start">
      <Flex
        direction="column"
        overrideClass={`${classes.subCardContainer} ${overrideSubCardContainer}`}
        testID={testID}
        overrideStyle={{ ...overrideSubCardStyle }}
      >
        <animated.div style={{ ...styles, width: "100%" }}>
          {data.map((item, index) => {
            return (
              <Flex
                key={index}
                align="center"
                justify="between"
                direction="row"
                overrideClass={classes.detailCont}
                testID={`cardDetail_${index}`}
              >
                <Typography
                  type="fontSmall"
                  color={RetailerOrangeColors.GREY600}
                >
                  {/* {isDesktop
                    ? item?.display
                    : item?.display && truncateText(item?.display, 10)} */}
                  {item?.display}
                </Typography>
                <Typography
                  type="fontSmallSemiBold"
                  color={RetailerOrangeColors.GREY900}
                >
                  {item?.value}
                  {item?.extraLabel && (
                    <span
                      className={`${classes.extraLabel}`}
                      onClick={item?.onClickEvent}
                      data-testid={`extraLabel_${item.display}`}
                    >
                      {item.extraLabel}
                    </span>
                  )}
                </Typography>
              </Flex>
            );
          })}
        </animated.div>
      </Flex>
      {data.length > 4 && (
        <Flex onClick={toggleHandler}>
          {isOpen ? (
            <CardUpArrow
              overrideStyle={{
                position: "relative",
                float: "right",
                left: "50%",
                cursor: "pointer",
                top: -10,
              }}
            />
          ) : (
            <CardDownArrow
              overrideStyle={{
                position: "relative",
                float: "right",
                left: "50%",
                cursor: "pointer",
                top: -10,
              }}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
};

OrangeDetailCard.proptypes = {
  data: PropTypes.array,
  overrideSubCardContainer: PropTypes.string,
  overrideSubCardStyle: PropTypes.object,
};

export default OrangeDetailCard;
