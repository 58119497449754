import React, { useContext, useState } from "react";
import { Flex, Modal, Typography, Toast } from "Component";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import UITranslator from "Hooks/uiTranslator";
import { fetchLoanBoundaries } from "RetailerJordan/service";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  CUSTOMER_NOT_ELIGIBLE_FOR_DEVICE,
  EXISTINGLOAN_STATUS,
} from "Global/constant";
import useTimoutHook from "RetailerJordan/Hooks/useTimoutHook";
import { RootProvider } from "RetailerJordan/Context";
import { RetailerJordanColors } from "Global/colors";
import { getPathData, truncateText } from "Utils/helpers";
import {
  ErrorCard,
  JordanButton,
  ProductModal,
  JordanLoader,
  DownpaymentModal,
  BackHandler,
} from "./";
import Theme from "Global/retailerJordanTheme";
import { PRODUCT_TYPE, REDIRECT_URL } from "HttpRequest/url";
const useStyles = createUseStyles({
  productCardWrapper: {
    flex: 1,
    maxWidth: "calc(50% - 8px)",
    border: `1px solid ${RetailerJordanColors.BORDERCOLOR}`,
    borderRadius: Theme.padding / 2,
    boxSizing: " border-box",
    flexBasis: "50%",
    "@media (max-width: 767px)": {
      flexBasis: "100%",
      maxWidth: "100%",
      marginRight: Theme.padding,
    },
  },
  imageWrapper: {
    width: "20%",

    backgroundColor: RetailerJordanColors.PLACEHOLDER_GREY,
    marginRight: Theme.padding * 2,
    "& img": {
      width: "100%",
      height: Theme.padding * 10,
      objectFit: "cover",
    },
  },
  imageWrapperRight: {
    marginRight: 0,
    marginLeft: Theme.padding * 2,
  },
  contentWrapper: {
    width: "50%",
  },
  linkWrapper: {
    width: "30%",
    display: "block",
    "& div": {
      textAlign: "center",
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  heading: {
    marginBottom: Theme.padding,
    maxHeight: 50,
    textOverflow: "ellipsis",
    overflow: "hidden",
    lineHeight: "22px",
  },
  overrideModalParent: {
    width: "50%",
    height: "80%",
    "@media (max-width: 767px)": {
      width: "95%",
    },
  },
  buttonClass: {
    marginTop: 0,
    "@media (max-width: 767px)": {
      height: Theme.padding * 8,
    },
  },
  footer: {
    paddingTop: Theme.padding * 2,
    paddingLeft: 0,
    paddingRight: Theme.padding * 3,
  },
  overrideModalHeader: {
    color: RetailerJordanColors.GREY900,
  },
  pointClass: {
    color: `${RetailerJordanColors.GREY500}!important`,
    marginBottom: Theme.padding,
  },
  priceClass: {
    fontSize: Theme.padding * 3,
    color: `${RetailerJordanColors.GREY900}!important`,
    marginBottom: Theme.padding / 2,
  },
  discountClass: {
    color: RetailerJordanColors.TEXTMEDIUMEMPHASIS,
  },
  directionChange: {
    direction: "rtl",
  },
  rightModalHeader: {
    direction: "rtl",
    paddingRight: Theme.margin * 2,
    paddingLeft: 0,
  },
  taxClass: {
    paddingLeft: Theme.margin / 2,
    paddingTop: Theme.margin / 2,
  },
  closeIconStyle: {
    display: "none",
  },
});

const ProductCard = ({
  productDetails,
  onChooseItem,
  pco_id,
  testID = "productCard",
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { nid } = useParams();
  const lang = UITranslator();
  const [timeoutHandler] = useTimoutHook();

  const { items: products = [] } = useSelector(
    (state) => state?.multi_product_details || {}
  );
  const eligibility_details = useSelector(
    (state) => state?.eligibility_details || {}
  );

  const { kyc_data = {} } = useSelector((state) => state?.kyc_details);
  const {
    itemDesc = "",
    catDesc = "",
    subCatDesc = "",
    id = "",
    item_cost_with_margin = 0,
    itemCode = "",
  } = productDetails;

  const [show, setShow] = useState(false);
  const [showDownpaymentModal, setShowDownpaymentModal] = useState(false);
  const [itemDetail, setItemDetail] = useState({
    item_cost_with_margin: "0",
  });
  const [errorData, setErrorData] = useState({
    isError: false,
    heading: "",
    subHeading: "",
  });
  const [isLoading, setIsLoading] = useState({
    loader: true,
    msg: t("fetching_details"),
  });
  const { toast } = Toast;
  const { loader, msg } = isLoading;
  const { state = {} } = useContext(RootProvider);
  const closeModal = () => {
    setItemDetail({
      item_cost_with_margin: "0",
    });
    setShow(false);
  };

  const updateDownpaymentAmount = (amount) => {
    setItemDetail((prev) => ({
      ...prev,
      downpayment_amount: amount,
    }));
  };

  const showModal = () => {
    if (products?.length === 4) {
      toast.error(t("product_max_limit"));
      return;
    } else if (eligibility_details?.available_credit_limit === 0) {
      toast.error(t("exhaust_limit"));
    } else {
      setShow(true);
      setIsLoading({ loader: true, msg: t("fetching_details") });
      setErrorData({
        isError: false,
        heading: "",
        subHeading: "",
      });
      const productItems = [];
      products.length !== 0 &&
        products.map((item) => {
          productItems.push({
            item_code: item?.itemCode,
            downpayment_amount: item?.downpayment_amount,
          });
        });
      fetchLoanBoundaries({
        msisdn: nid,
        shop_id: getPathData(state, ["userProfile", "shop_id"], ""),
        item_code: productDetails?.itemCode,
        contact_number: kyc_data?.contact_number,
        pco_id,
        product_type: PRODUCT_TYPE,
        ...(products.length !== 0 && { cart_items: productItems }),
      })
        .then((res) => {
          const status = getPathData(res, ["data", "code"], null);
          const message = getPathData(res, ["data", "message"], "");
          const boundaryData = getPathData(res, ["data", "data"], {});
          if (status === 2000) {
            setItemDetail({
              ...boundaryData,
              min_downpayment_amount_with_margin:
                boundaryData?.min_downpayment_amount_with_margin,
              max_downpayment_amount_with_margin:
                boundaryData?.max_downpayment_amount_with_margin || 1,
              downpayment_amount:
                boundaryData?.min_downpayment_amount_with_margin,
            });
          } else if (status === EXISTINGLOAN_STATUS) {
            timeoutHandler();
            return;
          } else if (status === CUSTOMER_NOT_ELIGIBLE_FOR_DEVICE) {
            // Min. downpayment is greater than equals to max downpayment
            setErrorData({
              isError: true,
              heading: "",
              subHeading: message,
            });
          } else {
            setErrorData({
              isError: true,
              heading: "something_wrong",
              subHeading: "please_try_again_after_sometime",
            });
          }
          setIsLoading({ loader: false, msg: "" });
        })
        .catch(() => {
          setIsLoading({ loader: false, msg: "" });
          setErrorData({
            isError: true,
            heading: "something_wrong",
            subHeading: "please_try_again_after_sometime",
          });
        });
    }
  };

  const addItemHandler = (itemDetail, addTax = false) => {
    const taxAmount = itemDetail?.tax16?.toLowerCase() === "taxable" ? 16 : 0;
    const downpayment_amount_tax = Number(
      itemDetail?.downpayment_amount * (taxAmount / 100)
    ).toFixed(3);

    const total_installment_amount = addTax
      ? itemDetail?.item_cost_with_margin -
        (itemDetail?.downpayment_amount + Number(downpayment_amount_tax))
      : itemDetail?.item_cost_with_margin - itemDetail?.downpayment_amount;

    if (
      total_installment_amount > eligibility_details?.available_credit_limit
    ) {
      toast.error(t("exhaust_limit"));
    } else {
      onChooseItem(itemDetail);
      closeModal();
      setShowDownpaymentModal(false);
    }
  };

  return (
    <Flex overrideClass={`${classes.productCardWrapper}`} key={id}>
      <Flex
        align="center"
        overrideClass={lang ? classes.directionChange : undefined}
        testID={testID}
      >
        <div
          className={`${classes.imageWrapper} ${
            lang ? classes.imageWrapperRight : undefined
          }`}
          data-testid="mobile_image"
        >
          <img
            src={`${REDIRECT_URL}/device_images/${itemCode}.jpg`}
            loading="lazy"
            onError={(event) => {
              event.target.src = `${
                process.env.PUBLIC_URL + "/assets/grey-image-placeholder.jpg"
              }`;
              event.onerror = null;
            }}
            alt={itemCode}
            data-testid="mobile_image_id"
          />
        </div>
        <div className={classes.contentWrapper} data-testid="mobile_desc">
          <Typography
            type="fontSmallBold"
            color={RetailerJordanColors.GREY900}
            overrideClass={classes.heading}
          >
            {truncateText(itemDesc, 36)}
          </Typography>
          <Typography
            // type="p"
            type="fontXSmallSemiBold"
            color={RetailerJordanColors.GREY500}
          >
            {truncateText(
              `${item_cost_with_margin} JOD | ${catDesc} | ${subCatDesc}`,
              28
            )}
          </Typography>
        </div>
        <div className={classes.linkWrapper} onClick={showModal}>
          <Typography
            type="fontSmallBold"
            color={RetailerJordanColors.PRIMARY}
            testID={`${productDetails.id}_choosebtn`}
          >
            {t("choose")}
          </Typography>
        </div>
      </Flex>
      <Modal
        open={show}
        onClose={closeModal}
        overrideModalHeader={`${classes.overrideModalHeader} ${
          lang && classes.rightModalHeader
        }`}
        testID={`${productDetails.id}_modal_id`}
        buttonHandler={closeModal}
        overrideModalParent={classes.overrideModalParent}
        showFooter={loader || errorData?.isError ? false : true}
        closeIcontTestId="selected_product_modal_close_icon_id"
        overrideCloseStyle={
          showDownpaymentModal ? classes.closeIconStyle : undefined
        }
        titleComponent={() =>
          showDownpaymentModal ? (
            <Flex justify="start" align="center">
              <Flex overrideStyle={{ width: "initial", marginRight: "8px" }}>
                <BackHandler
                  onClick={() => setShowDownpaymentModal(false)}
                  overrideClass={lang ? classes.directionChange : undefined}
                />
              </Flex>
              <Typography type="h3">
                {t("select_downpayment_amount")}
              </Typography>
            </Flex>
          ) : (
            <Typography type="h3">{itemDesc}</Typography>
          )
        }
        footerComponent={() => (
          <Flex
            align="center"
            overrideClass={`${classes.footer} ${
              lang && classes.directionChange
            }`}
          >
            {parseInt(itemDetail?.item_cost_with_margin) === 0 ||
            loader ||
            errorData?.isError ? null : (
              <Flex direction="column">
                <Flex>
                  <Typography type="h3" overrideClass={classes.priceClass}>
                    {itemDetail?.item_cost_with_margin} JOD
                  </Typography>
                  <Typography type="p" overrideClass={classes.taxClass}>
                    {(itemDetail?.tax16 !== "Not Taxable" ||
                      itemDetail?.tax26 !== "Not Taxable") &&
                      "+taxes"}
                  </Typography>
                </Flex>
                {/* <Typography type="p" overrideClass={classes.pointClass}>
                <s>{product?.total}</s>{" "}
                <span className={classes.discountClass}>
                  {product?.discount}%
                </span>
              </Typography> */}
              </Flex>
            )}
            <Flex>
              <JordanButton
                msg={
                  showDownpaymentModal
                    ? t("confirm_cart")
                    : t("continue_to_downpayment")
                }
                customClass={classes.buttonClass}
                isValid={
                  parseInt(itemDetail?.item_cost_with_margin) === 0 ||
                  loader ||
                  errorData?.isError
                }
                onClickHandler={() =>
                  showDownpaymentModal &&
                  onChooseItem &&
                  parseInt(itemDetail?.item_cost_with_margin) > 0
                    ? addItemHandler(itemDetail)
                    : setShowDownpaymentModal(true)
                }
                testID={
                  showDownpaymentModal
                    ? "confirm_cart_btn_id"
                    : "continue_downpayment_btn_id"
                }
              />
            </Flex>
          </Flex>
        )}
      >
        {loader ? (
          <JordanLoader label={msg} testID={`${productDetails?.id}_loader`} />
        ) : errorData?.isError ? (
          <ErrorCard
            heading={t(errorData?.heading)}
            subHeading={t(errorData?.subHeading)}
            showButton={false}
          />
        ) : showDownpaymentModal ? (
          <DownpaymentModal
            itemDetail={itemDetail}
            updateDownpaymentAmount={updateDownpaymentAmount}
          />
        ) : (
          // <ProductModal images={images} product={productDetails} />
          <ProductModal productDetail={productDetails} />
        )}
      </Modal>
    </Flex>
  );
};

export default ProductCard;
