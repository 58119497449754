import React from "react";
import PropTypes from "prop-types";
import { Loader } from "Component";
import { RetailerOrangeColors } from "Global/colors";

const OrangeLoader = (props) => {
  const { loaderMsg = "" } = props;
  return (
    <Loader
      label={loaderMsg}
      loaderColor={RetailerOrangeColors.PRIMARY}
      secondaryColor={RetailerOrangeColors.PRIMARY_LIGHT}
    />
  );
};

OrangeLoader.propTypes = {
  loaderMsg: PropTypes.string,
};

export default OrangeLoader;
