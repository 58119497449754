import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { Radio } from "Component";
import { RetailerOrangeColors } from "Global/colors";
import Theme from "Global/retailerOrangeTheme";

const useStyles = createUseStyles((theme) => ({
  selectedRadio: {
    height: 16,
    width: 16,
    border: `1px solid ${RetailerOrangeColors.BLUE}`,
    borderRadius: theme.borderRadius * 3,
  },
  unselectedRadio: {
    height: 16,
    width: 16,
    border: `1px solid ${RetailerOrangeColors.GREY300}`,
    borderRadius: theme.borderRadius * 3,
    marginRight: theme.margin + 4,
  },
  selectedInnerRadio: {
    height: 7,
    width: 7,
    alignSelf: "center",
    background: RetailerOrangeColors.BLUE,
  },
  unselectedInnerRadio: {
    height: 7,
    width: 7,
    alignSelf: "center",
    background: "none",
  },
}));

const OrangeRadio = (props) => {
  const {
    radioTitle = "",
    value,
    selected,
    handleChange = null,
    option = [],
    testId = "radioTestId",
    ...rest
  } = props;
  const classes = useStyles();
  return (
    <Radio
      options={option}
      label={radioTitle}
      value={value}
      selected={selected}
      radioButtonStyle={{
        padding: Theme.padding + 4,
        backgroundColor: RetailerOrangeColors.WHITE,
        borderRadius: Theme.borderRadius + 2,
        border: selected && `1px solid ${RetailerOrangeColors.PRIMARY}`,
        flexShrink: 0,
        marginBottom: Theme.margin,
      }}
      overrideOuterCircleClass={classes.selectedRadio}
      overrideOuterCircle={classes.unselectedRadio}
      overrideInnerCircleClass={classes.selectedInnerRadio}
      overrideInnerCircle={classes.unselectedInnerRadio}
      onSelect={handleChange}
      testID={testId}
      {...rest}
    />
  );
};

OrangeRadio.propTypes = {
  radioTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selected: PropTypes.bool,
  handleChange: PropTypes.func,
  option: PropTypes.array,
  testId: PropTypes.string,
};

export default OrangeRadio;
