import React, { useState, useEffect, lazy } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { ThemeProvider } from "react-jss";
import RetailerJordan from "Global/retailerJordanTheme";
import { Flex } from "Component";
import { setFavicon } from "Global/helper";
import { fetchMetaData } from "Global/commonRequest";
import JordanLoader from "./Component/jordanLoader";
import NidValidator from "./Hoc/nidValidator";
import RootContext from "./Context/rootContext";
import { useTranslation } from "react-i18next";
import { ErrorCard } from "./Component";
import FAQ from "./Container/faq";
import { PrivateEshopRoute, PrivateReportRoute } from "RetailerJordan/auth";
import { Provider } from "react-redux";
import { store } from "./Redux";
export const ThemeContext = React.createContext(null);
const PrivateRoute = lazy(() => import("RetailerJordan/auth"));
const LandingPage = lazy(() => import("RetailerJordan/Container/landingPage"));
const LoanProcess = lazy(() => import("RetailerJordan/Container/LoanProcess"));
const Reports = lazy(() => import("RetailerJordan/Container/Reports"));
const Eshop = lazy(() => import("RetailerJordan/Container/Eshop"));
// const Eligibility = lazy(() =>
//   import("RetailerJordan/Container/LoanProcess/userEligibility")
// );
const ReportDownload = lazy(() =>
  import("RetailerJordan/Container/Reports/reportDownload")
);
// const Eshop = lazy(() => import("RetailerJordan/Container/Eshop"));
// const Login = lazy(() => import("RetailerJordan/Container/Login"));
// const OAuthWrapper = () => {
//   const [searchParams] = useSearchParams();
//   const { t } = useTranslation();
//   const [isLoading, setIsLoading] = useState({
//     loader: true,
//     msg: t("please_wait"),
//   });
//   const { loader, msg } = isLoading;
//   // const access_token = localStorage.getItem("access_token");
//   // const refresh_token = localStorage.getItem("refresh_token");
//   const access_token = Cookies.get("access_token");
//   const refresh_token = Cookies.get("refresh_token");
//   useEffect(() => {
//     if (searchParams?.get("authToken") && searchParams?.get("refreshToken")) {
//       localStorage.setItem("access_token", searchParams?.get("authToken"));
//       localStorage.setItem("refresh_token", searchParams?.get("refreshToken"));
//       setIsLoading({ loader: false, msg: t("please_wait") });
//     }
//   }, []);
//   if (
//     loader ||
//     !access_token ||
//     access_token === null ||
//     access_token === "null" ||
//     access_token === "" ||
//     !refresh_token ||
//     refresh_token === null ||
//     refresh_token === "null" ||
//     refresh_token === ""
//   )
//     return <JordanLoader label={msg} />;
//   // return <Navigate to="/" />
//   window.location.href = REDIRECT_URL;
// };
export const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Flex justify="center" align="center" overrideStyle={{ height: "90vh" }}>
      <ErrorCard
        heading={t("something_wrong")}
        subHeading={t("please_try_again_after_sometime")}
        buttonText={t("go_home")}
        onPressHandler={() => navigate("/")}
      />
    </Flex>
  );
};
const Wrapper = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <Flex
          overrideStyle={{ height: "100vh" }}
          justify="center"
          align="center"
        >
          <JordanLoader label="Loading..." />
        </Flex>
      }
    >
      {children}
    </React.Suspense>
  );
};
const App = () => {
  const [isLoading, setIsLoading] = useState({
    loader: true,
    msg: "Loading...",
  });
  const { loader, msg } = isLoading;
  useEffect(() => {
    const code = localStorage.getItem("code");
    const logo = localStorage.getItem("logo");
    const name = localStorage.getItem("name");
    const heading = localStorage.getItem("heading");
    const favicon = localStorage.getItem("favIcon");
    if (
      !code ||
      code === null ||
      code === "null" ||
      code === "" ||
      //   PARTNER_CODE !== code ||
      !logo ||
      logo === null ||
      logo === "null" ||
      logo === "" ||
      !name ||
      name === null ||
      name === "null" ||
      name === "" ||
      !favicon ||
      favicon === null ||
      favicon === "null" ||
      favicon === "" ||
      !heading ||
      heading === null ||
      heading === "null" ||
      heading === ""
    ) {
      fetchMetaData({
        setIsLoading,
      });
    } else {
      const favicon = localStorage.getItem("favIcon");
      const heading = localStorage.getItem("heading");
      document.title = heading;
      setFavicon(favicon);
      setIsLoading((prev) => ({ ...prev, loader: false }));
    }
  }, []);
  return (
    // <HomeErrorBoundary>
    <ThemeProvider theme={RetailerJordan}>
      {loader ? (
        <Flex
          direction="row"
          justify="center"
          align="center"
          overrideStyle={{ height: "100vh" }}
        >
          <JordanLoader label={msg} />
        </Flex>
      ) : (
        <Router>
          <Wrapper>
            <RootContext>
              <Provider store={store}>
                <Routes>
                  <Route
                    element={
                      <Wrapper>
                        <PrivateRoute />
                      </Wrapper>
                    }
                  >
                    <Route
                      element={
                        <Wrapper>
                          <LandingPage />
                        </Wrapper>
                      }
                    >
                      <Route
                        path="/"
                        element={
                          <Wrapper>
                            <LoanProcess />
                          </Wrapper>
                        }
                      />

                      <Route
                        path="/customerdetails/:nid"
                        element={
                          <Wrapper>
                            <NidValidator>
                              <LoanProcess parentStep={0} childStep={1} />
                            </NidValidator>
                          </Wrapper>
                        }
                      />
                      <Route
                        path="/customerdetails/loan/:nid"
                        element={
                          <Wrapper>
                            <NidValidator>
                              <LoanProcess parentStep={0} childStep={2} />
                            </NidValidator>
                          </Wrapper>
                        }
                      />
                      <Route
                        element={
                          <Wrapper>
                            <PrivateReportRoute />
                          </Wrapper>
                        }
                      >
                        <Route
                          path="/reports"
                          element={
                            <Wrapper>
                              <Reports />
                            </Wrapper>
                          }
                        />
                        <Route
                          path="/download-reports"
                          element={
                            <Wrapper>
                              <ReportDownload />
                            </Wrapper>
                          }
                        />
                      </Route>
                      <Route
                        element={
                          <Wrapper>
                            <PrivateEshopRoute />
                          </Wrapper>
                        }
                      >
                        <Route
                          path="/eshop"
                          element={
                            <Wrapper>
                              <Eshop />
                            </Wrapper>
                          }
                        />
                      </Route>

                      <Route
                        path="/explore"
                        element={
                          <Wrapper>
                            <div>Explore</div>
                          </Wrapper>
                        }
                      />
                    </Route>
                  </Route>
                  <Route
                    path="/faq"
                    element={
                      <Wrapper>
                        <FAQ />
                      </Wrapper>
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <Wrapper>
                        <NotFound />
                      </Wrapper>
                    }
                  />
                  {/* <Route
                  index
                  path="/login"
                  element={
                    <Wrapper>
                      <Login />
                    </Wrapper>
                  }
                /> */}
                  {/* <Route
                  index
                  path="/login/hgh"
                  element={
                    <Wrapper>
                      <OAuthWrapper />
                    </Wrapper>
                  }
                />{" "}
                 */}
                </Routes>
              </Provider>
            </RootContext>
          </Wrapper>
        </Router>
      )}
    </ThemeProvider>
    // </HomeErrorBoundary>
  );
};

export default App;
