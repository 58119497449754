import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { useSpring, animated } from "@react-spring/web";
import { Typography, Flex } from "Component";
import { getPathData } from "Utils/helpers";
import { RetailerOrangeColors } from "Global/colors";
import Theme from "Global/retailerOrangeTheme";
import { useTranslation } from "react-i18next";
import { fetchMetaData } from "Global/commonRequest";
import DeviceObserver from "Hooks/deviceDetect";
import { LocationIcon } from "RetailerOrange/Assets";
import { EditIcon } from "RetailerOrange/Assets/Icons";
import OrangePopOver from "./orangePopOver";

export const ThemeContext = React.createContext(null);
const useStyles = createUseStyles((theme) => ({
  headerCont: {
    boxSizing: "border-box",
    padding: `${theme.padding}px ${theme.padding * 2}px `,
    backgroundColor: RetailerOrangeColors.WHITE,
    borderBottom: `1px solid ${RetailerOrangeColors.BORDERCOLOR}`,
  },
  imgLogo: {
    height: 45,
    width: 170,
    cursor: "pointer",
  },
  navlinkCont: {
    marginLeft: theme.margin * 2,
    alignSelf: "center",
  },
  navlinkMobileCont: {
    padding: theme.padding * 2,
  },
  navlink: {
    textDecoration: "none",
    color: RetailerOrangeColors.BLACK,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  navlinkMobile: {
    textDecoration: "none",
    color: RetailerOrangeColors.BLACK,
    "&:hover": {
      textDecoration: "underline",
      color: RetailerOrangeColors.BLACK,
    },
  },
  container: { minHeight: "100vh" },
  languageContainer: {
    width: "max-content",
    justifyContent: "flex-end",
    border: `1px solid ${RetailerOrangeColors.BORDERCOLOR}`,
    borderRadius: "4px",
    padding: Theme.padding + 2,
  },
  dropDownCont: {
    border: "none",
    backgroundColor: "rgba(0,0,0,0)",
    color: RetailerOrangeColors.BLACK,
    cursor: "pointer",
    fontFamily: "Poppins-Regular",
    fontSize: 16,
    "&:focus": {
      border: "none",
    },
    "&:active": {
      border: "none",
    },
    "&>*": {
      borderRadius: theme.borderRadius,
    },
  },
  sidebarContainer: {
    boxSizing: "border-box",
    height: "100vh",
    overflow: "auto",
    paddingBottom: 20,
    backgroundColor: RetailerOrangeColors.WHITE,
    width: 280,
    position: "absolute",
    right: 0,
    zIndex: 2,
    top: 0,
    overflowX: "hidden",
    transition: "0.5s",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 10,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  toggleIcon: {
    fontSize: 25,
    "&:active": {
      transform: "matrix(0.95, 0, 0, 0.95, 0, 0)",
    },
  },
}));
const LeftSideBar = (props) => {
  const { isSidebarVisible, toggleSidebar } = props;
  const classes = useStyles({ ...props, isSidebarVisible });
  const styles = useSpring({
    width: isSidebarVisible ? 220 : 0,
  });
  return (
    <animated.div style={{ ...styles }}>
      <Flex
        overrideClass={classes.overlay}
        onClick={toggleSidebar}
        data-testid="overlay_id"
      >
        <Flex
          direction="column"
          justify="start"
          overrideClass={classes.sidebarContainer}
          data-testid="leftsidemenu"
          onClick={toggleSidebar}
        >
          {isSidebarVisible && props.children}
          {/* <Flex
            justify="center"
            align="center"
            overrideClass={`${classes.toggleIconCont}`}
            onClick={toggleSidebar}
          >
            {isSidebarVisible ? (
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            ) : (
              "<<<"
            )}
          </Flex> */}
        </Flex>
      </Flex>
    </animated.div>
  );
};

const Header = (props) => {
  const { onEditHandler = null } = props;
  const classes = useStyles({ ...props });
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { isDesktop } = DeviceObserver();
  const retailerShopDetail = JSON.parse(
    localStorage.getItem("retailer_shop_details")
  );
  const retailerShopId = retailerShopDetail?.id;
  const retailerShopName =
    retailerShopDetail?.name?.toLowerCase() === "other"
      ? retailerShopDetail?.custom_name
      : retailerShopDetail?.name;

  const onChangeLanguageHandler = (val) => {
    i18n.changeLanguage(val);
    localStorage.setItem("language", val);
  };
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const logoutHandler = ({ params }) => {
    const toggleLoader = getPathData(params, ["setIsLoading"], null);
    if (toggleLoader) {
      toggleLoader({ loader: true, msg: t("login_out") });
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("permissions");
      localStorage.removeItem("email");
      localStorage.removeItem("user_id");
      localStorage.setItem("language", "fr");
      localStorage.removeItem("retailer_shop_details");
      i18n.changeLanguage("fr");
      setTimeout(() => {
        // window.location.replace("http://orangestaging-ci.yabx.co");
        fetchMetaData({ setIsLoading: null });
        navigate("/login", { replace: true });
      }, 200);
    }
  };
  const leftNavOptions = [
    {
      path: "",
      label: `${t("home")}`,
      onClick: null,
      component: null,
    },
    {
      path: "/tnc",
      label: `${t("t_and_c")}`,
      onClick: null,
      component: null,
    },
  ];
  const rightNavOptions = [
    {
      path: "",
      label: "",
      onClick: null,
      component: () => {
        if (
          !retailerShopName ||
          retailerShopName === null ||
          retailerShopName === "null" ||
          retailerShopName === "" ||
          retailerShopName === '""'
        ) {
          return null;
        } else {
          return (
            <Flex
              overrideClass={classes.languageContainer}
              justify="center"
              align="center"
              overrideStyle={{
                marginLeft: isSidebarVisible ? Theme.margin * 2 : 0,
                marginRight: !isSidebarVisible ? Theme.margin * 2 : 0,
                marginTop: isSidebarVisible ? Theme.margin * 2 : 0,
              }}
            >
              <LocationIcon
                style={{
                  width: 20,
                  marginRight: Theme.margin,
                  cursor: "pointer",
                }}
                data-testid="shop_location_icon"
              />
              <Typography
                type="fontSmallSemiBold"
                color={RetailerOrangeColors.FORM_TEXT_COLOR}
                overrideStyle={{ fontWeight: 600 }}
                testID="retailer_shop_name"
              >
                {retailerShopName}
              </Typography>
              <EditIcon
                style={{
                  width: 15,
                  marginRight: Theme.margin,
                  marginLeft: Theme.margin,
                  cursor: "pointer",
                }}
                data-testid="edit_location_icon"
                onClick={onEditHandler}
              />
            </Flex>
          );
        }
      },
    },
    {
      path: "",
      label: "",
      onClick: null,
      component: () => (
        <Flex
          justify="end"
          align="center"
          overrideStyle={{
            marginLeft: isSidebarVisible ? Theme.margin * 2 : 0,
            marginRight: !isSidebarVisible ? Theme.margin * 2 : 0,
            marginTop: isSidebarVisible ? Theme.margin * 2 : 0,
            width: "100px",
          }}
        >
          <OrangePopOver
            options={[
              {
                label: "English",
                value: "en",
              },
              {
                label: "French",
                value: "fr",
              },
            ]}
            value={i18n?.language || "fr"}
            handleChange={(res) => {
              onChangeLanguageHandler(getPathData(res, ["value"], ""));
            }}
          />
        </Flex>
      ),
    },
    {
      path: "",
      label: "",
      onClick: logoutHandler,
      component: () => (
        <Flex
          overrideStyle={{
            width: "max-content",
            justifyContent: "flex-end",
            marginLeft: isSidebarVisible ? Theme.margin * 2 : 0,
            marginRight: !isSidebarVisible ? Theme.margin * 2 : 0,
            marginTop: isSidebarVisible ? Theme.margin * 2 : 0,
            cursor: "pointer",
          }}
        >
          <Typography
            type="fontSmallSemiBold"
            onClick={() => logoutHandler({ params: props })}
            testID="logout_link"
          >
            {t("log_out")}
          </Typography>
        </Flex>
      ),
    },
  ];
  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };
  return (
    <>
      <Flex
        overrideClass={`${classes.headerCont}`}
        justify={isDesktop ? "start" : "between"}
      >
        <img
          src={localStorage.getItem("logo")}
          className={classes.imgLogo}
          data-testid="logo"
          alt="company_logo"
          onClick={() => navigate("/")}
        />
        {isDesktop ? (
          <Flex align="center" justify="between">
            {retailerShopId && (
              <Flex>
                {leftNavOptions.map((res, key) => {
                  const {
                    label = "",
                    overridelabelClass = "",
                    overrideLabelContainerClass = "",
                    path = "",
                    onClick = null,
                    component = null,
                  } = res;
                  return component ? (
                    <React.Fragment key={key}>{component(res)}</React.Fragment>
                  ) : (
                    <div
                      onClick={onClick}
                      className={`${classes.navlinkCont} ${overrideLabelContainerClass}`}
                      key={key}
                    >
                      <NavLink to={path} className={classes.navlink}>
                        <Typography
                          type="link"
                          overrideClass={overridelabelClass}
                        >
                          {label}
                        </Typography>
                      </NavLink>
                    </div>
                  );
                })}
              </Flex>
            )}
            {rightNavOptions.length > 0 && (
              <Flex justify="end" align="center">
                {rightNavOptions.map((res, key) => {
                  const {
                    label = "",
                    overridelabelClass = "",
                    overrideLabelContainerClass = "",
                    path = "",
                    onClick = null,
                    component = null,
                  } = res;
                  return component ? (
                    <React.Fragment key={key}>{component(res)}</React.Fragment>
                  ) : (
                    <div
                      // onClick={(e) => onClick({ event: e, params: props })}
                      onClick={onClick}
                      className={`${classes.navlinkCont} ${overrideLabelContainerClass}`}
                      key={key}
                    >
                      <NavLink to={path} className={classes.navlink}>
                        <Typography
                          type="link"
                          overrideStyle={{ width: "max-content" }}
                          overrideClass={overridelabelClass}
                        >
                          {label}
                        </Typography>
                      </NavLink>
                    </div>
                  );
                })}
              </Flex>
            )}
          </Flex>
        ) : (
          <Flex
            onClick={toggleSidebar}
            justify="end"
            align="center"
            overrideStyle={{ cursor: "pointer", flex: 1 }}
          >
            {isSidebarVisible ? null : (
              <i
                className={`fa fa-bars ${classes.toggleIcon}`}
                data-testid="barsIcon_id"
              />
            )}
          </Flex>
        )}
      </Flex>
      {!isDesktop && isSidebarVisible && (
        <LeftSideBar
          isSidebarVisible={isSidebarVisible}
          toggleSidebar={toggleSidebar}
        >
          <Flex direction="column">
            {retailerShopId && (
              <Flex direction="column">
                {leftNavOptions.map((res, key) => {
                  const {
                    label = "",
                    overridelabelClass = "",
                    overrideLabelContainerClass = "",
                    path = "",
                    onClick = null,
                    component = null,
                  } = res;
                  return component ? (
                    <React.Fragment key={key}>{component(res)}</React.Fragment>
                  ) : (
                    <div
                      onClick={onClick}
                      className={`${classes.navlinkMobileCont} ${overrideLabelContainerClass}`}
                      key={key}
                    >
                      <NavLink to={path} className={classes.navlinkMobile}>
                        <Typography
                          type="link"
                          overrideClass={overridelabelClass}
                          testID={`leftNavOption_${key}`}
                        >
                          {label}
                        </Typography>
                      </NavLink>
                    </div>
                  );
                })}
              </Flex>
            )}
            {rightNavOptions.length > 0 && (
              <Flex direction="column-reverse">
                {rightNavOptions.map((res, key) => {
                  const {
                    label = "",
                    overridelabelClass = "",
                    overrideLabelContainerClass = "",
                    path = "",
                    onClick = null,
                    component = null,
                  } = res;
                  return component ? (
                    <React.Fragment key={key}>{component(res)}</React.Fragment>
                  ) : (
                    <div
                      // onClick={(e) => onClick({ event: e, params: props })}
                      onClick={onClick}
                      className={`${classes.navlinkMobileCont} ${overrideLabelContainerClass}`}
                      key={key}
                    >
                      <NavLink to={path} className={classes.navlinkMobile}>
                        <Typography
                          type="link"
                          overrideClass={overridelabelClass}
                        >
                          {label}
                        </Typography>
                      </NavLink>
                    </div>
                  );
                })}
              </Flex>
            )}
          </Flex>
        </LeftSideBar>
      )}
    </>
  );
};

export default Header;
