/**
 * @jest-environment jsdom
 */
import React, { useReducer } from "react";
import { Toast, Flex } from "Component";
import { getPathData } from "Utils/helpers";
import { InitialState } from "./initialState";
import { ModalActions, LoaderActions } from "./actions";
import { OrangeLoader, OrangeModal } from "RetailerOrange/Components";
export const RootProvider = React.createContext(null);
const reducer = (state, action) => {
  switch (action.type) {
    case ModalActions.TOGGLE_MODAL:
      return {
        ...state,
        modalState: { ...action.payload },
      };
    case LoaderActions.TOGGLE_LOADER:
      return {
        ...state,
        loaderState: { ...action.payload },
      };
    /* istanbul ignore next */
    default:
      return state;
  }
};

const { SnackBar } = Toast;
const RootContext = (props) => {
  const [state, dispatch] = useReducer(reducer, InitialState);
  const closeModalHandler = () => {
    /* istanbul ignore if */
    if (onCloseCallback) onCloseCallback();
    document.body.classList.remove("hide_scroll");
    // @ts-ignore
    dispatch({
      type: ModalActions.TOGGLE_MODAL,
      payload: { isVisible: false, component: null },
    });
  };
  const openModalHanlder = (payload) => {
    document.body.classList.add("hide_scroll");
    // @ts-ignore
    dispatch({
      type: ModalActions.TOGGLE_MODAL,
      payload: { isVisible: true, ...payload },
    });
  };
  const toggleLoader = (payload) => {
    const isVisible = getPathData(payload, ["isVisible"], false);
    if (isVisible) document.body.classList.add("hide_scroll");
    else document.body.classList.remove("hide_scroll");
    // @ts-ignore
    dispatch({
      type: LoaderActions.TOGGLE_LOADER,
      payload,
    });
  };
  const value = {
    state,
    openModal: openModalHanlder,
    closeModal: closeModalHandler,
    toggleLoader,
  };
  const modalComponent = getPathData(state, ["modalState", "component"], null);
  const onCloseCallback = getPathData(
    state,
    ["modalState", "onCloseCallback"],
    null
  );
  const showLoader = getPathData(state, ["loaderState", "isVisible"], false);
  return (
    <RootProvider.Provider value={{ ...value }}>
      {props.children}
      {showLoader && (
        <Flex
          justify="center"
          align="center"
          overrideStyle={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.2)",
            height: "100vh",
            width: "100%",
            top: 0,
            zIndex: 2222,
          }}
        >
          <OrangeLoader />
        </Flex>
      )}
      <SnackBar
        position={"top-right"}
        autoClose={2000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme="dark"
      />
      <OrangeModal
        modalSize="medium"
        modalHeaderTitleType="fontSmallBold"
        modalOpen={getPathData(state, ["modalState", "isVisible"], false)}
        onCloseHandler={closeModalHandler}
        modalTitle={getPathData(state, ["modalState", "title"], "")}
        buttonHandler={closeModalHandler}
        {...getPathData(state, ["modalState", "extraModalProps"], {})}
        modalChildren={modalComponent && modalComponent()}
      />
    </RootProvider.Provider>
  );
};

export default RootContext;
