const DEVICE_PARTNER = "Samsung Knox";
const PRODUCT_NAME_TYPE = "orange_ci";
const STARTING_HANDSET_ACTIVATION = "starting_handset_activation";
const HANDSET_ACTIVATED = "handset_activated";
const STARTING_RETAILER_CONFIRMATION = "starting_retailer_confirmation";
const STARTING_DOWNPAYMENT = "starting_dowpayment";
const CUSTOMER_CONFIRMED = "customer_confirmed";
const EVENTS_SCHEDULED = "events_scheduled";
const MANDATE_CONFIRMED = "mandate_confirmed";
const MANDATE_INITIALISED = "mandate_initialised";
const PARTNER_LOGO = localStorage.getItem("logo");
const SECRET_PASS = process.env.REACT_APP_SECRET_PASS;
const EXISTINGLOAN_STATUS = 7035;
const CUSTOMER_NOT_ELIGIBLE_FOR_DEVICE = 7010;
const LANGUAGE_PATTERNS = {
  en: /^[a-zA-Z\s.,!?0-9]*$/,
  ar: /[\u0600-\u06FF]/,
};
const ENGLISH = "en";
const ARABIC = "ar";
// export const JordanKycKeys = {
//   "61d5be734849191e6600806f": "first_name",
//   "61d5d91e4849191e66008073": "last_name",
//   "61d5dab54849191e66008076": "gender",
//   "61d5dba94849191e66008079": "8888888882",
//   "65465d04006b7f16e8c7089c": "third_name",
//   "65465d04006b7f16e8c70889": "second_name",
//   "65465d04006b7f16e8c70895": "9571001142",
// };
const Jordan_Screen_Steps = {
  Eshop_Step: "Eshop flow",
  Step_2: "Confirm Customer Details Step",
  Step_3: "Enter Bureau Data Step",
  Step_4: "OTP Verification Step",
  Step_5: "Choose Product Step",
  Step_6: "Choose Installment Duration Step",
  Step_7: "Device ID Step",
  Step_8: "Confirm Downpayment Step",
};
export {
  DEVICE_PARTNER,
  HANDSET_ACTIVATED,
  STARTING_HANDSET_ACTIVATION,
  STARTING_RETAILER_CONFIRMATION,
  STARTING_DOWNPAYMENT,
  CUSTOMER_CONFIRMED,
  EVENTS_SCHEDULED,
  PARTNER_LOGO,
  SECRET_PASS,
  EXISTINGLOAN_STATUS,
  CUSTOMER_NOT_ELIGIBLE_FOR_DEVICE,
  LANGUAGE_PATTERNS,
  ENGLISH,
  ARABIC,
  MANDATE_INITIALISED,
  MANDATE_CONFIRMED,
  PRODUCT_NAME_TYPE,
  Jordan_Screen_Steps,
};
