import React from "react";
const EshopIcon = (props) => {
  const { color = "#29242D" } = props;
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.61537 5.6665H25.3846C25.668 5.6665 25.9054 5.76237 26.0971 5.95411C26.2888 6.14586 26.3846 6.38346 26.3846 6.66691C26.3846 6.95037 26.2888 7.18783 26.0971 7.37927C25.9054 7.57072 25.668 7.66644 25.3846 7.66644H6.61537C6.33205 7.66644 6.09457 7.57057 5.9029 7.37884C5.71123 7.18708 5.6154 6.94948 5.6154 6.66604C5.6154 6.38257 5.71123 6.14512 5.9029 5.95367C6.09457 5.76223 6.33205 5.6665 6.61537 5.6665ZM6.8718 26.3331C6.53035 26.3331 6.24414 26.2176 6.01316 25.9866C5.78219 25.7557 5.6667 25.4695 5.6667 25.128V18.3331H5.25386C4.87438 18.3331 4.56199 18.1848 4.3167 17.8882C4.07139 17.5917 3.99317 17.2536 4.08206 16.8741L5.4154 10.6178C5.47615 10.3403 5.6179 10.1124 5.84063 9.93404C6.06339 9.75568 6.31651 9.66651 6.6 9.66651H25.4C25.6835 9.66651 25.9366 9.75568 26.1594 9.93404C26.3821 10.1124 26.5239 10.3403 26.5846 10.6178L27.9179 16.8741C28.0068 17.2536 27.9286 17.5917 27.6833 17.8882C27.438 18.1848 27.1256 18.3331 26.7461 18.3331H26.3333V25.3331C26.3333 25.6165 26.2374 25.854 26.0457 26.0456C25.854 26.2373 25.6164 26.3331 25.3329 26.3331C25.0494 26.3331 24.812 26.2373 24.6205 26.0456C24.4291 25.854 24.3334 25.6165 24.3334 25.3331V18.3331H18.3333V25.128C18.3333 25.4695 18.2178 25.7557 17.9868 25.9866C17.7559 26.2176 17.4697 26.3331 17.1282 26.3331H6.8718ZM7.66663 24.3332H16.3334V18.3331H7.66663V24.3332Z"
        fill={color}
        {...props}
      />
    </svg>
  );
};

export default EshopIcon;
