import React, { Suspense, lazy } from "react";
import { useParams } from "react-router-dom";
import { OrangeLoader } from "RetailerOrange/Components";
import PartnerConfigData from "RetailerOrange/partnerConfigData";
const NotFound = lazy(() => import("Component/notFound"));
const mobileRegex = /^\d+$/;
const ValidateParamsWrapper = (props) => {
  const { mobNumber } = useParams();
  const isValid =
    mobNumber !== "" &&
    mobNumber.length === PartnerConfigData()?.partnerData?.msisdnMaxLength &&
    mobNumber.match(mobileRegex);
  if (isValid) return props.children;
  else
    return (
      <Suspense
        fallback={
          // <Loader
          //   label="Loading..."
          //   loaderColor={RetailerOrangeColors.PRIMARY}
          //   secondaryColor={RetailerOrangeColors.PRIMARY_LIGHT}
          // />
          <OrangeLoader loaderMsg="Loading..." />
        }
      >
        <NotFound />
      </Suspense>
    );
};

export default ValidateParamsWrapper;
