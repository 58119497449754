const ones = [
  "",
  "One",
  "Two",
  "Three",
  "Four",
  "Five",
  "Six",
  "Seven",
  "Eight",
  "Nine",
  "Ten",
  "Eleven",
  "Twelve",
  "Thirteen",
  "Fourteen",
  "Fifteen",
  "Sixteen",
  "Seventeen",
  "Eighteen",
  "Nineteen",
];

const tens = [
  "",
  "",
  "Twenty",
  "Thirty",
  "Forty",
  "Fifty",
  "Sixty",
  "Seventy",
  "Eighty",
  "Ninety",
];

const scales = ["", "Thousand", "Million", "Billion"];

const convertToWords = (num) => {
  if (num === "0") return "Zero";
  let n = parseInt(num, 10);
  if (isNaN(n)) return "Please enter a valid number";

  return numberToWords(n).trim();
};

const numberToWords = (num) => {
  if (num === 0) return "Zero";

  let words = "";
  let chunkCount = 0;

  while (num > 0) {
    let chunk = num % 1000;
    if (chunk !== 0) {
      let chunkWords = processChunk(chunk);
      words = chunkWords + " " + scales[chunkCount] + " " + words;
    }
    num = Math.floor(num / 1000);
    chunkCount++;
  }

  return words.trim();
};
const processChunk = (num) => {
  let result = "";

  if (num >= 100) {
    result += ones[Math.floor(num / 100)] + " Hundred ";
    num %= 100;
  }

  if (num >= 20) {
    result += tens[Math.floor(num / 10)] + " ";
    num %= 10;
  }

  if (num > 0) {
    result += ones[num] + " ";
  }

  return result.trim();
};

const UpdateWords = (num) => {
  return convertToWords(num);
};
export default UpdateWords;
