import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";
import UITranslator from "Hooks/uiTranslator";
import { Flex, Typography } from "Component";
import Theme from "Global/retailerJordanTheme";
import { RetailerJordanColors } from "Global/colors";
import { formatValue } from "Utils/helpers";
import { CardDownArrow, CardUpArrow } from "RetailerJordan/Assets/Icons";
import { animated, useSpring } from "@react-spring/web";
import JordanSlider from "./jordanSlider";

const useStyles = createUseStyles({
  cardOne: {
    borderRadius: Theme.borderRadius,
    marginTop: Theme.margin,
  },
  minTax: {
    fontSize: "10px",
  },
  directionChange: {
    direction: "rtl",
  },
  cardTwo: {
    borderRadius: Theme.borderRadius,
    marginTop: Theme.margin * 4,
    position: "relative",
  },
  detailCont: {
    padding: `${Theme.padding}px ${Theme.padding + 4}px`,
    border: `1px solid ${RetailerJordanColors.BORDERCOLOR}`,
    borderBottom: "none",
    background: RetailerJordanColors.GREY25,
    "&:last-child": {
      borderBottom: `1px solid ${RetailerJordanColors.BORDERCOLOR}`,
    },
  },
  detailCont1: {
    padding: `${Theme.padding}px ${Theme.padding + 4}px`,
  },
  sliderClass: {
    padding: Theme.margin / 2,
  },
});

const DownpaymentModal = ({ itemDetail, updateDownpaymentAmount }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lang = UITranslator();
  const [downpaymentBoundaries, setDownpaymentBoundaries] = useState({
    min_downpayment_amount_with_margin:
      itemDetail?.min_downpayment_amount_with_margin,
    max_downpayment_amount_with_margin:
      itemDetail?.max_downpayment_amount_with_margin,
    downpayment_amount: itemDetail?.downpayment_amount,
  });
  const [isOpen, setIsOpen] = useState(false);

  const taxAmount = itemDetail?.tax16?.toLowerCase() === "taxable" ? 16 : 0;
  const finalDownpaymentAmount = (
    downpaymentBoundaries.downpayment_amount +
    downpaymentBoundaries.downpayment_amount * (taxAmount / 100)
  )?.toFixed(3);

  const onSlideHandler = (value) => {
    if (
      value < Number(downpaymentBoundaries?.min_downpayment_amount_with_margin)
    ) {
      return;
    }
    setDownpaymentBoundaries((prev) => ({
      ...prev,
      downpayment_amount: value?.[0],
    }));
    updateDownpaymentAmount(value?.[0]);
  };

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  const downPaymentData = [
    {
      display: t("final_downpayment"),
      value: `${formatValue(finalDownpaymentAmount)} JOD`,
    },
    {
      display: t("total_amount_without_tax"),
      value: ` ${formatValue(
        Number(itemDetail?.item_cost_with_margin) -
          Number(downpaymentBoundaries?.downpayment_amount)
      )} JOD`,
    },
    {
      display: t("downpayment_tax"),
      value: `${taxAmount} %`,
    },
    {
      display: t("downpayment_tax_value"),
      value: ` ${
        downpaymentBoundaries?.downpayment_amount > 0
          ? formatValue(
              downpaymentBoundaries?.downpayment_amount * (taxAmount / 100)
            )
          : 0
      }  JOD`,
    },
    {
      display: t("downpayment"),
      value: ` ${formatValue(downpaymentBoundaries?.downpayment_amount)} JOD`,
    },
  ];

  const styles = useSpring({
    height: isOpen ? 35 * downPaymentData?.length + 2 : 106,
    overflow: "hidden",
  });

  return (
    <Flex direction="column">
      <Flex
        justify="center"
        direction="column"
        align="center"
        overrideClass={classes.cardOne}
        testID="selectDownpaymentCard"
      >
        <Flex
          justify="between"
          overrideStyle={{ paddingBottom: Theme.padding }}
        >
          <Typography
            type="fontSmall"
            color={RetailerJordanColors.TEXTMEDIUMEMPHASIS}
          >
            {t("downpayment_amount_jod")}
          </Typography>
          <Typography
            type="fontSmall"
            color={RetailerJordanColors.GREY900}
            overrideStyle={{
              padding: `${Theme.padding - 4}px ${Theme.padding}px`,
              borderBottom: `1px solid ${RetailerJordanColors.GREY50}`,
            }}
            testID="downpaymentTestId"
          >
            {downpaymentBoundaries?.downpayment_amount}{" "}
            {`+ ${formatValue(
              finalDownpaymentAmount - downpaymentBoundaries?.downpayment_amount
            )} `}
            <span className={classes.minTax}>({t("tax")})</span>
          </Typography>
        </Flex>
        <Flex overrideClass={classes.sliderClass}>
          <JordanSlider
            minVal={0}
            maxVal={
              downpaymentBoundaries?.downpayment_amount !== undefined &&
              downpaymentBoundaries?.downpayment_amount !== null
                ? downpaymentBoundaries?.max_downpayment_amount_with_margin
                : 1
            }
            value={[downpaymentBoundaries?.downpayment_amount]}
            onSlide={onSlideHandler}
            overrideTrackStyle={{ paddingLeft: Theme.padding }}
            testID="downpaymentRange"
          />
        </Flex>
        <Flex
          justify="between"
          overrideStyle={{ paddingTop: Theme.padding }}
          overrideClass={lang ? classes.directionChange : undefined}
        >
          <Typography type="fontXSmall" color={RetailerJordanColors.GREY600}>
            0
          </Typography>
          <Typography type="fontXSmall" color={RetailerJordanColors.GREY600}>
            {downpaymentBoundaries?.max_downpayment_amount_with_margin}
          </Typography>
        </Flex>
      </Flex>
      <Flex
        direction="column"
        align="center"
        overrideClass={classes.cardTwo}
        testID="selectDownpaymentDetail"
      >
        <animated.div style={{ ...styles, width: "100%" }}>
          {downPaymentData?.map((item, index) => {
            return (
              <Flex
                align="start"
                overrideClass={`${
                  item?.length === index + 1
                    ? classes.detailCont1
                    : classes.detailCont
                }`}
                key={index}
              >
                <Typography
                  type="fontSmall"
                  color={RetailerJordanColors.Grey600}
                  overrideStyle={{ width: "50%" }}
                >
                  {item?.display}
                </Typography>
                <Typography
                  type="fontSmallBold"
                  color={RetailerJordanColors.GREY900}
                >
                  {item?.value}
                </Typography>
              </Flex>
            );
          })}
        </animated.div>
        {downPaymentData?.length > 2 && (
          <Flex onClick={toggleHandler}>
            {isOpen ? (
              <CardUpArrow
                overrideStyle={{
                  position: "relative",
                  float: "right",
                  left: "50%",
                  cursor: "pointer",
                  top: -18,
                }}
              />
            ) : (
              <CardDownArrow
                overrideStyle={{
                  position: "relative",
                  float: "right",
                  left: "50%",
                  cursor: "pointer",
                  top: -10,
                }}
              />
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default DownpaymentModal;
