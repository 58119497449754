import axios from "axios";
// import { Toast } from "Component";
import { getPathData } from "Utils/helpers";
import {
  GET_REFRESH_TOKEN,
  PARTNER_CODE,
  PACKAGEID,
  REDIRECT_URL,
} from "./url";
import {
  UNAUTHORIZED_401,
  UNAUTHORIZED_440,
  UNAUTHORIZED_403,
} from "Global/statusCodes";
import { DecryptData, EncryptData, clearCookies } from "Global/helper";
import { APP_TYPE } from "./url";
import Cookies from "js-cookie";
// const { toast } = Toast;
const refreshToken =
  APP_TYPE === "jordan"
    ? Cookies.get("refresh_token")
    : DecryptData(localStorage.getItem("refresh_token"));
const Methods = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PUT: "put",
};
const StatusCode = {
  4063: "Imei updation failed",
  4054: "Consumer consent granted",
  440: "Signature expired",
  4060: "Not authorized",
  401: "Unauthorised",
};
const HEADER = {
  // "Access-Control-Allow-Origin": "*",
  // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  // 'Access-Control-Allow-Credentials':false,
  "partner-code": PARTNER_CODE,
  os: "web_application",
  "package-id": PACKAGEID,
};

const fetchRefreshToken = (params) => {
  let redirectUrl = Cookies.get("redirectUrl")
    ? Cookies.get("redirectUrl")
    : REDIRECT_URL;
  let url =
    APP_TYPE === "jordan"
      ? `${GET_REFRESH_TOKEN}?refresh_token=${Cookies.get("refresh_token")}`
      : `${GET_REFRESH_TOKEN}?refresh_token=${DecryptData(
          localStorage.getItem("refresh_token")
        )}`;
  axios
    .get(url, {
      defaultHeaders: false,
      headers: {
        ...HEADER,
      },
    })
    .then((res) => {
      const token = getPathData(res, ["data", "access_token"], null);
      const refresh_token = getPathData(res, ["data", "refresh_token"], null);
      const status = getPathData(res, ["data", "code"], "");
      if (
        status === UNAUTHORIZED_440 ||
        status === UNAUTHORIZED_401 ||
        status === 4023
      ) {
        localStorage.clear();
        if (APP_TYPE === "jordan") {
          window.location.replace(redirectUrl);
          clearCookies();
        } else {
          clearCookies();
          window.location.replace(REDIRECT_URL);
        }
      } else if (token) {
        if (APP_TYPE === "jordan") {
          const domain =
            "." +
            getPathData(window, ["location", "hostname"], "")
              ?.split(".")
              ?.slice(1)
              ?.join(".");
          Cookies.remove("access_token");
          Cookies.set("access_token", token, { domain });
          Cookies.set("refresh_token", refresh_token, { domain });
        } else {
          localStorage.setItem("access_token", EncryptData(token));
        }
        return Request({ ...params, count: params?.count + 1 });
      }
      return false;
    })
    .catch(() => {});
};
const validateRefreshToken = ({ redirectUrl, count, params, REDIRECT_URL }) => {
  if (
    !refreshToken ||
    refreshToken === null ||
    refreshToken === "null" ||
    refreshToken === ""
  ) {
    localStorage.clear();
    if (APP_TYPE === "jordan") {
      window.location.replace(redirectUrl);
      clearCookies();
    } else {
      clearCookies();
      window.location.replace(REDIRECT_URL);
    }
    return;
  }
  if (count < 3) {
    return fetchRefreshToken({ ...params, count });
  } else {
    localStorage.clear();
    if (APP_TYPE === "jordan") {
      window.location.replace(redirectUrl);
      clearCookies();
    } else {
      clearCookies();
      window.location.replace(REDIRECT_URL);
    }
  }
};
export const Request = (params) => {
  const {
    method = "GET",
    url = "",
    payload = {},
    auth = true,
    multipart = false,
    successAction = null,
    failureAction = null,
    defaultHeaders = true,
    headers = {},
    count = 0,
  } = params;
  let customHeaders = {};
  if (APP_TYPE === "jordan") {
    customHeaders = {
      // "Accept-Langugage": `${localStorage.getItem("language")}-IN`,
    };
  }
  let redirectUrl = Cookies.get("redirectUrl")
    ? Cookies.get("redirectUrl")
    : REDIRECT_URL;
  const getMethod = getPathData(Methods, [method], null);
  if (!getMethod) {
    return;
  }
  if (getMethod === "get") {
    axios
      .get(
        url,
        auth
          ? {
              data: null,
              headers: defaultHeaders
                ? {
                    ...HEADER,
                    Authorization:
                      APP_TYPE === "jordan"
                        ? `Bearer ${Cookies.get("access_token")}`
                        : `Bearer ${DecryptData(
                            localStorage.getItem("access_token")
                          )}`,
                    "Content-Type": multipart
                      ? "multipart/form-data"
                      : "application/json",
                    ...headers,
                    ...customHeaders,
                  }
                : {
                    ...headers,
                    ...customHeaders,

                    Authorization:
                      APP_TYPE === "jordan"
                        ? `Bearer ${Cookies.get("access_token")}`
                        : `Bearer ${DecryptData(
                            localStorage.getItem("access_token")
                          )}`,
                  },
            }
          : { headers: { ...headers, ...customHeaders } }
      )
      .then((res) => {
        const status = getPathData(res, ["data", "code"], "");
        const statusMsg = getPathData(StatusCode, [status], null);
        if (status == UNAUTHORIZED_440 || status == UNAUTHORIZED_401) {
          validateRefreshToken({
            redirectUrl,
            count,
            params,
            REDIRECT_URL,
          });
        } else {
          if (statusMsg) {
            //toast.info(statusMsg);
          }
          successAction && successAction(res);
        }
      })
      .catch((err) => {
        const response = JSON.parse(JSON.stringify(err));
        const status = getPathData(response, ["status"], "");
        if (status == UNAUTHORIZED_403) {
          validateRefreshToken({
            redirectUrl,
            count,
            params,
            REDIRECT_URL,
          });
        } else {
          failureAction && failureAction(err);
        }
      });
  } else {
    axios[getMethod](
      url,
      { ...payload, partner_code: PARTNER_CODE },
      auth
        ? {
            headers: defaultHeaders
              ? {
                  ...HEADER,
                  Authorization:
                    APP_TYPE === "jordan"
                      ? `Bearer ${Cookies.get("access_token")}`
                      : `Bearer ${DecryptData(
                          localStorage.getItem("access_token")
                        )}`,
                  "Content-Type": multipart
                    ? "multipart/form-data"
                    : "application/json",
                  ...headers,
                  ...customHeaders,
                }
              : {
                  ...headers,
                  ...customHeaders,
                  Authorization:
                    APP_TYPE === "jordan"
                      ? `Bearer ${Cookies.get("access_token")}`
                      : `Bearer ${DecryptData(
                          localStorage.getItem("access_token")
                        )}`,
                },
          }
        : { headers: { ...headers, ...customHeaders } }
    )
      .then((res) => {
        const status = getPathData(res, ["data", "code"], "");
        const statusMsg = getPathData(StatusCode, [status], null);
        if (status == UNAUTHORIZED_440 || status == UNAUTHORIZED_401) {
          validateRefreshToken({
            redirectUrl,
            count,
            params,
            REDIRECT_URL,
          });
        } else {
          if (statusMsg) {
            // toast.info(statusMsg);
          }
          successAction && successAction(res);
        }
      })
      .catch((err) => {
        const response = JSON.parse(JSON.stringify(err));
        const status = getPathData(response, ["status"], "");
        if (status == UNAUTHORIZED_403) {
          validateRefreshToken({
            redirectUrl,
            count,
            params,
            REDIRECT_URL,
          });
        } else {
          failureAction && failureAction(err);
        }
      });
  }
};
