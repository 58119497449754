import React from "react";
// import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Flex } from "Component";
import { createUseStyles } from "react-jss";
import { RetailerJordanColors } from "Global/colors";
import Theme from "Global/retailerJordanTheme";
import { REDIRECT_URL } from "HttpRequest/url";
// import UITranslator from "Hooks/uiTranslator";
// import { useTranslation } from "react-i18next";

const useStyles = createUseStyles({
  thumbSlide: {
    "& li": {
      border: `1px solid ${RetailerJordanColors.BORDERCOLOR}!important`,
      borderRadius: Theme.padding / 2,
    },
    "& .is-active": {
      borderColor: `${RetailerJordanColors.PRIMARY}!important`,
    },
    marginBottom: Theme.padding * 2,
  },
  mainSlide: {
    "& li": {
      height: "70vh",
    },
  },
  overviewStyle: {
    fontWeight: "600",
    color: `${RetailerJordanColors.GREY900}!important`,
  },

  buttonClass: {
    marginTop: 0,
  },

  footer: {
    paddingTop: Theme.padding * 2,
    paddingLeft: "0",
    paddingRight: Theme.padding * 3,
  },
  overrideModalHeader: {
    color: RetailerJordanColors.GREY900,
    ...Theme.fontLargeBold,
  },
  pointClass: {
    color: `${RetailerJordanColors.GREY500}!important`,
    marginBottom: Theme.padding,
  },
  placeholderClass: {
    width: "50%",
    display: "block",
    margin: "auto",
  },
});

const ProductModal = (props) => {
  const { testID = "propductModal", productDetail } = props;
  const { itemCode = "" } = productDetail;

  // const mainRef = useRef(null);
  // const thumbsRef = useRef(null);
  const classes = useStyles();
  // const lang = UITranslator();
  // const { t } = useTranslation();
  // useEffect(() => {
  //   if (mainRef.current && thumbsRef.current && thumbsRef.current.splide) {
  //     mainRef.current.sync(thumbsRef.current.splide);
  //   }
  // }, []);

  // const renderSlides = (flag) => {
  //   return images.map((slide) => (
  //     <SplideSlide key={slide[flag]}>
  //       <img src={slide[flag]} alt="image" />
  //     </SplideSlide>
  //   ));
  // };

  // const mainOptions = {
  //   type: "loop",
  //   perPage: 1,
  //   perMove: 1,
  //   pagination: false,
  //   autoplay: true,
  //   interval: 3000,
  //   arrows: false,
  //   cover: true,
  //   direction: lang && "rtl",
  // };

  // const thumbsOptions = {
  //   type: "slide",
  //   rewind: true,
  //   pagination: false,
  //   fixedWidth: 80,
  //   fixedHeight: 80,
  //   gap: 10,
  //   cover: true,
  //   focus: "center",
  //   isNavigation: true,
  //   autoplay: true,
  //   interval: 3000,
  //   arrows: false,
  //   direction: lang && "rtl",
  // };

  return (
    <Flex direction="column" overrideStyle={{ width: "100%" }} testID={testID}>
      {/* <Splide
        options={mainOptions}
        ref={mainRef}
        aria-labelledby="thumbnail-slider-example"
        className={classes.mainSlide}
      >
        {renderSlides("original")}
      </Splide>

      <Splide
        options={thumbsOptions}
        ref={thumbsRef}
        aria-label="The carousel with thumbnails. Selecting a thumbnail will change the main carousel"
        className={classes.thumbSlide}
      >
        {renderSlides("thumbnail")}
      </Splide> */}
      <img
        src={`${REDIRECT_URL}/device_images/${itemCode}.jpg`}
        loading="lazy"
        className={classes.placeholderClass}
        onError={(event) => {
          event.target.src = `${
            process.env.PUBLIC_URL + "/assets/grey-image-placeholder.jpg"
          }`;
          event.onerror = null;
        }}
        alt={itemCode}
        data-testid="productimage"
      />
      {/* <Typography type="h5" overrideClass={classes.overviewStyle}>
        {t("overview")}
      </Typography> */}
      {/* <ul>
        {product?.overview.map((item, index) => {
          return (
            <li key={index}>
              <Typography type="h6" overrideClass={classes.pointClass}>
                {item}
              </Typography>
            </li>
          );
        })}
      </ul> */}
    </Flex>
  );
};

export default ProductModal;
