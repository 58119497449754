export const Colors = {
  PRIMARY_LIGHT: "#86D3FF",
  PRIMARY_DARK: "#0F3471",
  PRIMARY: "#2684FF",
  SECONDARY: "#FA5736",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  RED: "#CC0033",
  GREY: "#C8C8C8",
  LIGHT_GREY: "#D3D3D3",
  LABEL_COLOR: "#666666",
  LIGHTBLUE: "#ADD8E6",
  YELLOW: "#FFEA00",
  GREY1: "#0000004D",
  // BTN_PRIMARY: "#0d5bc6",
  FORM_TEXT_COLOR: "#202124",
  DARK_GREY: "#282A36",
  GREY_20: "#EEEEEE",
  GREY_30: "#F8F9FA",
  UNSELECTED: "#666",
  BORDERCOLOR: "#E3E2E4",
  GREEN: "#50C878",
  LINK: "#00000066",
  FOOTER_BG: "#060A0D",
  GOOTER_LINK: "#E6E7E880",
  OVERLAY: "rgba(31, 32, 41, 0.75)",
  RED_20: "#F48B8B",
  RED_30: "#FF9696",
  ORANGE_20: "#FFCDBD",
  ORANGE: "#F0A871",
  BROWN: "#844A1D",
  GREEN_20: "#D6EEC8",
  GREEN_30: "#3E8E39",
  GREEN_40: "#006400",
  GREY_40: "#a9a9a9",
  YELLOW_20: "#FFF8E1",
  BLUE_20: "#deedfa",
  ERROR_RED: "#710C05",
  GREY_10: "#3A363F",
  BUTTONDISABLED: "#ABA7AF",
  GREY900: "#0C0911",
  BORDERCOLOR_10: "#F2F2F3",
  TEXTCOLOR: "#6B6572",
  GREY_50: "#29242D",
  GREY_60: "#E1DFE2",
  GREY_70: "#f5f5f9",
  BLUE_10: "#696cff",
  DISABLED: "#C7C5C9",
  HELPTEXT: "#e8c32c",
};
export const RetailerOrangeColors = {
  PRIMARY_LIGHT: "#FFD580",
  PRIMARY_DARK: "#db6702",
  PRIMARY: "#FF7900",
  SECONDARY: "#FA5736",
  BORDERCOLOR: "#F2F2F3",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  RED: "#CC0033",
  OVERLAY: "rgba(31, 32, 41, 0.75)",
  RED300: "#FDA29B",
  RED600: "#D92D20",
  GREY200: "#EAECF0",
  GREY900: "#101828",
  ORANGE700: "#CC5801",
  ORANGE100: "#FFF3D3",
  GREY600: "#475467",
  BLUE: "#0F6CBD",
  GREY30: "#F8F9FA",
  GREY50: "#F9FAFB",
  TEXTMEDIUMEMPHASIS: "#29242D",
  GREY300: "#D0D5DD",
  GREY100: "#F2F4F7",
  GREY25: "#FCFCFD",
  GREY500: "#667085",
  GREY400: "#98A2B3",
  GREEN500: "#12B76A",
  ORANGE600: "#FF7900",
  YELLOW100: "#FEF0C7",
  RED100: "#FEE4E2",
  BLUE700: "#175CD3",
  GREEN100: "#e5fcd3",
};
export const RetailerJordanColors = {
  PRIMARY_LIGHT: "#86D3FF",
  PRIMARY_DARK: "#800FF0",
  PRIMARY: "#5E0BB7",
  SECONDARY: "#FA5736",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  RED: "#CC0033",
  GREY: "#C8C8C8",
  LIGHT_GREY: "#D3D3D3",
  LABEL_COLOR: "#3A363F",
  LIGHTBLUE: "#ADD8E6",
  YELLOW: "#FFEA00",
  GREY1: "#0000004D",
  GREY500: "#4D4851",
  BLUE: "#4372c5",
  // BTN_PRIMARY: "#0d5bc6",
  FORM_TEXT_COLOR: "#202124",
  DARK_GREY: "#282A36",
  GREY_20: "#EEEEEE",
  GREY_30: "#F8F9FA",
  UNSELECTED: "#666",
  PLACEHOLDER_GREY: "#CCC",
  BORDERCOLOR: "#E3E2E4",
  GREEN: "#50C878",
  LINK: "#00000066",
  FOOTER_BG: "#060A0D",
  GOOTER_LINK: "#E6E7E880",
  OVERLAY: "rgba(31, 32, 41, 0.75)",
  RED_20: "#F48B8B",
  RED_30: "#FF9696",
  ORANGE_20: "#FFCDBD",
  ORANGE: "#F0A871",
  BROWN: "#844A1D",
  GREEN_20: "#D6EEC8",
  GREEN_30: "#3E8E39",
  GREEN_40: "#006400",
  GREY_40: "#a9a9a9",
  YELLOW_20: "#FFF8E1",
  BLUE_20: "#deedfa",
  ERROR_RED: "#710C05",
  GREY600: "#3A363F",
  GREY200: "#ABA7AF",
  PURPLE_10: "#5E0BB7",
  BORDERCOLOR_10: "#F2F2F3",
  GREY400: "#6B6572",
  GREY900: "#0C0911",
  GREYSTROKEPRIMARY: "#E3E2E4",
  BRAND75: "#ECDCFA",
  BRAND25: "#FAF6FD",
  GREY25: "#F7F7F8",
  BRAND800: "#26044E",
  GREY800: "#19151E",
  BRAND50: "#F6EDFC",
  TEXTMEDIUMEMPHASIS: "#29242D",
  GREY50: "#E1DFE2",
  TextBLACK4: "#0A0A0B",
  TEXTBLACK24: "#3B3D40",
  FILEUPLOADBORDER: "#EBEFFF",
  GREY98: "#F8FAFC",
  TABLECOLUMNBORDER: "#EBEBFF",
  BRAND100: "#D8B6F6",
  BRAND700: "#3E077E",
  BLUE56: "#1F1FFF",
  TEXTCOLOR: "#6B6572",
  OFF_YELLOW: "#F9EFE4",
  DISABLED: "#C7C5C9",
  TEXTLOWEMPHASIS: "#87818D",
  SUCCESS: "#057133",
  ERROR: "#710C05",
  WARNING: "#FFC107",
};

export default Colors;
