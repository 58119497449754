import React from "react";
import { createUseStyles } from "react-jss";
import Colors from "Global/colors";
import { Flex, Typography, List } from "Component";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Theme from "Global/retailerOrangeTheme";
import { PRODUCT_NAME } from "HttpRequest/url";
import UpdateWords from "RetailerOrange/Hooks/numberToWords";
const useStyles = createUseStyles((theme) => ({
  downloadIcon: {
    cursor: "pointer",
    border: `1px solid ${Colors.BORDERCOLOR}`,
    maxWidth: 120,
    padding: theme.padding,
    textAlign: "center",
    color: "#3A3B3C",
    "&:hover": {
      backgroundColor: Colors.GREY_20,
      color: Colors.BLACK,
    },
  },
  labelClass: {
    marginTop: theme?.margin * 2,
    marginBottom: theme?.margin,
    fontFamily: "Inter-Regular",
    textAlign: "left",
  },
  headingClass: {
    marginTop: theme?.margin,
    fontFamily: "Inter-Regular",
    textAlign: "left",
    textDecoration: "underline",
  },
  mainHeading: {
    fontFamily: "Inter-Bold",
    marginTop: theme?.margin,
    marginBottom: theme?.margin * 2,
    textAlign: "center",
  },
}));
const TermsAndCondition = (props) => {
  const { params: { loanData = {}, date = "", kycResponse = {} } = {} } = props;
  const classes = useStyles();
  const { labelClass, headingClass, mainHeading } = classes;
  const { t } = useTranslation();
  const printRef = React.useRef();
  /* istanbul ignore next */
  const printHandler = () => {
    var content = document.getElementById("printableArea");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };
  /* istanbul ignore next */
  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const doc = new jsPDF("p", "mm");
    const imgData = canvas.toDataURL("image/png");
    const imgWidth = 250.1;
    const pageHeight = 300.1;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;
    doc.addImage(
      imgData,
      "PNG",
      10,
      0,
      imgWidth,
      imgHeight + 19,
      undefined,
      "FAST"
    );
    heightLeft -= pageHeight;
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(
        imgData,
        "PNG",
        10,
        position,
        imgWidth,
        imgHeight + 25,
        undefined,
        "FAST"
      );
      heightLeft -= pageHeight;
    }
    doc.save("retailer_tnc.pdf");
  };
  const {
    open_loans: {
      device_emi_amount = "",
      loan_amount = "",
      device_downpayment_percentage = "",
      loan_duration = "",
      device_downpayment_amount = "",
      device_cost = "",
      loan_interest = "",
      loan_principal_interest_downpayment = "",
    } = {},
  } = loanData;
  const { first_name = "", last_name = "", address = "" } = kycResponse;
  const loanDuartionInWords =
    loan_duration === 9
      ? `${t("nine")} ${t("month")}`
      : loan_duration === 3
      ? `${t("three")} ${t("month")}`
      : loan_duration === 6
      ? `${t("six")} ${t("month")}`
      : "";

  return PRODUCT_NAME === "burkina_faso" ? (
    <Flex direction="column" overrideStyle={{ margin: Theme.margin * 4 }}>
      {device_downpayment_amount ? null : (
        <>
          <Flex justify="end" overrideStyle={{ gap: 10 }}>
            <Flex
              overrideClass={classes.downloadIcon}
              onClick={handleDownloadPdf}
              direction="row"
              align="center"
              justify="center"
              testID="downloadBtn_id"
            >
              {t("download")}
            </Flex>
            <Flex
              onClick={printHandler}
              overrideClass={classes.downloadIcon}
              direction="row"
              align="center"
              justify="center"
              testID="printBtn_id"
            >
              {t("print")}
            </Flex>
          </Flex>
          <iframe id="ifmcontentstoprint" style={{ display: "none" }}></iframe>
        </>
      )}
      <Flex
        direction="column"
        innerRef={printRef}
        id="printableArea"
        testID="terms_and_condition_text"
      >
        <Typography type="h4" overrideClass={mainHeading}>
          {t("burkina_terms_and_condition_heading")}{" "}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_terms_and_condition_text_1.1")}{" "}
          {t("burkina_terms_and_condition_text_1.2")}{" "}
          {t("burkina_terms_and_condition_text_1.3")}{" "}
          {t("burkina_terms_and_condition_text_1.4")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_terms_and_condition_text_1.5")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("burkina_terms_and_condition_Article_1")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_1_msg_1")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("burkina_terms_and_condition_Article_2")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_2_msg_1")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("burkina_terms_and_condition_Article_3")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_3_msg_3.1")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_3_msg_3.2")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_3_msg_3.3")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_3_msg_3.4")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_3_msg_3.5")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("burkina_terms_and_condition_Article_4")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_4_msg_1.1")}
        </Typography>
        <List
          listType="unordered"
          options={[
            {
              label: `3 ${t("month")}`,
              component: (item) => <Typography>{item.label}</Typography>,
            },
            {
              label: `6 ${t("month")}`,
              component: (item) => <Typography>{item.label}</Typography>,
            },
            {
              label: `9 ${t("month")}`,
              component: (item) => <Typography>{item.label}</Typography>,
            },
          ]}
        />
        <Typography overrideClass={labelClass}>
          {t("burkina_article_4_msg_1.2")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_4_msg_1.3")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("burkina_terms_and_condition_Article_5")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_5_msg_1")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("burkina_terms_and_condition_Article_6")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_6_msg_1")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_6_msg_2")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("burkina_terms_and_condition_Article_7")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_7_msg_1.1")}{" "}
          {loan_amount ? loan_amount : "......"}{" "}
          {t("burkina_article_7_msg_1.2")} (
          {loan_duration
            ? `${loanDuartionInWords} (${loan_duration}M)`
            : "....."}
          ) {t("burkina_article_7_msg_1.3")}{" "}
          {device_emi_amount ? device_emi_amount : "......"}{" "}
          {t("burkina_article_7_msg_1.4")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("burkina_terms_and_condition_Article_8")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_8_msg_1")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_8_msg_2")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_8_msg_3")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("burkina_terms_and_condition_Article_9")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_9_msg_1")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_9_msg_2.1")}{" "}
          {device_downpayment_percentage
            ? device_downpayment_percentage
            : "....."}
          % {t("burkina_article_9_msg_2.2")}{" "}
          {device_downpayment_amount ? device_downpayment_amount : "....."}{" "}
          {t("burkina_article_9_msg_2.3")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_9_msg_3")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_9_msg_4")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_9_msg_5")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_9_msg_6")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("burkina_terms_and_condition_Article_10")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_10_msg_1")}
        </Typography>
        <List
          listType="unordered"
          options={[
            {
              label: `${t("burkina_article_10_msg_1.1")}`,
            },
            {
              label: `${t("burkina_article_10_msg_1.2")}`,
            },
            {
              label: `${t("burkina_article_10_msg_1.3")}`,
            },
            {
              label: `${t("burkina_article_10_msg_1.4")}`,
            },
            {
              label: `${t("burkina_article_10_msg_1.5")}`,
            },
            {
              label: `${t("burkina_article_10_msg_1.6")}`,
            },
          ]}
        />
        <Typography overrideClass={headingClass} type="h5">
          {t("burkina_terms_and_condition_Article_11")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_11_msg_1")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_11_msg_2")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_11_msg_3")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_11_msg_4")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_11_msg_5")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("burkina_terms_and_condition_Article_12")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_12_msg_1.1")} {t("burkina_article_12_msg_1.2")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_12_msg_2")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_12_msg_3")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_12_msg_4")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_12_msg_5")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("burkina_terms_and_condition_Article_13")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_13_msg_1")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_13_msg_2")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("burkina_terms_and_condition_Article_14")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_14_msg_1")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_14_msg_2")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("burkina_terms_and_condition_Article_15")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_15_msg_1")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("burkina_terms_and_condition_Article_16")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_16_msg_1")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_16_msg_2")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_16_msg_3")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("burkina_terms_and_condition_Article_17")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_17_msg_1")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("burkina_article_17_msg_2")}
        </Typography>
        <Typography
          overrideStyle={{ fontStyle: "italic" }}
          overrideClass={labelClass}
        >
          *{t("article_18_msg_4")}*
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("article_18_msg_5")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("article_18_msg_6")} {last_name}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("article_18_msg_7")} {first_name}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("article_18_msg_8")}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("article_18_msg_9")} {address}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("article_18_msg_10")} {date}
        </Typography>
        <Typography overrideClass={labelClass}>
          {t("article_18_msg_11")}
        </Typography>
      </Flex>
    </Flex>
  ) : (
    <Flex direction="column" overrideStyle={{ margin: Theme.margin * 4 }}>
      {device_downpayment_amount ? null : (
        <>
          <Flex justify="end">
            <Flex
              overrideClass={classes.downloadIcon}
              onClick={handleDownloadPdf}
              direction="row"
              align="center"
              justify="center"
              testID="downloadBtn_id"
            >
              {t("download")}
            </Flex>
            <Flex
              onClick={printHandler}
              overrideClass={classes.downloadIcon}
              direction="row"
              align="center"
              justify="center"
              testID="printBtn_id"
            >
              {t("print")}
            </Flex>
          </Flex>
          <iframe id="ifmcontentstoprint" style={{ display: "none" }}></iframe>
        </>
      )}

      <Flex
        direction="column"
        innerRef={printRef}
        id="printableArea"
        testID="terms_and_condition_text"
      >
        <Typography type="h4" overrideClass={mainHeading}>
          {t("terms_and_condition_heading")}{" "}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_1")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_1_msg_1")}{" "}
          <a
            style={{ color: "blue" }}
            href="https://www.cofinacotedivoire.com"
            rel="noreferrer"
            target="_blank"
          >
            Cofina Côte d’Ivoire
          </a>
          {t("article_1_msg_1.1")}{" "}
          <a
            style={{ color: "blue" }}
            href="https://www.cofinacotedivoire.com"
            rel="noreferrer"
            target="_blank"
          >
            Cofina Côte d’Ivoire
          </a>
          {t("article_1_msg_1.2")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_1_msg_2")}{" "}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          c){" "}
          <a
            style={{ color: "blue" }}
            href="https://www.orange.ci/"
            rel="noreferrer"
            target="_blank"
          >
            ORANGE COTE D’IVOIRE
          </a>
          {t("article_1_msg_3")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_1_msg_4")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_1_msg_5")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_2")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_2_msg_1.1")}{" "}
          <a
            style={{ color: "blue" }}
            href="https://www.cofinacotedivoire.com"
            rel="noreferrer"
            target="_blank"
          >
            COFINA COTE D’IVOIRE
          </a>{" "}
          {t("article_2_msg_1.2")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_3")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_3_msg_1")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_4")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_4_submsg_1")}
          {"  "}
          {device_downpayment_percentage} {t("article_4_submsg_2")}
          {"  "}
          {device_downpayment_amount.toLocaleString()} {t("acticle_4_submsg_3")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_4_msg_2")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_5")}
        </Typography>
        <Typography color="#3A3B3C">{t("article_5_msg_1")}</Typography>
        <List
          listType="unordered"
          options={[
            {
              label: `3 ${t("month")}`,
              component: (item) => (
                <Flex justify="center" align="center">
                  <Typography color="#3A3B3C">{item.label}</Typography>
                  {loan_duration === 3 && (
                    <img
                      src={process.env.PUBLIC_URL + "/assets/tickIcon.png"}
                      style={{ width: 15, height: 15 }}
                    />
                  )}
                </Flex>
              ),
            },
            {
              label: `6 ${t("month")}`,
              component: (item) => (
                <Flex justify="center" align="center">
                  <Typography color="#3A3B3C">{item.label}</Typography>
                  {loan_duration === 6 && (
                    <img
                      src={process.env.PUBLIC_URL + "/assets/tickIcon.png"}
                      style={{ width: 15, height: 15 }}
                    />
                  )}
                </Flex>
              ),
            },
            {
              label: `9 ${t("month")}`,
              component: (item) => (
                <Flex justify="center" align="center">
                  <Typography color="#3A3B3C">{item.label}</Typography>
                  {loan_duration === 9 && (
                    <img
                      src={process.env.PUBLIC_URL + "/assets/tickIcon.png"}
                      style={{ width: 15, height: 15 }}
                    />
                  )}
                </Flex>
              ),
            },
          ]}
        />
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_5_msg_2")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_5_msg_3")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_6")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_6_msg_1")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_7")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_7_msg_1")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_7_msg_2.1")} {device_cost.toLocaleString()}{" "}
          {UpdateWords(device_cost)} {t("article_7_msg_2.2")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_7_msg_2.3")}
        </Typography>
        <List
          listType="unordered"
          options={[
            {
              label: `${t("article_7_points_1.1")} ${
                loan_amount ? loan_amount.toLocaleString() : "..........."
              }(${loan_amount ? UpdateWords(loan_amount) : ".........."}) ${t(
                "article_7_points_1.2"
              )}`,
              component: (item) => (
                <Typography color="#3A3B3C">{item.label}</Typography>
              ),
            },
            {
              label: `${t("article_7_points_2")} ${
                loan_interest ? loan_interest.toLocaleString() : "........."
              }(${
                loan_interest ? UpdateWords(loan_interest) : ".........."
              })${t("article_7_points_1.2")}`,
              component: (item) => (
                <Typography color="#3A3B3C">{item.label}</Typography>
              ),
            },
            {
              label: `${t("article_7_points_3.1")} ${
                loan_duration ? loan_duration : "............"
              }(${
                loanDuartionInWords ? loanDuartionInWords : ".............."
              }) ${t("article_7_points_3.2")} ${
                device_emi_amount
                  ? device_emi_amount.toLocaleString()
                  : "........"
              }(${
                device_emi_amount
                  ? UpdateWords(device_emi_amount)
                  : "..........."
              })${t("article_7_points_1.2")}`,
              component: (item) => (
                <Typography color="#3A3B3C">{item.label}</Typography>
              ),
            },
            {
              label: `${t("article_7_points_4")} ${
                loan_principal_interest_downpayment
                  ? loan_principal_interest_downpayment
                  : "........."
              }(${
                loan_principal_interest_downpayment
                  ? UpdateWords(loan_principal_interest_downpayment)
                  : "........"
              }) ${t("article_7_points_1.2")}`,
              component: (item) => (
                <Typography color="#3A3B3C">{item.label}</Typography>
              ),
            },
          ]}
        />
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_7_msg_3")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_7_msg_4")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_7_msg_5")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_7_msg_6")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_8")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_8_msg_1")}
        </Typography>
        <Typography color="#3A3B3C">{t("article_8_msg_2")}</Typography>
        <List
          listType="unordered"
          options={[
            {
              label: `${t("article_8_list_1")}`,
              component: (item) => (
                <Typography color="#3A3B3C">{item.label}</Typography>
              ),
            },
            {
              label: `${t("article_8_list_2")}`,
              component: (item) => (
                <Typography color="#3A3B3C">{item.label}</Typography>
              ),
            },
            {
              label: `${t("article_8_list_3")}`,
              component: (item) => (
                <Typography color="#3A3B3C">{item.label}</Typography>
              ),
            },
          ]}
        />
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_8_msg_3")}
        </Typography>
        <Typography overrideClass={labelClass} color="#3A3B3C">
          {t("article_8_msg_4")}
        </Typography>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_9")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_9_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_9_msg_2")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_9_msg_3")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_9_msg_4")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_9_msg_5")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_9_msg_6")}
          </Typography>
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_10")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_10_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_10_submsg_1")}
            {"  "}
            {device_downpayment_percentage} {t("article_10_submsg_2")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_10_msg_3")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_10_msg_4")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_10_msg_5")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_10_msg_6")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_10_msg_7")}
          </Typography>
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_11")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_11_msg_1")}
          </Typography>
          <Typography color="#3A3B3C">{t("article_11_msg_2")}</Typography>
          <List
            listType="unordered"
            options={[
              {
                label: `${t("article_11_list_1")}`,
                component: (item) => (
                  <Typography color="#3A3B3C">{item.label}</Typography>
                ),
              },
              {
                label: `${t("article_11_list_2")}`,
                component: (item) => (
                  <Typography color="#3A3B3C">{item.label}</Typography>
                ),
              },
              {
                label: `${t("article_11_list_3")}`,
                component: (item) => (
                  <Typography color="#3A3B3C">{item.label}</Typography>
                ),
              },
              {
                label: `${t("article_11_list_4")}`,
                component: (item) => (
                  <Typography color="#3A3B3C">{item.label}</Typography>
                ),
              },
            ]}
          />
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_12")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_12_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_12_msg_2")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_12_msg_3")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_12_msg_4")}
          </Typography>
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_13")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_13_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_13_msg_2")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_13_msg_3")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_13_msg_4")}
          </Typography>
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_14")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_14_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_14_msg_2")}
          </Typography>
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_15")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_15_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_15_msg_2")}
          </Typography>
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_16")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_16_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_16_msg_2")}
          </Typography>
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_17")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_17_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_17_msg_2")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_17_msg_3")}
          </Typography>
        </Flex>
        <Typography overrideClass={headingClass} type="h5">
          {t("terms_and_condition_Article_18")}
        </Typography>
        <Flex direction="column">
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_1")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_2")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_3")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_4")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_5")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_6")} {last_name}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_7")} {first_name}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_8")}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_9")} {address}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_10")} {date}
          </Typography>
          <Typography overrideClass={labelClass} color="#3A3B3C">
            {t("article_18_msg_11")}
          </Typography>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TermsAndCondition;
