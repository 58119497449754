import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  total_downpayment_amount: 0,
  items: [],
};

const multiProductSlice = createSlice({
  name: "multi_product_details",
  initialState,
  reducers: {
    addProduct: (state, action) => {
      const { params, downpayment_amount_tax, final_downpayment } =
        action.payload;

      state.items.push({
        ...params,
        downpayment_amount_tax: downpayment_amount_tax,
      });

      state.total_downpayment_amount += final_downpayment;
    },
    updateProduct: (state, action) => {
      const { productIndex, downpaymentValue, new_downpayment_amount_tax } =
        action.payload;
      const final_downpayment =
        Number(downpaymentValue) + Number(new_downpayment_amount_tax);

      const product = state?.items[productIndex];
      const old_downpayment_amount = product?.downpayment_amount;
      const old_downpayment_amount_tax = Number(
        product?.downpayment_amount_tax
      );

      state.items[productIndex] = {
        ...product,
        downpayment_amount: downpaymentValue,
        downpayment_amount_tax: new_downpayment_amount_tax,
      };

      state.total_downpayment_amount =
        state.total_downpayment_amount -
        (old_downpayment_amount + old_downpayment_amount_tax) +
        final_downpayment;
    },
    removeProduct: (state, action) => {
      const { productIndex, final_downpayment } = action.payload;
      state.total_downpayment_amount -= final_downpayment;
      state.items = state?.items.filter((_, index) => index !== productIndex);
    },
    updateSelectedProductData: (state, action) => {
      return { ...state, ...action?.payload };
    },
    updateDeviceNo: (state, action) => {
      return {
        ...state,
        items: state?.items.map((item, index) => {
          const single_item = action?.payload.find(
            (_, elIndex) => elIndex === index
          );
          return single_item
            ? { ...item, device_no: single_item?.device_no }
            : item;
        }),
      };
    },
    resetMultiProductData: () => {
      return initialState;
    },
  },
});

export const {
  addProduct,
  updateProduct,
  removeProduct,
  updateSelectedProductData,
  updateDeviceNo,
  resetMultiProductData,
} = multiProductSlice.actions;
export default multiProductSlice.reducer;
