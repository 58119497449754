import React from "react";
const DownEditIcon = (props) => {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.16626 19.0009V15.6676H16.8329V19.0009H0.16626ZM3.49959 12.3343H4.66626L11.1663 5.8551L9.97876 4.6676L3.49959 11.1676V12.3343ZM1.83293 14.0009V10.4593L11.1663 1.14677C11.319 0.993991 11.4961 0.875936 11.6975 0.792603C11.8989 0.709269 12.1107 0.667603 12.3329 0.667603C12.5551 0.667603 12.7704 0.709269 12.9788 0.792603C13.1871 0.875936 13.3746 1.00094 13.5413 1.1676L14.6871 2.33427C14.8538 2.48705 14.9753 2.6676 15.0517 2.87594C15.1281 3.08427 15.1663 3.29955 15.1663 3.52177C15.1663 3.7301 15.1281 3.93496 15.0517 4.13635C14.9753 4.33774 14.8538 4.52177 14.6871 4.68844L5.37459 14.0009H1.83293Z"
        fill="#ABA7AF"
        {...props}
      />
    </svg>
  );
};

export default DownEditIcon;
