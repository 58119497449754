import moment from "moment";
export const getPathData = (obj, [...args], defaultValue = null) => {
  const result = args.reduce((obj, level) => obj && obj[level], obj);
  return result || defaultValue;
};
export const validateRegex = (string = "", expressions = []) => {
  for (const value of expressions) {
    if (!string.match(value)) {
      return false;
    }
  }
  return true;
};

export const validateEmail = (value = "") => {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return validRegex.test(value);
};
export const validateMobile = (value = "") => {
  const regexExp = /^[6-9]\d{9}$/gi;
  return regexExp.test(value);
};
export const isNumber = (value = 0) => {
  return !isNaN(value);
};
export const dtutil = (value = "") => {
  return moment(value);
};
export const isEmptyObject = (obj = {}) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};
export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};
export const truncateText = (text, maxLength) => {
  if (text?.length <= maxLength) {
    return text;
  }
  return text?.slice(0, maxLength) + "...";
};

export const interestRateConverter = (num) => {
  if (!num) {
    return "NA";
  } else if (num < 1) {
    return `${num * 100} %`;
  } else {
    return `${num}%`;
  }
};

export const formatValue = (value) => {
  if (value === null || value === undefined) {
    return "0";
  }
  const numericValue = typeof value === "string" ? Number(value) : value;
  if (numericValue === 0) {
    return "0";
  }
  const formattedValue =
    numericValue % 1 !== 0 ? numericValue.toFixed(3) : String(numericValue);
  return formattedValue;
};

export const buildURL = (baseUrl = "", obj = {}) => {
  let url = baseUrl + "?";

  for (const key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      obj[key] !== undefined &&
      obj[key] !== null &&
      obj[key] !== ""
    ) {
      url += encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]) + "&";
    }
  }

  if (url.endsWith("&")) {
    url = url.slice(0, -1);
  }

  return url;
};

export const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const convertStatus = (status) => {
  return status
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const useDebouncedCallback = (func, wait) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      // timer = null;
      func.apply(context, args);
    }, wait);
  };
};

export const stepSetter = (step) => {
  localStorage.setItem("cancellation_step", step);
};

export default {
  getPathData,
  validateRegex,
  validateEmail,
  validateMobile,
  isNumber,
  dtutil,
  isEmptyObject,
  isJson,
  truncateText,
  formatValue,
  buildURL,
  capitalizeFirstLetter,
  useDebouncedCallback,
  stepSetter,
};
